import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import { useStores } from '../../hooks/use-stores';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

function Home() {
    const { t } = useTranslation();
    const { store, companyStore } = useStores();
    const { error, loadingID } = store;
    const { currentCompany, loadingCompanies } = companyStore;

    useEffect(() => {
        if (store.automationEvent_id) {
            store.loadTrainingByEvent(store.automationEvent_id);
        }
    }, [currentCompany, store]);

    return loadingCompanies || loadingID ? (
        <ThemedSpinner />
    ) : !currentCompany || store.defaultHomeRedirect === '' ? (
        <div className="container max-1280">
            <div className="block">{t('noProductsAvailable')}</div>
        </div>
    ) : (
        <div className="container max-1280">
            <ErrorMessage error={error} />
            {store.training_id ? (
                <Redirect to={`/trainings/${store.training_id}`} />
            ) : null}
            {store.defaultHomeRedirect !== '' ? (
                <Redirect to={`/${store.defaultHomeRedirect}`} />
            ) : null}
        </div>
    );
}

export default observer(Home);
