import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import Waiting from '../components/waiting';
import { querystring } from '../utils/helpers';
import qs from 'querystring';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useStores } from '../hooks/use-stores';

function DirectSignIn() {
    const match = useRouteMatch();
    const history = useHistory();
    let { token } = match.params;
    const { authStore, store } = useStores();
    const { emailLoginError, currentUser } = authStore;
    const { contextParams } = store;
    useEffect(() => {
        authStore.directSignin(token);
    }, []);

    const redirectUrl = useMemo(() => {
        let redirect = querystring('redirect') || '/';
        if (!redirect.startsWith('/')) redirect = `/${redirect}`;
        if (redirect === '/') {
            redirect = `${redirect}?${qs.stringify(store.contextParams)}`;
        }
        return redirect;
    }, [contextParams]);

    useEffect(() => {
        if (currentUser) history.push(redirectUrl);
        if (emailLoginError) history.push('/auth/error/email');
    }, [emailLoginError, currentUser]);

    return <Waiting waitingActive={true} from="DirectSignIn" />;
}

export default observer(DirectSignIn);
