import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import TrainingView from './TrainingView';
import { useStores } from '../../hooks/use-stores';
import TrainingsList from './TrainingsList';
import HomeViewContainer from '../Home/HomeViewContainer';

export default observer(({ companyId, isTrainingEnabled, currentCompany }) => {
    const { store, trainingStore } = useStores();
    useEffect(() => {
        store.resetContext();
    }, [companyId, store]);

    const language = store.language;

    if (currentCompany) {
        return isTrainingEnabled ? (
            <Switch>
                <Route
                    path={`/trainings/`}
                    exact
                    render={() => (
                        <HomeViewContainer
                            layoutTitle="Awareness and Compliance Training"
                            code={'trainings'}
                            pageTitle={'training programs'}
                            content={<TrainingsList />}
                        />
                    )}
                />

                <Route
                    path={`/trainings/:id`}
                    render={(props) => (
                        <TrainingView
                            match={props.match}
                            trainingId={props.match.params.id}
                            store={store}
                            trainingStore={trainingStore}
                            videoTokenStore={store}
                            language={language}
                            isDemo={false}
                        />
                    )}
                />
            </Switch>
        ) : (
            <Redirect to="/" />
        );
    } else {
        return null;
    }
});
