import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, calculateColor } from '../../utils/helpers';
import {
    FlexDiv,
    SpaceBetweenDiv,
    TextTiny,
    InlineProgressBar,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';

function TrainingsListDetails({ training, brandingStore }) {
    const { t } = useTranslation();

    const getScore = useCallback(
        (training) => (100 * (training.viewed || 0)) / training.subjectsSent,
        []
    );

    const getSentSubjects = useCallback(
        (training) =>
            (100 * (training.subjectsSent || 0)) / training.totalSubjects,
        []
    );
    return (
        <FlexDiv gap={16} fullWidth>
            {/* Your score */}
            <SpaceBetweenDiv alignCenter gap={16} rowGap={4}>
                <FlexDiv flexGrow={1}>
                    <TextTiny lighter bold>
                        {t('Your Score')}
                    </TextTiny>
                </FlexDiv>
                <FlexDiv flexGrow={4} $noWrap alignCenter fullWidth>
                    <InlineProgressBar
                        height={4}
                        value={getScore(training)}
                        fullWidth
                        customColor={calculateColor(
                            getScore(training),
                            brandingStore
                        )}
                    />
                    <TextTiny lighter bold noWrap minWidth={40}>
                        {getScore(training).toFixed(0)}%
                    </TextTiny>
                </FlexDiv>
            </SpaceBetweenDiv>

            {/* Available lessons */}
            <SpaceBetweenDiv alignCenter gap={16}>
                <FlexDiv flexGrow={1}>
                    <TextTiny lighter bold>
                        {t('Available lessons')}
                    </TextTiny>
                </FlexDiv>
                <SpaceBetweenDiv flexGrow={4} $noWrap alignCenter fullWidth>
                    <InlineProgressBar
                        height={4}
                        value={getSentSubjects(training)}
                        fullWidth
                        customColor={calculateColor(
                            getSentSubjects(training),
                            brandingStore
                        )}
                    />
                    <TextTiny lighter bold noWrap minWidth={40}>
                        {training.subjectsSent} / {training.totalSubjects}
                    </TextTiny>
                </SpaceBetweenDiv>
            </SpaceBetweenDiv>

            {/* Schedule */}
            <SpaceBetweenDiv alignCenter gap={16}>
                <FlexDiv flexGrow={1} desktopOnly>
                    <TextTiny lighter bold>
                        Schedule
                    </TextTiny>
                </FlexDiv>
                <FlexDiv flexGrow={4}>
                    <TextTiny lighter>
                        {training.nextSubjectDate ? (
                            <Fragment>
                                {training.scheduleType === 1 ? (
                                    <Fragment>
                                        {t('allLessonsDeliveredOn')}{' '}
                                        {formatDate(training.created)}
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {training.subjectsSent} {t('of')}{' '}
                                        {training.totalSubjects}{' '}
                                        {t('lessons delivered.')} {t('Next')}{' '}
                                        {training.nextSubjectsToSend > 1 &&
                                            training.nextSubjectsToSend}{' '}
                                        {t('scheduled on')}{' '}
                                        {formatDate(training.nextSubjectDate)}
                                    </Fragment>
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                {t('allLessonsDeliveredOn')}{' '}
                                {formatDate(training.created)}
                            </Fragment>
                        )}
                    </TextTiny>
                </FlexDiv>
            </SpaceBetweenDiv>
        </FlexDiv>
    );
}

export default observer(TrainingsListDetails);
