import RestService from './RestService';

export default class Questions extends RestService {
    constructor(parentService, parentId) {
        super(`${parentService.baseUrl}/${parentId}/questions`);
    }

    async getPersonalizedQuestion(questionId, assessmentId) {
        return this.get(
            `${this.baseUrl}/${questionId}/assessment/${assessmentId}`
        );
    }
}
