import { makeAutoObservable } from 'mobx';
import { generateUpdateEntity } from '../utils/mobx';
import services from '../services';
import { stripHtml } from '../utils/helpers';

class SpeechStore {
    textToPlay = null;
    mediaUrls = {};
    mediaUrlsCreating = [];
    error = null;
    element = null;
    progress = 0;
    playingAudio = null;

    setError = (error) => {
        this.error = error;
    };

    setElement(element) {
        this.element = element;
        if (element) {
            this.element.ondurationchange = this.ondurationchange.bind(this);
            this.element.ontimeupdate = this.ontimeupdate.bind(this);
            this.element.onpause = this.onpause.bind(this);
            this.element.onended = this.onpause.bind(this);
        } else {
            if (this.element) {
                this.element.ondurationchange = null;
                this.element.ontimeupdate = null;
            }
        }
    }
    ondurationchange() {
        this.progress = 0;
    }

    onpause() {
        this.playingAudio = null;
    }

    ontimeupdate() {
        this.progress = this.element.currentTime / this.element.duration;
    }

    async speak(text, hash) {
        this.element.pause();
        this.playingAudio = null;
        this.progress = 0;
        this.textToPlay = text;
        if (!this.mediaUrls[hash]) await this.loadMedia(hash, this.textToPlay);
        if (this.element) {
            this.element.src = this.mediaUrls[hash];
            this.element.play();
            this.playingAudio = hash;
        }
    }

    loadMedia = generateUpdateEntity(
        'mediaUrl',
        this,
        'mediaUrlsCreating',
        async (hash, text) => {
            const result = await services.Speech.create({
                text,
                hash,
            });
            this.mediaUrls[hash] = result.url;
        }
    );

    constructor() {
        makeAutoObservable(this);
    }
}

export default SpeechStore;
