import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import EyeIcon from '@material-ui/icons/Visibility';
import { ThemeProvider } from 'styled-components';
import {
    BigModal,
    Eye,
    FlexDiv,
    TextDefault,
    TextInput,
    ThemedButton,
} from '@awarego/awarego-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { AwareGOTheme as muiThemeFile } from '../../theme-mui';
import { Button, TextField } from '@material-ui/core';

function ThemeWrapper(props) {
    const { primaryColor, secondaryColor, children } = props;
    const [theme, setTheme] = React.useState({
        primary: primaryColor,
        secondary: secondaryColor,
    });
    const [muiTheme, setMuiTheme] = React.useState(muiThemeFile);

    useEffect(() => {
        setTheme({
            primary: primaryColor,
            secondary: secondaryColor,
        });
        muiThemeFile.palette.primary.main = primaryColor;
        muiThemeFile.palette.secondary.main = secondaryColor;
        setMuiTheme(theme);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primaryColor, secondaryColor]);
    return (
        <MuiThemeProvider theme={muiTheme}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </MuiThemeProvider>
    );
}

const defaultPrimary = '#5590ff';
const defaultSecondary = '#ff0000';
export default observer(() => {
    const [primary, setPrimary] = React.useState(defaultPrimary);
    const [secondary, setSecondary] = React.useState(defaultSecondary);

    const [primaryColor, setPrimaryColor] = React.useState(defaultPrimary);
    const [secondaryColor, setSecondaryColor] =
        React.useState(defaultSecondary);

    const [isOpen, setIsOpen] = React.useState(false);

    const handleSetColors = () => {
        setPrimaryColor(primary);
        setSecondaryColor(secondary);
    };
    const handleOpenModal = () => {
        setIsOpen(true);
    };
    const handleHideModal = () => {
        setIsOpen(false);
    };

    return (
        <ThemeWrapper
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
        >
            <FlexDiv column alignCenter fullWidth>
                <ThemedButton mode="primary" text="Primary" />
                <ThemedButton mode="secondary" text="Secondary" />
                <hr />
                <br />
                <br />
                <br />
                <TextDefault themed>Set colors:</TextDefault>
                <FlexDiv gap={8} mb={16}>
                    <TextField
                        onChange={(e) => setPrimary(e.target.value)}
                        value={primary}
                        label="primary"
                        variant="outlined"
                    />
                    <TextInput
                        label="secondary"
                        onChange={(e) => setSecondary(e.target.value)}
                        value={secondary}
                        overwriteGlobalStyles
                    />
                </FlexDiv>
                <ThemedButton onClick={handleSetColors} text="Set" />
                <ThemedButton onClick={handleOpenModal} text="openModal" />
                <Eye color={'primary'} />
                <Eye color={'secondary'} />
                <BigModal
                    isOpen={isOpen}
                    onRequestClose={handleHideModal}
                    heading="Add questions"
                    modalContent={
                        <>
                            <TextInput value="lfdas" overwriteGlobalStyles />{' '}
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<Eye color={'secondary'} />}
                            >
                                prim
                            </Button>
                            <Button
                                color="secondary"
                                variant="contained"
                                startIcon={<Eye color={'primary'} />}
                            >
                                sec
                            </Button>
                            <EyeIcon
                                color="primary"
                                alt="preview video"
                                fontSize="small"
                            />{' '}
                            <EyeIcon
                                color="secondary"
                                alt="preview video"
                                fontSize="small"
                            />{' '}
                        </>
                    }
                />
            </FlexDiv>
        </ThemeWrapper>
    );
});
