import React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Container } from 'reactstrap';
import Subject from './subject';

function Subjects({ match, inactive, subjects }) {
    if (!subjects) {
        return (
            <div>
                <Container>
                    <Row>
                        <Col md="6" xs="12">
                            <div className="loading-group" />
                        </Col>
                        <Col md="6" xs="12">
                            <div className="loading-group" />
                        </Col>
                        <Col md="6" xs="12">
                            <div className="loading-group" />
                        </Col>
                        <Col md="6" xs="12">
                            <div className="loading-group" />
                        </Col>
                        <Col md="6" xs="12">
                            <div className="loading-group" />
                        </Col>
                        <Col md="6" xs="12">
                            <div className="loading-group" />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    } else if (subjects) {
        return subjects.map((subject, i) => (
            <Subject
                match={match}
                subject={subject}
                key={subject.id}
                inactive={inactive}
                index={i}
                allSubjects={subjects}
            />
        ));
    } else {
        return null;
    }
}

export default observer(Subjects);
