import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import { observer, Observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStores } from '../hooks/use-stores';

function PrivateRoute({ component: Component, notLoggedUrl, render, ...rest }) {
    const location = useLocation();
    const history = useHistory();
    const { authStore } = useStores();

    if (!notLoggedUrl) notLoggedUrl = authStore.loginUrl;

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        if (
            queryParams.has('sso') &&
            authStore.isAuthenticated === true &&
            authStore.token != null
        ) {
            queryParams.delete('sso');
            history.replace({
                search: queryParams.toString(),
            });
        }
    }, []);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (
                    notLoggedUrl &&
                    (notLoggedUrl.startsWith('http://') ||
                        notLoggedUrl.startsWith('https://'))
                ) {
                    window.location = notLoggedUrl;
                    return;
                }

                return authStore.isAuthenticated === true &&
                    authStore.token != null ? (
                    Component ? (
                        <Component {...props} {...rest} />
                    ) : (
                        <Observer>
                            {() => {
                                return render(props);
                            }}
                        </Observer>
                    )
                ) : (
                    <Redirect to={notLoggedUrl} />
                );
            }}
        />
    );
}

export default observer(PrivateRoute);
