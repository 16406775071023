import { createTheme } from '@material-ui/core/styles';
import { palette } from '@awarego/awarego-components';

export const AwareGOTheme = createTheme({
    palette: {
        primary: { main: '#20A26B' },
        secondary: { main: '#ffffff' },
    },
    typography: {
        fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    },
    overrides: {
        MuiSlider: {
            thumb: {
                color: '#20A26B',
                width: 22,
                height: 22,
                boxShadow: '0 0 0 3px white',
                borderColor: '#ffffff',
                borderWidth: 4,
            },
            track: {
                height: 8,
                color: '#20A26B',
            },
            rail: {
                color: '#afafaf',
                height: 8,
                opacity: 0.4,
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: '13px',
                fontWeight: 700,
                lineHeight: '18px',
                padding: '6px 24px',
                '&:first-child': {
                    marginTop: '4px',
                },
                '&:last-child': {
                    marginBottom: '4px',
                },
            },
        },
        MuiMenu: {
            list: {
                minWidth: '100px',
            },
        },
        MuiFormControlLabel: {
            label: {
                fontWeight: '600',
                letterSpacing: '0.04em',
            },
        },
        MuiOutlinedInput: {
            root: {
                marginTop: '4px',
                '&&& $input': {
                    margin: 0,
                    paddingLeft: '10px',
                    fontWeight: 'bold',
                    fontSize: '13px',
                    lineHeight: '18px',
                    paddingTop: '10px',
                    paddingBottom: '8px',
                    color: palette.graphite.ash,
                },
            },
        },
        MuiFormHelperText: {
            root: {
                color: '#c4c4c4',
                '&$error': {
                    color: '#BE2024',
                },
            },
        },
        MuiTabs: {
            indicator: {
                backgroundColor: '#c4c4c4',
            },
        },
    },
    breakpoints: {
        values: {
            md: 992,
        },
    },
});
