/* eslint-disable no-undef */
import { reaction, makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import { awareGoTheme as styledComponentsThemeFile } from '../theme';
import { AwareGOTheme as muiThemeFile } from '../theme-mui';
import _ from 'lodash';
import { querystring } from '../utils/helpers';

const { REACT_APP_LMS_URL: lmsUrl } = process.env;
const pattern = /^((http|https):\/\/)/;

class BrandingStore {
    brandingLoaded = false;
    branding = null;
    defaultMiniHraType = 'police';
    redColor = '#EB5757';
    yellowColor = '#FCBE00';
    greenColor = '#20A26B';

    overrideCompanyId = null;
    accessKey = null;

    constructor(companyStore) {
        makeAutoObservable(this);
        this.companyStore = companyStore;

        reaction(
            () => this.companyStore.currentCompany,
            (company) => {
                if (company) {
                    this.loadBranding(company.company_id);
                } else this.loadBranding();
            }
        );

        reaction(
            () => this.companyStore.noCompanies,
            (data) => {
                if (data) {
                    this.loadBranding();
                }
            }
        );

        reaction(
            () => this.overrideCompanyId,
            (data) => {
                if (data) {
                    this.loadBranding();
                }
            }
        );
    }

    setCustomBranding(companyId, accessKey) {
        this.overrideCompanyId = companyId;
        this.accessKey = accessKey;
    }

    async loadBranding(companyId) {
        this.brandingLoaded = false;

        let companyInUrl = Number(querystring('o'));

        const effectiveCompanyId =
            this.overrideCompanyId || companyId || companyInUrl;

        try {
            let result = companyId
                ? await services.Companies.BrandingService(
                      effectiveCompanyId
                  ).list({
                      accessKey: this.accessKey,
                      returnUrl:
                          window.location.pathname + window.location.search,
                  })
                : await services.Branding.list({
                      companyId: effectiveCompanyId,
                      accessKey: this.accessKey,
                      returnUrl:
                          window.location.pathname + window.location.search,
                  });
            runInAction(() => {
                this.branding = result;
                this.brandingLoaded = true;
            });
        } catch (err) {
            if (err.response && err.response.status === 403) {
                this.authStore.logout();
            }
        }
    }

    get logo() {
        let logoPath = '/static/img/logo_simple.png';

        if (
            this.companyStore.currentCompany &&
            this.companyStore.currentCompany.logo_name
        ) {
            logoPath =
                process.env.REACT_APP_RESOURCES_URL +
                '/' +
                process.env.REACT_APP_COMPANY_RESOURCES_DIR +
                '/' +
                this.companyStore.currentCompany.logo_name;
        } else if (this.branding && this.branding['ui.logo']) {
            logoPath = this.branding['ui.logo'];
        }
        return logoPath;
    }

    get favicon() {
        if (!this.brandingLoaded) return null;
        return (
            (this.branding && this.branding['ui.favicon']) ||
            'favicon_generic.ico'
        );
    }

    get emailOptions() {
        if (!this.brandingLoaded) return null;
        const options =
            this.branding &&
            Object.keys(this.branding).filter((x) =>
                x.startsWith('ui.miniHRA.emailOption')
            );
        const result = {};
        options.forEach((option) => {
            if (option === 'ui.miniHRA.emailOption') {
                result['default'] = this.branding[option];
            } else {
                const suffix = option.split('.').pop();
                result[suffix] = this.branding[option];
            }
        });
        return result;
    }

    get name() {
        if (!this.brandingLoaded) return null;
        return (this.branding && this.branding['name']) || 'AwareGo';
    }

    get account_name() {
        if (!this.brandingLoaded) return null;
        return (this.branding && this.branding['account_name']) || 'AwareGO';
    }

    get supportEmail() {
        if (!this.brandingLoaded) return null;
        return (
            (this.branding && this.branding['contact.support']) ||
            'support@awarego.com'
        );
    }

    get loginUrl() {
        if (!this.brandingLoaded) return null;
        return this.branding && this.branding['loginUrl'];
    }

    get lmsDomain() {
        if (!this.brandingLoaded) return lmsUrl;
        if (this.branding && this.branding['lms_domain']) {
            let url = this.branding['lms_domain'];
            if (!pattern.test(url)) {
                url = 'https://' + url;
            }
            return url;
        }
        return lmsUrl;
    }

    get privacyUrl() {
        const defaultPrivacyUrl = 'https://www.awarego.com/privacy';
        if (!this.brandingLoaded) return defaultPrivacyUrl;
        return (
            (this.branding && this.branding['ui.privacy_url']) ||
            defaultPrivacyUrl
        );
    }

    get miniHraFinishUrl() {
        const defaultMiniHraFinishUrl =
            'https://awarego.com/human-risk-assessment/';
        if (!this.brandingLoaded) return defaultMiniHraFinishUrl;
        return (
            (this.branding && this.branding['ui.minihra_finish_url']) ||
            defaultMiniHraFinishUrl
        );
    }

    get miniHraHash() {
        const defaultMiniHraHash = '#awarego #icelandpolice';
        if (!this.brandingLoaded) return defaultMiniHraHash;
        return (
            (this.branding && this.branding['ui.minihra_hash']) ||
            defaultMiniHraHash
        );
    }

    getScoreDefinition(percentage) {
        let roundedPercentage = Math.floor(percentage);
        if (this.brandingLoaded && this.branding.assessmentScoreRanges) {
            let def = this.branding.assessmentScoreRanges.company.find(
                (x) =>
                    roundedPercentage >= x.from_score &&
                    roundedPercentage <= x.to_score
            );
            if (!def)
                def = this.branding.assessmentScoreRanges.global.find(
                    (x) =>
                        roundedPercentage >= x.from_score &&
                        roundedPercentage <= x.to_score
                );

            if (def && !def.color) {
                switch (def.category) {
                    case 'error':
                        def.color = this.redColor;
                        break;
                    case 'warn':
                        def.color = this.yellowColor;
                        break;
                    case 'info':
                    case 'success':
                    default:
                        def.color = this.greenColor;
                }
            }
            return def;
        }
    }

    get theme() {
        let perPartner = {};

        if (this.brandingLoaded) {
            if (this.branding['ui.colors.primary'])
                perPartner.primary = this.branding['ui.colors.primary'];
            if (this.branding['ui.colors.secondary'])
                perPartner.secondary = this.branding['ui.colors.secondary'];
        }

        let perCompany = {};
        if (this.companyStore.currentCompany) {
            if (this.companyStore.currentCompany.color_main)
                perCompany.primary =
                    this.companyStore.currentCompany.color_main;
            if (this.companyStore.currentCompany.color_secondary)
                perCompany.secondary =
                    this.companyStore.currentCompany.color_secondary;
        }

        let theme = _.defaults(
            {},
            perCompany,
            perPartner,
            styledComponentsThemeFile
        );
        return theme;
    }

    get termsUrl() {
        const defaultTermsUrl = 'https://www.awarego.com/terms/';
        if (!this.brandingLoaded) return defaultTermsUrl;
        return (
            (this.branding && this.branding['ui.terms_url']) || defaultTermsUrl
        );
    }

    get muiTheme() {
        const theme = muiThemeFile;

        if (this.brandingLoaded) {
            if (this.branding['ui.colors.primary'])
                theme.palette.primary = theme.palette.augmentColor({
                    main: this.branding['ui.colors.primary'],
                });
            if (this.branding['ui.colors.secondary'])
                theme.palette.secondary = theme.palette.augmentColor({
                    main: this.branding['ui.colors.secondary'],
                });
        }

        return theme;
    }

    setDefaultMiniHraType(type) {
        const legacyMapping = { 'cybersecurity-challenge': 'police' };
        if (legacyMapping[type]) type = legacyMapping[type];
        this.defaultMiniHraType = type || 'police';
    }

    landingPageType(type) {
        if (type != 'mini-hra') return 'short';
    }

    publicHRAVariant(type) {
        if (
            type !== 'ai-challenge' && //TODO: need to remove this hardcode constant string
            this.brandingLoaded &&
            this.branding &&
            this.branding['ui.cybersecurity-challenge.code']
        )
            return this.branding['ui.cybersecurity-challenge.code'];

        return this.defaultMiniHraType;
    }

    get trainingSkipAutoFinish() {
        if (!this.brandingLoaded) return false;
        return (
            this.branding &&
            this.branding['ui.training.skip_auto_finish'] === '1'
        );
    }

    privacyText(variant) {
        if (variant === 'ai-challenge') {
            return ' We will not store or use your email address. We just wanna prevent AI bots from testing themselves. For further details see our';
        } else {
            return 'For further details see our';
        }
    }

    get brandingLanguage() {
        if (!this.brandingLoaded) return null;
        return this.branding && this.branding['language'];
    }

    get speechOverEnabled() {
        if (!this.brandingLoaded) return false;
        return this.branding && this.branding['ui.speech_over'];
    }
}

export default BrandingStore;
