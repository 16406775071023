import React from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

@inject('brandingStore', 'authStore')
@observer
class SSO extends React.Component {
    componentDidMount() {
        console.log(this.props.brandingStore.loginUrl);
        if (this.props.brandingStore.loginUrl)
            window.location = this.props.brandingStore.loginUrl;
    }

    render() {
        return this.props.brandingStore.loginUrl ? null : <Redirect to={'/'} />;
    }
}

export default SSO;
