import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Hidden,
} from '@material-ui/core';
import Spinner from '../../Spinner';
import { Link } from 'react-router-dom';
import SemiCircleProgress from '../../semi-circle-progress';
import AdditionalInfo from './AdditionalInfo';

function ReviewTableSingleThreat({
    loadingAssessmentResults,
    results,
    shareResults,
    parentUrl,
    trainingId,
    assessmentId,
}) {
    const { t } = useTranslation();
    const singleTA = Object.values(results)[0];

    return (
        <Table className="quiz-results-table">
            <TableHead>
                <TableRow>
                    <TableCell>
                        <span className="mobileOnly">
                            {t('Question')}/{t('Threat area')}
                        </span>
                        <span className="desktopOnly">{t('Question')}</span>
                    </TableCell>
                    <Hidden smDown>
                        <TableCell>{t('Threat area')}</TableCell>
                    </Hidden>
                    <TableCell align="center">{t('Score')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {loadingAssessmentResults ? (
                    <TableRow>
                        <TableCell colSpan={3}>
                            <div
                                className="spinner-table small"
                                style={{ textAlign: 'center' }}
                            >
                                <Spinner />
                            </div>
                        </TableCell>
                    </TableRow>
                ) : (
                    <>
                        {Object.keys(results).map((key) => {
                            return results[key].questions.map((q, i) => {
                                return (
                                    <TableRow key={q.name + i}>
                                        <TableCell>
                                            {shareResults ? (
                                                t('assessment:' + q.name)
                                            ) : (
                                                <Link
                                                    to={`${parentUrl}${
                                                        trainingId
                                                            ? '/subjects/quiz/answers'
                                                            : `/${assessmentId}/answers`
                                                    }?index=${q.order}`}
                                                >
                                                    {t('assessment:' + q.name)}
                                                </Link>
                                            )}

                                            <div className="mobileOnly">
                                                <br />
                                                {t('assessment:' + q.area)}
                                            </div>
                                        </TableCell>
                                        <Hidden smDown>
                                            <TableCell>
                                                {t('assessment:' + q.area)}
                                            </TableCell>
                                        </Hidden>
                                        <TableCell>
                                            <div className="progress-semicircle small">
                                                <SemiCircleProgress
                                                    percentage={
                                                        q.averageScore ||
                                                        Math.max(
                                                            0,
                                                            Math.round(
                                                                (q.total_score /
                                                                    q.max_score) *
                                                                    100
                                                            )
                                                        )
                                                    }
                                                    size="small"
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            });
                        })}

                        {singleTA && singleTA.additionalInfo && (
                            <AdditionalInfo
                                text={singleTA.additionalInfo.text}
                                linkTarget={singleTA.additionalInfo.linkTarget}
                                linkText={singleTA.additionalInfo.linkText}
                            />
                        )}
                    </>
                )}
            </TableBody>
        </Table>
    );
}

export default observer(ReviewTableSingleThreat);
