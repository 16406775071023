import React from 'react';
import {
    SpaceBetweenDiv,
    ThemedButton,
    TextInput,
} from '@awarego/awarego-components';
import LinkIcon from '@material-ui/icons/Link';
import { useStores } from '../../hooks/use-stores';

function CopyLink({ link = '' }) {
    const { commonStore } = useStores();
    return (
        <SpaceBetweenDiv $noWrap gap={8}>
            <TextInput
                disabled
                stretched
                inline
                noYPadding
                value={`${link.slice(0, 29)}...`}
                leftIcon={<LinkIcon fontSize="small" />}
            />
            <ThemedButton
                mode="secondary"
                height={48}
                $noWrap
                onClick={() => {
                    navigator.clipboard.writeText(link);
                    commonStore.success(
                        'Link copied. Share it with your team and receive their feedback via email.'
                    );
                }}
                text="Copy link"
            />
        </SpaceBetweenDiv>
    );
}

export default CopyLink;
