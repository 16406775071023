import BaseService from './BaseService';

class RestService extends BaseService {
    constructor(baseUrl) {
        super();
        this.baseUrl = baseUrl;
    }

    getId(entity) {
        if (typeof entity === 'string') return entity;
        return entity && (entity.id || entity._id);
    }

    create(entity, options) {
        return this.post(this.baseUrl, entity, options);
    }

    fetch(entityId) {
        return this.get(`${this.baseUrl}/${entityId}`);
    }

    list(params) {
        return this.get(`${this.baseUrl}`, params);
    }

    update(entity) {
        return this.put(`${this.baseUrl}/${this.getId(entity)}`, entity);
    }

    delete(entity) {
        return this.del(`${this.baseUrl}/${this.getId(entity)}`);
    }
}

export default RestService;
