import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import {
    TextDefault,
    FlexDiv,
    ThemedButton,
    TextInput,
} from '@awarego/awarego-components';
import { useStores } from '../hooks/use-stores';
import DownloadIcon from '@material-ui/icons/GetApp';

export default observer(({ onDownload, isDemo }) => {
    const { t } = useTranslation();

    const { authStore, store, companyStore, demoStore } = useStores();

    const { currentUser } = authStore;
    const { creatingCertificate, certificateError } = store;
    const { currentCompany } = companyStore;

    const [name, setName] = useState(null);
    const [showDownloading, setShowDownloading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (isDemo && demoStore && demoStore.demoUser)
            setName(demoStore.demoUser.name);
        if (currentUser) setName(currentUser.name);
    }, [currentUser, demoStore, isDemo]);

    const onDownloadClick = async () => {
        // When name change is disabled, name isn't required and therefore don't show error when it is missing.
        if (
            currentCompany &&
            currentCompany.settings['ui.certificate_name_change_disabled']
        ) {
            await onDownload(name);
            setShowDownloading(true);
        } else {
            if (name) {
                setError('');
                await onDownload(name);
                if (certificateError) {
                    setError(certificateError);
                } else {
                    setShowDownloading(true);
                }
                if (currentUser && name !== currentUser.name) {
                    await authStore.profileUpdate({ name: name });
                }
            } else {
                setError('Name is required');
            }
        }
    };

    const onChangeName = (e) => {
        setName(e.target.value);
    };

    const nameVerification = useMemo(() => {
        if (!currentCompany) return;
        return (
            !showDownloading &&
            !currentCompany.settings['ui.certificate_name_change_disabled'] && (
                <TextInput
                    onChange={onChangeName}
                    label={t('Verify your name')}
                    type="text"
                    placeholder={t('Enter your name')}
                    value={name}
                    error={error || creatingCertificate}
                />
            )
        );
    }, [creatingCertificate, currentCompany, error, name, showDownloading, t]);

    return (
        <FlexDiv column gap={32}>
            <Helmet>
                <title>{t('Get your Certificate!')}</title>
            </Helmet>
            {nameVerification}
            {!showDownloading && (
                <ThemedButton
                    mode="primary"
                    onClick={onDownloadClick}
                    center
                    text={
                        <>
                            <DownloadIcon /> {t('Download')}
                        </>
                    }
                />
            )}
            {showDownloading && (
                <TextDefault center lighter>
                    {t('Your certificate should download in a few moments')}
                </TextDefault>
            )}
        </FlexDiv>
    );
});
