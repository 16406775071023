import React from 'react';
import { observer } from 'mobx-react';
import {
    CircularProgressbarWithChildren,
    buildStyles,
} from 'react-circular-progressbar';
import { useStores } from '../hooks/use-stores';
import { useTranslation } from 'react-i18next';
import { calculateColor } from '../utils/helpers';

export default observer(({ percentage, size }) => {
    const { brandingStore } = useStores();
    const { t } = useTranslation();

    const setBarWidth = () => {
        if (size === 'large') return 14;
        return 14;
    };
    const setcircleRatio = () => {
        if (size === 'large') return 0.58;
        return 0.48;
    };
    const setRotation = () => {
        if (size === 'large') return 0.7;
        return 0.76;
    };

    return (
        <CircularProgressbarWithChildren
            circleRatio={setcircleRatio()}
            value={percentage}
            strokeWidth={setBarWidth()}
            className="semicircle"
            styles={buildStyles({
                rotation: setRotation(),
                strokeLinecap: 'round',
                textSize: '20px',
                pathTransitionDuration: 1,
                textAlign: 'center',
                fontWeight: 'bold',
                pathColor: calculateColor(percentage, brandingStore),
                textColor: '#000',
                trailColor: '#EBEEF1',
                backgroundColor: '#FCBE00',
            })}
        >
            {size === 'large' && (
                <div className="text">
                    <div className="score">{percentage}</div>
                    <div className="description">{t('of 100')}</div>
                </div>
            )}
            {size === 'small' && (
                <div className="text small">
                    <div
                        className="score"
                        style={{
                            color: calculateColor(percentage, brandingStore),
                        }}
                    >
                        {' '}
                        {percentage}
                    </div>
                </div>
            )}
        </CircularProgressbarWithChildren>
    );
});
