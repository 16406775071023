import { makeAutoObservable, reaction } from 'mobx';
import services from '../services';
import uuid from 'uuid/v4';
import { generateCreateEntity, generateLoadEntity } from '../utils/mobx';
import subjectStore from './models/subjectStore';
import { extractErrorMessage } from '../utils/helpers';
import every from 'lodash/every';
import defaults from 'lodash/defaults';
import { EVENTS } from '../utils/constants';
import Events from './events';
import { addBeforeUnloadReactionToAssessmentStore } from './mixins/beforeUnload';
const emailAddress = require('email-addresses');
const contentDisposition = require('content-disposition');

function tryParseLocalStorageItem(key) {
    try {
        return JSON.parse(window.localStorage.getItem(key));
    } catch (e) {
        return null;
    }
}

class AssessmentDemoStore {
    loadingData = false;
    loadingAssessmentResults = false;
    _questionAction = false;
    loadingAssessmentId = null;
    loadingTrainingId = null;
    startingAssessmentId = null;
    error = null;
    assessments = [];
    results = [];
    assessmentAnswers = {};
    assessment = null;
    training = null;
    statusUpdate = 0;
    reviewingQuestions = [];
    question = null;
    demoUser = tryParseLocalStorageItem('demo-user');
    reportTo = null;
    sessionId = window.localStorage.getItem('demo-session-id') || null;
    loadingQuestion = false;
    sessionEvents = {};
    duration = 0;
    creatingCertificate = false;
    certificateError = null;

    constructor(authStore, companyStore, mainStore, commonStore) {
        makeAutoObservable(this);
        this.authStore = authStore;
        this.companyStore = companyStore;
        this.mainStore = mainStore;
        this.commonStore = commonStore;
        reaction(
            () => this.sessionId,
            (sessionId) => {
                if (sessionId) {
                    window.localStorage.setItem('demo-session-id', sessionId);
                } else {
                    window.localStorage.removeItem('demo-session-id');
                }
            }
        );

        reaction(
            () => this.demoUser,
            (demoUser) => {
                if (demoUser) {
                    window.localStorage.setItem(
                        'demo-user',
                        JSON.stringify(demoUser)
                    );
                } else {
                    window.localStorage.removeItem('demo-user');
                }
            }
        );

        addBeforeUnloadReactionToAssessmentStore(this);
    }

    setReportTo(value) {
        this.reportTo = value;
        if (this.companyId) {
            const existingReportToData =
                tryParseLocalStorageItem('demo-report-to') || {};
            existingReportToData[this.companyId] = value;
            window.localStorage.setItem(
                'demo-report-to',
                JSON.stringify(existingReportToData)
            );
        }
    }

    generateSessionId() {
        this.sessionId = uuid();
    }

    setCompanyId(value) {
        this.companyId = value;
        const existingReportToData =
            tryParseLocalStorageItem('demo-report-to') || {};
        this.reportTo = existingReportToData[value];
    }

    setSessionId(value) {
        this.sessionId = value;
    }

    setContext(value) {
        this.context = value;
    }

    logout() {
        this.assessments.replace([]);
        this.loadingData = false;
    }

    setError(error) {
        this.error = error;
        this.commonStore.error(error);
    }
    setDemoUser(email, name) {
        this.demoUser = { email, name, id: uuid() };
        this.generateSessionId();
    }

    restart() {
        this.demoUser = null;
        this.sessionId = null;
        // restart duration of the demo to original value
        if (this.duration !== 0)
            this.assessment.status.timeLeft = this.duration;
    }

    get isAuthenticated() {
        return !!this.demoUser;
    }

    get parsedReplyTo() {
        if (!this.reportTo) return null;
        const parsed = emailAddress.parseOneAddress(this.reportTo);
        return parsed;
    }

    get reportToEmail() {
        if (this.parsedReplyTo) return this.parsedReplyTo.address;
        return null;
    }

    get reportToName() {
        if (this.parsedReplyTo) return this.parsedReplyTo.name;
        return null;
    }

    get requestParams() {
        return {
            ...this.context,
            user: this.demoUser,
            sessionId: this.sessionId,
        };
    }

    async loadAssessment(_, background) {
        if (!this.sessionId) return; // not initialized yet
        if (!background) {
            if (this.loadingAssessmentId) return;
            this.loadingAssessmentId = true;
        }
        this.setError(null);
        try {
            let data = await services.Companies.demoService(
                this.companyId
            ).myAssessment({
                ...this.requestParams,
                l: this.mainStore.language,
            });
            this.assessment = data;
            //this.subjects.replace(data.subjects);
            this.mainStore.setLayoutTitle(data.name);
        } catch (e) {
            this.setError(extractErrorMessage(e));
        } finally {
            this.loadingAssessmentId = false;
        }
    }

    async loadTraining(background) {
        if (!background) {
            if (this.loadingTrainingId) return;
            this.loadingTrainingId = true;
        }
        this.setError(null);
        try {
            let data = await services.Companies.demoService(
                this.companyId
            ).myTraining({
                ...this.requestParams,
                l: this.mainStore.language,
            });
            if (data.subjects) {
                let subjects = [];
                for (const subject of data.subjects) {
                    if (subject.type === 3) {
                        subjects.push(new subjectStore(subject));
                    } else subjects.push(subject);
                }
                data.subjects = subjects;
            }
            this.training = data;
            //this.subjects.replace(data.subjects);
            this.mainStore.setLayoutTitle(data.name);
        } catch (e) {
            this.setError(extractErrorMessage(e));
        } finally {
            this.loadingTrainingId = null;
        }
    }

    async reloadTraining() {
        this.training && this.loadTraining(true);
    }

    async startAssessment(_, targetObj) {
        if (this.startingAssessmentId) return;
        this.startingAssessmentId = true;

        this.setError(null);
        try {
            let data = await services.Companies.demoService(
                this.companyId
            ).startAssessment(this.requestParams);
            if (data) {
                Object.assign(this.assessment, data);
                if (targetObj) Object.assign(targetObj, data);
            }

            // save assessment demo duration in case user doesn't finish demo on time and wants to Start again
            this.duration = data.status.timeLeft;
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.startingAssessmentId = null;
        }
    }

    loadQuestion = generateLoadEntity(
        'question',
        this,
        'loadingQuestion',
        async (companyId, assessmentId) => {
            return services.Companies.demoService(
                companyId
            ).getPersonalizedQuestion(assessmentId, this.requestParams);
        },
        'question'
    );

    loadResults = generateLoadEntity(
        'results',
        this,
        'loadingAssessmentResults',
        async (companyId) => {
            return services.Companies.demoService(companyId).results(
                this.requestParams
            );
        },
        'results'
    );

    loadAnswers = generateLoadEntity(
        'assessmentAnswers',
        this,
        'loadingAssessmentResults',
        async (companyId) => {
            return services.Companies.demoService(companyId).answers(
                this.requestParams
            );
        },
        'assessmentAnswers'
    );

    completeQuestion = generateCreateEntity(
        'completeQuestion',
        this,
        '_questionAction',
        (assessmentId, questionId, questions) => {
            return services.Companies.demoService(
                this.companyId
            ).questionActions(questionId, questions, this.requestParams);
        },
        (result, originalArguments) => {
            if (result) {
                Object.assign(this.assessment, result);
                if (originalArguments.assessment) {
                    Object.assign(originalArguments.assessment, result);
                }
            }
        }
    );

    sendFeedback = generateCreateEntity(
        'sendFeedback',
        this,
        'sendingFeedback',
        (feedbackCode, isAssessment) => {
            return services.Companies.demoService(this.companyId)
                .feedbackService()
                .create({
                    feedbackCode,
                    reportTo: this.reportTo,
                    isTraining: !isAssessment,
                    isAssessment,
                    ...this.requestParams,
                });
        }
    );

    isSubjectDone(subject) {
        return (
            subject.status.ANSWARE_CORRECT > 0 &&
            (subject.status.VIDEO_95 > 0 || subject.status.VIDEO_100 > 0)
        );
    }

    isSubjectPassed(subject) {
        return (
            subject.status.ANSWARE_CORRECT > 0 &&
            (subject.status.VIDEO_95 > 0 ||
                subject.status.VIDEO_100 > 0 ||
                subject.type === 3)
        );
    }

    get viewedVideos() {
        return this.subjects.filter((s) => this.isSubjectDone(s)).length;
    }

    isSubjectDelivered() {
        return true;
    }

    get subjectsTODO() {
        return this.subjects.filter((x) => {
            if (x.type === 3)
                return x.isSubjectDelivered() && !x.isSubjectDone();
            else return this.isSubjectDelivered(x) && !this.isSubjectDone(x);
        });
    }

    get deliveredSubjects() {
        return this.subjects.filter((x) => this.isSubjectDelivered(x));
    }

    get availableUnfinishedSubjects() {
        return this.subjects.filter(
            (x) => this.isSubjectDelivered(x) && !this.isSubjectPassed(x)
        );
    }

    get subjectsDone() {
        return this.subjects.filter((x) => {
            if (x.type === 3)
                return x.isSubjectDelivered() && x.isSubjectDone();
            else {
                return this.isSubjectDelivered(x) && this.isSubjectDone(x);
            }
        });
    }

    get subjects() {
        return this.training && this.training.subjects
            ? this.training.subjects
            : [];
    }

    get certificateQualified() {
        return (
            every(this.subjects, this.isSubjectPassed) &&
            (!this.training.assessment_id ||
                (this.training &&
                    this.training.assessment &&
                    this.training.assessment.status &&
                    this.training.assessment.status.finished))
        );
    }

    get isStarted() {
        return (
            this.assessment &&
            (this.assessment.started || this.assessment.status.started)
        );
    }

    get done() {
        return (
            this.assessment &&
            this.assessment.status &&
            (this.assessment.status.questionsDone.length /
                this.assessment.definition.questions.length) *
                100
        );
    }

    get isFinished() {
        return (
            this.assessment &&
            this.assessment.status &&
            (this.assessment.status.finished ||
                this.assessment.status.questionsDone.length ===
                    this.assessment.definition.questions.length)
        );
    }

    get isExpired() {
        return (
            this.assessment &&
            this.assessment.status &&
            this.assessment.status.expired &&
            !this.assessment.status.started
        );
    }

    get isTimedOut() {
        return (
            this.assessment &&
            this.assessment.status &&
            this.assessment.status.timeLeft <= 0
        );
    }

    setTimedOut() {
        this.assessment.status.timeLeft = 0;
    }
    get answers() {
        return this.mainStore.answers;
    }
    setCurrentSubjectAnswers(subject) {
        return this.mainStore.setCurrentSubjectAnswers(subject);
    }

    onLogEvent = (trainingId, subject, event_id, context, options) => {
        options = defaults({}, options, { send: true });
        let company_id = this.companyId;
        let subject_id = null;
        let subject_name = '';
        let company_name = '';
        //this.showSubjectDoneNotification(event_id);

        if (subject && typeof subject === 'object') {
            subject_id = subject.id;
            subject_name = subject.title;
        } else subject_id = subject;

        const alwaysLog = [EVENTS.ANSWARE_INCORRECT].includes(event_id);
        const eventKey =
            context && context.decision
                ? `${company_id}:demo:${subject_id}:${event_id}:${context.decision}:`
                : `${company_id}:demo:${subject_id}:${event_id}:`;

        if (options.send && (alwaysLog || !this.sessionEvents[eventKey])) {
            services.Companies.demoService(company_id).eventsService().create({
                event_id,
                trainingId,
                sessionId: this.sessionId,
                company_id,
                subject_id,
                context,
            });
            Events.callGoogleAnalytics(event_id, subject_name, company_name, {
                ...context,
                isDemo: true,
            });
            this.sessionEvents[eventKey] = true;
        }
    };

    async downloadCertificate(trainingId, name) {
        this.certificateError = null;
        this.creatingCertificate = true;

        try {
            const options = {
                responseType: 'blob',
            };

            let response;
            response = await services.Companies.demoService(
                this.companyId
            ).downloadCertificate(
                trainingId,
                {
                    name,
                    ...this.requestParams,
                },
                options
            );

            const blob = new Blob([response.data], {
                type: response.headers['content-type'],
            });
            const url = window.URL.createObjectURL(blob);
            let fileName = `certificate.pdf`;
            const disposition =
                response.headers['content-disposition'] &&
                contentDisposition.parse(
                    response.headers['content-disposition']
                );
            if (
                disposition &&
                disposition.type === 'attachment' &&
                disposition.parameters &&
                disposition.parameters.filename
            ) {
                fileName = disposition.parameters.filename;
            }

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            let err = extractErrorMessage(e);
            this.certificateError = err;
        } finally {
            this.creatingCertificate = false;
        }
    }

    shareUrl() {
        return null;
    }
}

export default AssessmentDemoStore;
