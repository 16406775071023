import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../hooks/use-stores';
import {
    FlexDiv,
    Heading1,
    TextDefault,
    ThemedButton,
} from '@awarego/awarego-components';

function EmailSignInError() {
    const { authStore } = useStores();
    let history = useHistory();
    const { isAuthenticated, currentUser } = authStore;
    const { t } = useTranslation();

    const handleSignout = () => {
        authStore.logout();
        history.push('/');
    };

    return (
        <FlexDiv justifyCenter alignCenter wrapper column>
            {isAuthenticated ? (
                <Fragment>
                    <Heading1 mb={16} mt={60} alignCenter>
                        {t('You are signed in as')}{' '}
                        <strong>{currentUser.email}</strong>
                    </Heading1>
                    <Link to="/">
                        <ThemedButton
                            mode="primary"
                            text={t('Go to frontpage')}
                        />
                    </Link>
                    <TextDefault
                        onClick={() => handleSignout()}
                        link
                        lighter
                        underline
                        mt={16}
                    >
                        {t('Login as another user')}
                    </TextDefault>
                </Fragment>
            ) : (
                <Fragment>
                    <Heading1 mb={16}>{t('Unable to sign in')}</Heading1>
                    <TextDefault mb={16}>
                        {t(
                            'The link you tried to use to sign in was not valid.'
                        )}
                    </TextDefault>
                    <Link to="/auth/signin">
                        <ThemedButton
                            mode="secondary"
                            text={t('Request a new sign in link')}
                        />
                    </Link>
                </Fragment>
            )}
        </FlexDiv>
    );
}

export default observer(EmailSignInError);
