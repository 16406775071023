import React, { useEffect, useMemo, useCallback } from 'react';
import chunk from 'lodash/chunk';
import { observer } from 'mobx-react';
import { ThemedAnswer } from '../themed/ThemedComponents';
import { useTranslation } from 'react-i18next';
import { FlexDiv } from '@awarego/awarego-components';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const CONSTANTS = require('../../utils/constants');

export default observer(
    ({ subject, question, answers, isAnswered, logEvent }) => {
        const { t } = useTranslation();
        const [chunks, setChunks] = React.useState(chunk(answers, 2));
        const [clicked, setClicked] = React.useState({});

        useEffect(() => {
            setChunks(chunk(answers, 2));
            if (isAnswered) {
                setClicked({});
            }
        }, [answers, isAnswered]);

        const answer = useCallback(
            (answer, position) => {
                if (!isAnswered && !clicked[position]) {
                    setClicked({ ...clicked, [position]: true });
                    if (answer.correct) {
                        logEvent(subject, CONSTANTS.EVENTS.ANSWARE_CORRECT);
                    } else {
                        logEvent(subject, CONSTANTS.EVENTS.ANSWARE_INCORRECT);
                    }
                }
            },
            [clicked, isAnswered, logEvent, subject]
        );

        const btns = useMemo(
            () =>
                chunks.map((x, i) => (
                    <FlexDiv
                        gap={16}
                        key={'col-' + i}
                        flexGrow={1}
                        justifyCenter
                        alignCenter
                    >
                        {x.map((a, j) => (
                            <ThemedAnswer
                                correct={
                                    a.correct &&
                                    (isAnswered || clicked[`${i}-${j}`])
                                }
                                incorrect={!a.correct && clicked[`${i}-${j}`]}
                                onClick={() => answer(a, `${i}-${j}`)}
                                key={'ans-' + j}
                            >
                                {a.text}
                                {a.correct &&
                                    (isAnswered || clicked[`${i}-${j}`]) && (
                                        <CheckIcon
                                            style={{
                                                color: 'white',
                                            }}
                                            fontSize="small"
                                        />
                                    )}
                                {!a.correct && clicked[`${i}-${j}`] && (
                                    <CloseIcon
                                        style={{
                                            color: 'white',
                                        }}
                                        fontSize="small"
                                    />
                                )}
                            </ThemedAnswer>
                        ))}
                    </FlexDiv>
                )),
            [answer, chunks, clicked, isAnswered]
        );

        if (!question) {
            return null;
        } else {
            return (
                <div className="section-exam">
                    <div className="exam-question-holder">
                        {/* Todo add question counter*/}
                        <div className="exam-question-counter">
                            {t('Question')} 1/1
                        </div>
                        <div className="exam-question">{question}</div>
                        <FlexDiv gap={16} fullWidth justifyCenter desktopOnly>
                            {btns}
                        </FlexDiv>
                        <FlexDiv gap={16} fullWidth column mobileOnly>
                            {btns}
                        </FlexDiv>
                    </div>
                </div>
            );
        }
    }
);
