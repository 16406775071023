import React, { Fragment, useMemo, useCallback } from 'react';
import {
    FlexDiv,
    Heading3,
    TextDefault,
    ThemedButton,
    Heading2,
    palette,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { joinPath } from '../../utils/helpers';
import { useHistory } from 'react-router-dom';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import Errormessage from '../../components/errormessage';
import styled from 'styled-components';
import { resourcePath } from '../../utils/helpers';
import Logo from './logo';

const FlexDivWithBgImage = styled(FlexDiv)`
    background-image: url(${(props) => props.backgroundUrl});
    background-repeat: no-repeat;
    background-position: right top;
    background-size: auto 264px;
    @media (max-width: 992px) {
        background-size: auto 88px;
    }
`;

function UserFinishStep({ parentMatch, isFeedbackPage, isAssessment }) {
    const { demoStore } = useStores();
    const history = useHistory();
    const {
        assessment,
        loadingAssessmentId,
        reportToEmail,
        reportToName,
        feedBackError,
    } = demoStore;

    const sendFeedback = useCallback(
        async (feedbackResult) => {
            const result = await demoStore.sendFeedback(
                feedbackResult,
                isAssessment
            );
            if (result)
                history.replace(
                    joinPath(
                        parentMatch.url,
                        isAssessment ? 'assessment/finish' : 'training/finish'
                    )
                );
        },
        [demoStore, history, isAssessment, parentMatch.url]
    );

    const content = useMemo(() => {
        if (isFeedbackPage)
            return (
                <Fragment>
                    <Heading3 center>
                        Let {reportToName} know how you feel about this demo
                    </Heading3>
                    <FlexDiv gap={16}>
                        <ThemedButton
                            color={palette.vibrant.raspberry}
                            mode="primary"
                            roundSize={88}
                            onClick={() => {
                                sendFeedback('negative');
                            }}
                            text={<ThumbDownIcon />}
                        />
                        <ThemedButton
                            color={palette.evergreen.aspargus}
                            mode="primary"
                            roundSize={88}
                            onClick={() => {
                                sendFeedback('positive');
                            }}
                            text={<ThumbUpIcon />}
                        />
                    </FlexDiv>
                    <TextDefault lighter center>
                        Your response will be sent to {reportToEmail}
                    </TextDefault>
                </Fragment>
            );
        return (
            <Fragment>
                <Heading3 center>
                    Thank you! Your response has been sent to {reportToEmail}
                </Heading3>
                {!loadingAssessmentId && assessment && assessment.showBlog && (
                    <FlexDiv gap={8} rowGap={4} alignCenter justifyCenter>
                        <TextDefault lighter center>
                            Want to learn more about cybersecurity?
                        </TextDefault>
                        <TextDefault themed link bold>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={'https://awarego.com/blog'}
                            >
                                Visit our blog
                            </a>
                        </TextDefault>
                    </FlexDiv>
                )}
            </Fragment>
        );
    }, [
        isFeedbackPage,
        reportToEmail,
        reportToName,
        sendFeedback,
        loadingAssessmentId,
        assessment,
    ]);

    return (
        <FlexDiv
            layoutWrapper
            backgroundColor={isAssessment ? '#EBEDFC' : '#E9F6F1'}
            positionRelative
        >
            <Logo />
            <FlexDivWithBgImage
                backgroundUrl={resourcePath(
                    `/static/img/${
                        isAssessment ? 'blue' : 'green'
                    }-bubbles-bg.svg`
                )}
                mobileOnly
                minHeight={200}
            />
            <FlexDivWithBgImage
                column
                backgroundUrl={resourcePath(
                    `/static/img/${
                        isAssessment ? 'blue' : 'green'
                    }-bubbles-bg.svg`
                )}
                gap={4}
                alignCenter
                height={264}
                justifyCenter
                pb={32}
                desktopOnly
            >
                <Heading2 extraBold>Demo completed!</Heading2>
                {feedBackError && (
                    <Errormessage error={feedBackError} errorType={'error'} />
                )}
            </FlexDivWithBgImage>
            <FlexDiv
                flexGrow={1}
                backgroundColor={'white'}
                px={16}
                pb={64}
                alignStart
                justifyCenter
            >
                <FlexDiv
                    backgroundColor="white"
                    borderRadius={16}
                    column
                    padding={32}
                    gap={32}
                    mt={-64}
                    dropShadowBelow
                    alignCenter
                    justifyCenter
                    minHeight={240}
                    maxWidth={470}
                >
                    {content}
                </FlexDiv>
            </FlexDiv>
        </FlexDiv>
    );
}

export default observer(UserFinishStep);
