import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading1 } from '@awarego/awarego-components';

function EmptyItem({ item }) {
    const { t } = useTranslation();
    return (
        <div className="empty-item">
            <Heading1 themed>{t(`You have no assigned ${item}`)}</Heading1>
            <p>{t(`No ${item} assigned to you at this moment`)}</p>
            <div className="training-progress assessment-demo block hovered mobile demo">
                <div className="header"></div>
                <div className="footer"></div>
            </div>
        </div>
    );
}

export default EmptyItem;
