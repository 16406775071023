import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { formatDate, formatDateAndTime } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import './AssessmentsList.scss';
import EmptyItem from '../Home/EmptyItem';
import {
    ThemedButton,
    Heading2,
    FlexDiv,
    TextDefault,
    SemiCircleProgress,
} from '@awarego/awarego-components';
import OneMomentSpinner from '../../components/OneMomentSpinner';
import styled from 'styled-components';
import moment from 'moment';

const StyledFlexDiv = styled(FlexDiv)`
    border: 1px solid transparent;
    transition: 0.4s ease-out;

    @media (max-width: 992px) {
        padding: 16px;
        flex-direction: column;
    }

    &:hover {
        background: #e9f6f0;
        border: 1px solid rgba(32, 161, 107, 0.25);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
            0px 4px 24px rgba(29, 30, 29, 0.08);
        cursor: pointer;
    }
`;

export default observer(() => {
    const { t } = useTranslation();
    const { assessmentStore, store, commonStore } = useStores();
    const { loadingData, assessments } = assessmentStore;

    let history = useHistory();

    useEffect(() => {
        store.setLayoutTitle(t('Awareness and Compliance Assessment'));
    }, [store, t]);

    const openExpiredModal = (due) => {
        commonStore.showConfirm(
            t('expired_due_date', {
                due: formatDateAndTime(due),
            }),

            t('Close'),
            t('This assessment has expired.'),
            () => {},
            false,
            false
        );
    };

    return (
        <Fragment>
            {loadingData ? (
                <OneMomentSpinner />
            ) : (
                <FlexDiv column gap={16}>
                    {assessments.length > 0 ? (
                        assessments
                            .slice()
                            .reverse()
                            .map((a) => (
                                <StyledFlexDiv
                                    padding={24}
                                    gap={32}
                                    rowGap={16}
                                    alignCenter
                                    link
                                    onClick={() =>
                                        a.expired && !a.status.started
                                            ? openExpiredModal(a.due)
                                            : history.push(
                                                  `/assessments/${a.id}`
                                              )
                                    }
                                    key={a.id}
                                >
                                    <div>
                                        {!a.status.finished && (
                                            <img
                                                src="/static/img/icon-assessment-new.svg"
                                                alt={t('New assessment')}
                                            />
                                        )}
                                        {a.status.started &&
                                            (a.status.finished ||
                                                a.completed) && (
                                                <img
                                                    src="/static/img/icon-assessment-done.svg"
                                                    alt={t(
                                                        'Finished assessment'
                                                    )}
                                                />
                                            )}
                                        {a.expired && !a.status.started && (
                                            <img
                                                src="/static/img/icon-assessment-expired.svg"
                                                alt={t('Expired assessment')}
                                            />
                                        )}
                                    </div>
                                    <FlexDiv
                                        column
                                        justifyCenter
                                        gap={4}
                                        flexGrow={1}
                                    >
                                        <Heading2 center mobileOnly>
                                            {a.name}
                                        </Heading2>
                                        <Heading2 desktopOnly>
                                            {a.name}
                                        </Heading2>
                                        <TextDefault
                                            themed={
                                                (a.status &&
                                                    !a.status.finished &&
                                                    a.due) ||
                                                undefined
                                            }
                                        >
                                            {a.status &&
                                                !a.status.finished &&
                                                a.due && (
                                                    <>
                                                        <i className="date-icon due"></i>
                                                        {t('Due on')}{' '}
                                                        {formatDateAndTime(
                                                            a.due
                                                        )}
                                                    </>
                                                )}
                                            {a.status.started &&
                                                (a.status.finished ||
                                                    a.completed) && (
                                                    <>
                                                        <i className="date-icon done"></i>
                                                        {t('Completed on')}{' '}
                                                        {formatDate(
                                                            a.end || a.completed
                                                        )}
                                                    </>
                                                )}
                                            {a.expired && !a.status.started && (
                                                <>
                                                    <i className="date-icon expired"></i>
                                                    {t(
                                                        'This assessment has expired on'
                                                    )}{' '}
                                                    {formatDateAndTime(a.due)}.
                                                </>
                                            )}
                                        </TextDefault>
                                    </FlexDiv>
                                    <FlexDiv gap={32} alignCenter>
                                        {a.status && !a.status.finished && (
                                            <ThemedButton
                                                mode="primary"
                                                text={
                                                    a.status.started
                                                        ? t('Continue')
                                                        : t('Start')
                                                }
                                            />
                                        )}
                                        {a.status.started &&
                                            (a.status.finished ||
                                                a.completed) && (
                                                <Fragment>
                                                    <SemiCircleProgress
                                                        percentage={
                                                            a.averageScore ||
                                                            Math.max(
                                                                0,
                                                                Math.round(
                                                                    (a.score /
                                                                        a.max_score) *
                                                                        100
                                                                )
                                                            )
                                                        }
                                                        halfCircle
                                                        width={48}
                                                        percentageText
                                                        top={10}
                                                        mt={-10}
                                                    />
                                                    <TextDefault bold>
                                                        {t('View results')}
                                                    </TextDefault>
                                                </Fragment>
                                            )}
                                    </FlexDiv>
                                </StyledFlexDiv>
                            ))
                    ) : (
                        <EmptyItem item={'assessment'} />
                    )}
                </FlexDiv>
            )}
        </Fragment>
    );
});
