import RestService from './RestService';

export default class Users extends RestService {
    constructor() {
        super('/users');
    }

    changePassword(oldPassword, password) {
        return this.post('/auth/changePassword', { oldPassword, password });
    }

    forgotPassword(email) {
        return this.post('/auth/forgotPassword', { email });
    }

    resetPassword(token, password) {
        return this.post('/auth/resetPassword', { token, password });
    }

    switchLanguage(language) {
        return this.put(`${this.baseUrl}/setLanguage`, { language });
    }
}
