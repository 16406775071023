import { observer } from 'mobx-react';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Collapse,
} from '@material-ui/core';
import Spinner from '../../Spinner';
import SemiCircleProgress from '../../semi-circle-progress';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { palette, TextLead } from '@awarego/awarego-components';
import { Link } from 'react-router-dom';
import AdditionalInfo from './AdditionalInfo';
function ReviewTableMultiThreat({
    loadingAssessmentResults,
    results,
    shareResults,
    parentUrl,
    trainingId,
    assessmentId,
    preOpen = [],
}) {
    const { t } = useTranslation();
    const [openedAreas, setOpenedAreas] = useState(preOpen);

    const toggleArea = (id, e) => {
        let areas = [...openedAreas];

        if (openedAreas.includes(id)) {
            areas = areas.filter((x) => x !== id);
        } else {
            areas.push(id);
        }
        setOpenedAreas(areas);
        e.stopPropagation();
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('Threat area')}</TableCell>
                        <TableCell align="center">{t('Score')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loadingAssessmentResults ? (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <div
                                    className="spinner-table small"
                                    style={{ textAlign: 'center' }}
                                >
                                    <Spinner />
                                </div>
                            </TableCell>
                        </TableRow>
                    ) : (
                        results &&
                        Object.keys(results).map((key) => {
                            return (
                                <Fragment key={key}>
                                    <TableRow
                                        onClick={(e) => {
                                            toggleArea(key, e);
                                        }}
                                        style={{ borderBottom: 0 }}
                                    >
                                        <TableCell
                                            style={{
                                                borderBottom: 'unset',
                                                paddingBottom: 0,
                                            }}
                                        >
                                            {t('assessment:' + key)}
                                            {openedAreas.includes(key) ? (
                                                <ArrowDropDownIcon />
                                            ) : (
                                                <ArrowRightIcon />
                                            )}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                borderBottom: 'unset',
                                                paddingBottom: 0,
                                                width: '70px',
                                            }}
                                        >
                                            <div className="progress-semicircle small">
                                                <SemiCircleProgress
                                                    percentage={Math.round(
                                                        results[key]
                                                            .averageScore
                                                    )}
                                                    size="small"
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    {openedAreas.includes(key) && (
                                        <TableRow>
                                            <TableCell
                                                colSpan={2}
                                                style={{
                                                    borderBottom: 'unset',
                                                    backgroundColor:
                                                        palette.lightroom
                                                            .cloudLight,
                                                    paddingBottom: '0px',
                                                    paddingTop: '0px',
                                                }}
                                            >
                                                <Collapse
                                                    in={openedAreas.includes(
                                                        key
                                                    )}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <Table>
                                                        <TableHead
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        width: '70px',
                                                                    }}
                                                                ></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {results[
                                                                key
                                                            ].questions.map(
                                                                (q) => (
                                                                    <Fragment
                                                                        key={
                                                                            q.id
                                                                        }
                                                                    >
                                                                        <TableRow
                                                                            style={{
                                                                                borderBottom:
                                                                                    '2px solid white',
                                                                            }}
                                                                        >
                                                                            <TableCell
                                                                                style={{
                                                                                    width: '90%',
                                                                                }}
                                                                            >
                                                                                <TextLead
                                                                                    color={
                                                                                        palette
                                                                                            .graphite
                                                                                            .charcoal
                                                                                    }
                                                                                >
                                                                                    {shareResults ? (
                                                                                        t(
                                                                                            'assessment:' +
                                                                                                q.name
                                                                                        )
                                                                                    ) : (
                                                                                        <Link
                                                                                            to={`${parentUrl}${
                                                                                                trainingId
                                                                                                    ? '/subjects/quiz/answers'
                                                                                                    : `/${assessmentId}/answers`
                                                                                            }?index=${
                                                                                                q.order
                                                                                            }`}
                                                                                        >
                                                                                            {t(
                                                                                                'assessment:' +
                                                                                                    q.name
                                                                                            )}
                                                                                        </Link>
                                                                                    )}
                                                                                </TextLead>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <div className="progress-semicircle tiny">
                                                                                    <SemiCircleProgress
                                                                                        percentage={
                                                                                            q.averageScore ||
                                                                                            Math.max(
                                                                                                0,
                                                                                                Math.round(
                                                                                                    (q.total_score /
                                                                                                        q.max_score) *
                                                                                                        100
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                        size="small"
                                                                                    />
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </Fragment>
                                                                )
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell
                                            colSpan={2}
                                            style={{ paddingTop: 0 }}
                                        >
                                            {' '}
                                            {results[key].additionalInfo && (
                                                <AdditionalInfo
                                                    text={
                                                        results[key]
                                                            .additionalInfo.text
                                                    }
                                                    linkTarget={
                                                        results[key]
                                                            .additionalInfo
                                                            .linkTarget
                                                    }
                                                    linkText={
                                                        results[key]
                                                            .additionalInfo
                                                            .linkText
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            );
                        })
                    )}
                </TableBody>
            </Table>
        </>
    );
}

export default observer(ReviewTableMultiThreat);
