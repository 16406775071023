import React, { useEffect } from 'react';
import { querystring } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import { CircularProgressbar } from 'react-circular-progressbar';
import Errormessage from '../../components/errormessage';
import { useHistory } from 'react-router-dom';

function InfosecAssessment({ parentMatch }) {
    const { infosecStore } = useStores();
    const { joining, error } = infosecStore;
    const history = useHistory();
    useEffect(() => {
        const email = querystring('email');
        if (email) {
            (async () => {
                await infosecStore.join(email);
                history.push(`${parentMatch.url}/assessment`);
            })();
        } else history.push(parentMatch.url);
    }, []);

    return (
        <>
            {JSON.stringify(joining)}
            <Errormessage error={error} errorType={'error'} />
            {joining ? <CircularProgressbar /> : null}
        </>
    );
}

export default InfosecAssessment;
