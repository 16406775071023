import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Waiting from '../components/waiting';
import { querystring } from '../utils/helpers';
import { useStores } from '../hooks/use-stores';
import { useHistory, useParams } from 'react-router-dom';

function SignIn() {
    const { authStore } = useStores();
    const history = useHistory();
    const { token } = useParams();

    useEffect(() => {
        if (token) {
            authStore.setToken(token);
            let redirect = querystring('redirect') || '/';
            if (redirect === 'undefined') redirect = '/';
            if (redirect === 'null') redirect = '/';
            if (!redirect.startsWith('/')) redirect = `/${redirect}`;
            history.push(redirect);
        }
    }, [token]);

    return <Waiting waitingActive={true} from={'TokenSignIn'} />;
}

export default observer(SignIn);
