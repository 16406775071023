import React from 'react';
import {
    palette,
    FlexDiv,
    StatusBadge,
    TextDefault,
    CheckCircle,
    CancelCircle,
} from '@awarego/awarego-components';
import { useTranslation } from 'react-i18next';

export default function DecisionFeedbackTable({ table, indexOffset = 0 }) {
    const { t } = useTranslation();

    const decisionTaken = (possibleDecision, decision) => {
        if (decision === 1) {
            return possibleDecision.decision_1.key === possibleDecision.answer;
        } else {
            return possibleDecision.decision_2.key === possibleDecision.answer;
        }
    };

    return (
        <FlexDiv column flexGrow={1} gap={24}>
            {table.map((decision, index) => (
                <FlexDiv key={index} column gap={16}>
                    <FlexDiv gap={8}>
                        <TextDefault lighter>
                            Decision {index + 1 + indexOffset}
                        </TextDefault>
                        <TextDefault>{decision.questionText}</TextDefault>
                        {decision.answer === 2 && (
                            <StatusBadge
                                text={t('decisionSubjectNotSeenBadge')}
                                color={palette.graphite.heavyMetal}
                                thinner
                            />
                        )}
                    </FlexDiv>
                    <FlexDiv alignCenter>
                        <TextDefault
                            lighter={!decisionTaken(decision, 1)}
                            ml={!decisionTaken(decision, 1) && 24}
                        >
                            {decisionTaken(decision, 1) &&
                                (decision.decision_1.correct ? (
                                    <CheckCircle
                                        color={palette.evergreen.aspargus}
                                    />
                                ) : (
                                    <CancelCircle color={palette.vibrant.red} />
                                ))}
                            {t(decision.decision_1.text)}
                        </TextDefault>
                    </FlexDiv>
                    <FlexDiv alignCenter>
                        <TextDefault
                            lighter={!decisionTaken(decision, 2)}
                            ml={!decisionTaken(decision, 2) && 24}
                        >
                            {decisionTaken(decision, 2) &&
                                (decision.decision_2.correct ? (
                                    <CheckCircle
                                        color={palette.evergreen.aspargus}
                                    />
                                ) : (
                                    <CancelCircle color={palette.vibrant.red} />
                                ))}
                            {t(decision.decision_2.text)}
                        </TextDefault>
                    </FlexDiv>
                </FlexDiv>
            ))}
        </FlexDiv>
    );
}
