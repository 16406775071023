import RestService from './RestService';

export default class Events extends RestService {
    constructor(parentService) {
        if (parentService) {
            super(`${parentService.baseUrl}/events`);
        } else super('/events');
    }

    askTestEvent() {
        this.get(`${this.baseUrl}/testSocketIO`);
    }
}
