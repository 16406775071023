import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';
import TrainingView from '../Trainings/TrainingView';

function CMI5Training({ uniqueId, availableLanguages }) {
    const match = useRouteMatch();
    const { cmi5Store } = useStores();

    return (
        <TrainingView
            match={match}
            availableLanguages={availableLanguages}
            trainingId={uniqueId}
            language={cmi5Store.language}
            trainingStore={cmi5Store}
            store={cmi5Store}
            videoTokenStore={cmi5Store}
            redirectOnError={false}
            onClose={() => {
                cmi5Store.rxd.ConcedeControl();
            }}
            isScorm
        />
    );
}

export default CMI5Training;
