import React from 'react';
import { observer } from 'mobx-react';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import { rgba } from 'polished';

function ThemedEmailSentSVG({ primaryColor }) {
    return (
        <SvgLoader path="/static/img/assessment/quiz-demo.svg">
            <SvgProxy selector="#primary" fill={primaryColor} />
            <SvgProxy selector="#secondary" fill={rgba(primaryColor, 0.1)} />
        </SvgLoader>
    );
}

export default observer(ThemedEmailSentSVG);
