import React, { useEffect, useState } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { Route } from 'react-router';
import { joinPath, querystring } from '../../utils/helpers';
import AssessmentsAnswerView from '../Assessments/AssessmentsAnswerView';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import PackageLoader from './PackageLoader';
//https://awarego.ngrok.io/cmi5/1/course/ae5a3366-f591-47cb-ace6-aced167c73cb/T8EMoFxj12IW3ZAo0evc2fSHV3ncp9shsJsGrfY51NYtajT5zC/au/0

const RXD = window.parent.RXD;

function RXDHome() {
    const match = useRouteMatch();
    const { companyId, uniqueId, accessKey, auId } = match.params;
    const { authStore, cmi5Store, brandingStore } = useStores();
    const [ready, setReady] = useState();
    const { cmiUser } = cmi5Store;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        initRXD();
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        cmi5Store.setCompanyId(companyId);
        cmi5Store.setContext({ accessKey, auId: Number(auId) });
        brandingStore.setCustomBranding(companyId, accessKey);
        setReady(true);
    }, [companyId]);

    const initRXD = () => {
        if (RXD) {
            authStore.forgetUser();

            cmi5Store.setRXD(RXD);
        }
    };

    return (
        ready && (
            <Switch>
                <Route
                    path={`${joinPath(match.url, 'assessment/answers')}`}
                    render={() => {
                        let questionIndex = querystring('index');
                        return (
                            <AssessmentsAnswerView
                                companyId={companyId}
                                assessmentId={uniqueId}
                                currentUser={cmiUser}
                                questionStore={cmi5Store}
                                assessmentStore={cmi5Store}
                                parentUrl={match.url}
                                index={questionIndex}
                            />
                        );
                    }}
                />

                <Route
                    path={`${joinPath(match.url, ':id/answers')}`}
                    render={() => {
                        let questionIndex = querystring('index');
                        return (
                            <Redirect
                                to={`${joinPath(
                                    match.url,
                                    `assessment/answers${
                                        questionIndex
                                            ? `?index=${questionIndex}`
                                            : ''
                                    }`
                                )}`}
                            />
                        );
                    }}
                />
                <Route
                    render={() => (
                        <PackageLoader
                            {...{
                                parentMatch: match,
                                companyId,
                                uniqueId,
                                accessKey,
                                auId,
                            }}
                        />
                    )}
                />
            </Switch>
        )
    );
}

export default observer(RXDHome);
