import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import AssessmentsView from './AssessmentsView';
import { useStores } from '../../hooks/use-stores';
import { querystring } from '../../utils/helpers';
import AssessmentsAnswerView from './AssessmentsAnswerView';
import AssessmentsList from './AssessmentsList';
import HomeViewContainer from '../Home/HomeViewContainer';

export default observer(
    ({ companyId, isAssessmentEnabled, currentCompany }) => {
        const { assessmentStore, store, authStore, questionStore } =
            useStores();
        const { currentUser } = authStore;

        const match = useRouteMatch();

        useEffect(() => {
            store.resetContext();
        }, [companyId, store]);

        const language = store.language;

        if (currentCompany) {
            return isAssessmentEnabled ? (
                <Switch>
                    <Route
                        path={`${match.url}`}
                        exact
                        render={() => (
                            <HomeViewContainer
                                layoutTitle="Awareness and Compliance Assessment"
                                code={'assessments'}
                                pageTitle={'assessments'}
                                content={<AssessmentsList />}
                            />
                        )}
                    />

                    <Route
                        path={`${match.url}/:id/answers`}
                        exact
                        render={(props) => {
                            let questionIndex = querystring('index');
                            return (
                                <AssessmentsAnswerView
                                    companyId={companyId}
                                    assessmentStore={assessmentStore}
                                    questionStore={questionStore}
                                    currentUser={currentUser}
                                    assessmentId={props.match.params.id}
                                    parentUrl={match.url}
                                    index={questionIndex}
                                />
                            );
                        }}
                    />

                    <Route
                        path={`${match.url}/:id/answers`}
                        render={(props) => (
                            <AssessmentsView
                                match={props.match}
                                parentUrl={match.url}
                                companyId={companyId}
                                assessmentId={props.match.params.id}
                                language={language}
                                assessmentStore={assessmentStore}
                                questionStore={questionStore}
                            />
                        )}
                    />

                    <Route
                        path={`${match.url}/:id`}
                        render={(props) => (
                            <AssessmentsView
                                match={props.match}
                                parentUrl={match.url}
                                companyId={companyId}
                                currentUser={currentUser}
                                assessmentId={props.match.params.id}
                                assessmentStore={assessmentStore}
                                questionStore={questionStore}
                                language={language}
                            />
                        )}
                    />
                </Switch>
            ) : (
                <Redirect to="/" />
            );
        } else {
            return null;
        }
    }
);
