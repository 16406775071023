import { reaction, makeAutoObservable } from 'mobx';
import keyBy from 'lodash/keyBy';
import defaults from 'lodash/defaults';
import services from '../services';
import Events from './events.js';
import i18n from 'i18next';
import moment from 'moment';
import { querystring, extractErrorMessage } from '../utils/helpers';
import { EVENTS } from '../utils/constants';
import shuffle from 'lodash/shuffle';
import contentDisposition from 'content-disposition';

class MainStore {
    layoutTitle = '';
    company = null;
    currentSubjectTextContent = '';
    showSubjectDoneAnimation = false;
    managerCompanies = null;
    i18n = null;
    hasConfirmed = false;
    error = null;
    certificateError = null;
    creatingCertificate = false;
    campaign_id = parseInt(querystring('c', window.location.search));
    subject_id = parseInt(querystring('s', window.location.search));
    automationEvent_id = parseInt(querystring('a', window.location.search));
    training_id = parseInt(querystring('t', window.location.search));
    utm_source = querystring('utm_source', window.location.search);
    utm_term = querystring('utm_term', window.location.search);
    language = window.localStorage.getItem('language') || 'en';
    loadingTokens = [];
    loadingScorms = [];
    answers = [];
    answersFor = null;
    loadingVideoUrl = false;
    loadingID = false;
    playerPresetCaptionsLanguage = null;
    playerCaptionsSettings = null;
    player = null;
    statusUpdate = 0;
    tokens = [];
    scorms = [];
    sessionEvents = {};

    constructor(commonStrore, authStore, companyStore, brandingStore) {
        makeAutoObservable(this);
        this.authStore = authStore;
        this.companyStore = companyStore;
        this.commonStrore = commonStrore;
        this.brandingStore = brandingStore;
        reaction(
            () => this.authStore.currentUser,
            (user) => {
                if (!user) {
                    this.logout();
                } else {
                    if (user.language) this.setLanguage(user.language, true);
                }
            }
        );

        reaction(
            () => this.language,
            (locale) => {
                i18n.changeLanguage(locale);
            }
        );
        reaction(
            () => this.companyStore.currentCompany,
            (company) => {
                if (!this.authStore.currentUser) return;
                if (
                    this.authStore.currentUser.language === null &&
                    company.defaultLanguage
                ) {
                    this.setLanguage(company.defaultLanguage, true);
                }
                // Hardcode Czech subtitles appearing by default when user has Czech as language, if another language gets added like this
                // it should be made a more integrated option (flag in database for language?)
                if (this.authStore.currentUser.language === 'cs') {
                    this.playerPresetCaptionsLanguage = 'Czech';
                }
                if (company.settings['ui.preset_caption_language']) {
                    this.playerPresetCaptionsLanguage =
                        company.settings['ui.preset_caption_language'];
                }
                if (company.settings['ui.captions_settings']) {
                    this.playerCaptionsSettings =
                        company.settings['ui.captions_settings'];
                }
            }
        );

        reaction(
            () => this.brandingStore.brandingLanguage,
            (value) => {
                if (value) {
                    const currentLanguage = localStorage.getItem('language');
                    if (!currentLanguage) {
                        this.setLanguage(value, true);
                    }
                }
            }
        );
    }

    get defaultHomeRedirect() {
        if (this.companyStore.currentCompany.isTrainingEnabled) {
            return 'trainings';
        } else if (this.companyStore.currentCompany.isAssessmentEnabled) {
            return 'assessments';
        } else {
            return '';
        }
    }

    async loadTrainingByEvent(eventId) {
        this.loadingID = true;
        try {
            this.training_id = await services.Trainings.idByEvent(eventId);
        } catch (e) {
            console.log(e);
        } finally {
            this.loadingID = false;
        }
    }

    logout = () => {
        this.companies = null;
        this.company = null;
        this.managerCompanies = null;
    };

    setI18n(i18n) {
        this.i18n = i18n;
    }

    async setLanguage(language_code, skipSaving) {
        await this.commonStrore.languagesLoader;
        window.localStorage.setItem('language', language_code);
        let changed = false;
        if (this.language !== language_code) {
            changed = true;
        }
        this.language = language_code;
        if (changed) this.tokens.replace([]);
        i18n.changeLanguage(language_code);
        moment.locale(language_code);
        document.documentElement.lang = language_code.replace('_', '-');
        if (!skipSaving) {
            await services.Users.switchLanguage(language_code);
        }
        //this.loadServerData()
    }

    setHasConfirmed = (hasConfirmed) => {
        this.hasConfirmed = hasConfirmed;
    };

    setError = (error) => {
        this.error = error;
    };

    setLayoutTitle = (title) => {
        this.layoutTitle = title;
    };

    setCurrentSubjectTextContent = (content) => {
        this.currentSubjectTextContent = content;
    };

    setShowSubjectDoneAnimation = (showAnimation) => {
        this.showSubjectDoneAnimation = showAnimation;
    };

    setCampaignById = (campaign_id) => {
        this.campaign_id = parseInt(campaign_id);
    };

    setCurrentSubjectAnswers = (subject) => {
        this.answersFor = subject.subject_id;
        let answers = subject
            ? [
                  { text: subject.wrong_answer1, correct: false },
                  { text: subject.wrong_answer2, correct: false },
                  { text: subject.wrong_answer3, correct: false },
                  { text: subject.correct_answer, correct: true },
              ]
            : [];
        this.answers.replace(shuffle(answers));
    };

    async getCurrentSubjectContentText(subject) {
        if (subject && subject.api_id_content) {
            const textContent = await services.Subjects.getTextContent(
                subject.api_id_content
            );
            if (
                textContent &&
                textContent.content &&
                textContent.content.rendered
            )
                this.setCurrentSubjectTextContent(textContent.content.rendered);
            else this.setCurrentSubjectTextContent('');
        } else {
            this.setCurrentSubjectTextContent('');
        }
    }

    setCompanyById = (id) => {
        id = parseInt(id);
        if (id && this.companies && this.companies.length > 0) {
            for (
                let a = 0, lengthCompanies = this.companies.length;
                a < lengthCompanies;
                a++
            ) {
                if (this.companies[a]) {
                    if (this.companies[a].company_id === id) {
                        this.company = this.companies[a];
                        return;
                    }
                }
            }
        }
    };

    get contextParams() {
        let params = {};
        if (this.companyStore.currentCompany)
            params.o = this.companyStore.currentCompany.company_id;
        if (this.campaign_id) params.c = this.campaign_id;
        if (this.automationEvent_id) params.a = this.automationEvent_id;
        if (this.training_id) params.t = this.training_id;
        if (this.language) params.l = this.language;

        if (this.utm_source) params.utm_source = this.utm_source;
        if (this.utm_term) params.utm_term = this.utm_term;

        return params;
    }

    resetContext() {
        this.automationEvent_id = null;
        this.training_id = null;
        const companyIdOutside = parseInt(
            querystring('o', window.location.search)
        );
        if (
            companyIdOutside &&
            this.companyStore.companyId !== companyIdOutside
        ) {
            this.companyStore.setCurrentCompany(companyIdOutside);
        }
    }

    onLogEvent = (trainingId, subject, event_id, context, options) => {
        options = defaults({}, options, { send: true });
        let email = null;
        let company_id = -1;
        let subject_id = null;
        let subject_name = '';
        let company_name = '';
        //this.showSubjectDoneNotification(event_id);

        if (this.authStore.currentUser) {
            email = this.authStore.currentUser.email;
        }
        if (!email && this.demoStore && this.demoStore.sessionId) {
            email = this.demoStore.sessionId;
        }
        if (this.companyStore.currentCompany) {
            company_id = this.companyStore.currentCompany.company_id;
            company_name = this.companyStore.currentCompany.company_name;
        }
        if (subject && typeof subject === 'object') {
            subject_id = subject.id;
            subject_name = subject.title;
        } else subject_id = subject;

        const alwaysLog = [EVENTS.ANSWARE_INCORRECT].includes(event_id);
        const eventKey =
            context && context.decision
                ? `${company_id}:${trainingId}:${subject_id}:${event_id}:${context.decision}:`
                : `${company_id}:${trainingId}:${subject_id}:${event_id}:`;

        if (
            email &&
            options.send &&
            (alwaysLog || !this.sessionEvents[eventKey])
        ) {
            Events.log(event_id, trainingId, company_id, subject_id, context);
            Events.callGoogleAnalytics(
                event_id,
                subject_name,
                company_name,
                context
            );
            this.sessionEvents[eventKey] = true;
        }
    };

    async downloadCertificate(trainingId, name) {
        this.certificateError = null;
        this.creatingCertificate = true;

        try {
            const options = {
                responseType: 'blob',
            };

            let response;
            response = await services.Trainings.downloadCertificate(
                trainingId,
                {
                    name,
                },
                options
            );

            const url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type: response.headers['content-type'],
                })
            );
            let fileName = `certificate.pdf`;
            const disposition =
                response.headers['content-disposition'] &&
                contentDisposition.parse(
                    response.headers['content-disposition']
                );
            if (
                disposition &&
                disposition.type === 'attachment' &&
                disposition.parameters &&
                disposition.parameters.filename
            ) {
                fileName = disposition.parameters.filename;
            }

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            let err = extractErrorMessage(e);
            this.certificateError = err;
        } finally {
            this.creatingCertificate = false;
        }
    }

    async sendCertificate(companyId, trainingId, name, updateName) {
        this.certificateError = null;
        this.creatingCertificate = true;

        try {
            await services.Companies.certificateService(companyId).create({
                trainingId,
                name,
                updateName,
            });
        } catch (e) {
            let err = extractErrorMessage(e);
            console.log(err);
            this.certificateError = err;
        } finally {
            this.creatingCertificate = false;
        }
    }

    get companyId() {
        if (this.cmiStore && this.cmiStore.companyId) {
            return this.cmiStore.companyId;
        }
        return this.companyStore.currentCompany
            ? this.companyStore.currentCompany.company_id
            : this.companyStore.companyId;
    }

    async videoToken(videoId) {
        if (this.loadingTokens.includes(videoId)) return false;

        this.loadingTokens.push(videoId);
        try {
            let token = await services.Companies.subjectsService(
                this.companyId
            ).getVideoToken(videoId, this.language); //videoId

            let t = this.tokens.find((x) => x.id === videoId);
            if (t) this.tokens.remove(t);
            this.tokens.push({ id: videoId, token });
        } finally {
            this.loadingTokens.remove(videoId);
        }
    }

    async loadVideoPlayerUrl(videoId) {
        if (this.loadingVideoUrl) return false;
        this.player = null;
        this.loadingVideoUrl = true;
        try {
            this.player = await services.Companies.subjectsService(
                this.companyId
            ).getVideoPlayerUrl(videoId);
        } catch (e) {
            console.log(e);
        } finally {
            this.loadingVideoUrl = false;
        }
    }

    get videoPlayerUrl() {
        if (
            this.player &&
            this.player.url &&
            this.player.exp > new Date().getTime()
        )
            return this.player.url;
        return null;
    }

    get tokenIndex() {
        return keyBy(this.tokens, 'id');
    }

    getTokenKey(subjectId) {
        return subjectId;
    }

    async scormInfo(subjectId) {
        if (this.loadingScorms.includes(subjectId)) return false;

        this.loadingScorms.push(subjectId);
        try {
            let response = await services.Companies.subjectsService(
                this.companyId
            ).getScormInfo(subjectId, this.language);
            let url = null;

            if (typeof response === 'object') {
                url = response.url;
                const domain = response.domain || window.location.host;
                const path = response.path || '/scorm';
                Object.keys(response.signedCookies).forEach((key) => {
                    document.cookie = `${key}=${response.signedCookies[key]}; path=${path}; domain=${domain};`;
                });
            } else url = response;

            let t = this.scorms.find((x) => x.id === subjectId);
            if (t) this.scorms.remove(t);
            this.scorms.push({ id: subjectId, url });
        } finally {
            this.loadingScorms.remove(subjectId);
        }
    }

    get scormIndex() {
        return keyBy(this.scorms, 'id');
    }
}

export default MainStore;
