import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../hooks/use-stores';
import {
    FlexDiv,
    Block,
    Heading1,
    TextInput,
    TextDefault,
    TextTiny,
    ThemedButton,
} from '@awarego/awarego-components';

function SignIn({ company_id, campaign_id, subject_id }) {
    const { authStore, brandingStore } = useStores();
    const { loginError } = authStore;
    const { account_name, termsUrl } = brandingStore;
    const { t } = useTranslation();

    let history = useHistory();

    const [email, setEmail] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value.trim());
    };

    const handleBlur = () => {
        localStorage.setItem('savedEmail', email);
    };

    // Load previously entered email from local storage
    useEffect(() => {
        const savedEmail = localStorage.getItem('savedEmail');
        if (savedEmail) {
            setEmail(savedEmail);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        authStore.setEmail(email);
        const result = await authStore.signin(
            email,
            company_id,
            campaign_id,
            subject_id
        );
        if (result === 'redirect') return;
        if (result) history.push('/auth/check-email');
    };
    return (
        <FlexDiv justifyCenter alignCenter wrapper column>
            <Block width={570}>
                <Heading1>{t('Sign in')}</Heading1>
                <TextDefault lighter mb={24}>
                    {t('enterWorkEmail')}
                </TextDefault>
                <form
                    id="signin"
                    method="post"
                    action="/auth/email/signin"
                    onSubmit={handleSubmit}
                >
                    <FlexDiv column gap={16}>
                        <TextInput
                            placeholder={t('genericWorkAddress')}
                            stretched
                            aria-describedby="emailHelp"
                            value={email}
                            onChange={handleEmailChange}
                            name="email"
                            onBlur={handleBlur}
                            autoComplete="email"
                            type="email"
                            label={t('workEmailLabel')}
                            overwriteGlobalStyles
                            error={loginError && loginError.length > 0}
                            helperText={
                                loginError && loginError.length > 0
                                    ? loginError
                                    : undefined
                            }
                        />
                        {
                            <ThemedButton
                                mode="primary"
                                size="fullWidth"
                                type="submit"
                                text={t('Continue')}
                            />
                        }
                    </FlexDiv>
                </form>
                <TextTiny lighter mt={16}>
                    {t(`signInAgreement`, { account_name })}{' '}
                    <a
                        href={termsUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <u>{t('Terms of service')}</u>
                    </a>
                </TextTiny>
            </Block>
        </FlexDiv>
    );
}

export default observer(SignIn);
