import Assessment from './assessment';
import React, { useEffect } from 'react';
import { useStores } from '../../hooks/use-stores';
import OneMomentSpinner from '../../components/OneMomentSpinner';
import Training from './training';
import { observer } from 'mobx-react';
function PackageLoader({ parentMatch, companyId, uniqueId, accessKey, auId }) {
    const { cmi5Store } = useStores();
    useEffect(() => {
        if (companyId && uniqueId)
            cmi5Store.loadPackage(companyId, uniqueId, accessKey, auId);
    }, [uniqueId]);

    const { scormPackage, loadingPackage } = cmi5Store;

    if (loadingPackage === true || loadingPackage == null) {
        return <OneMomentSpinner />;
    }
    if (!scormPackage) {
        return <div>Package not found</div>;
    }

    return (
        <>
            {' '}
            {scormPackage.type === 'assessment' && (
                <Assessment
                    availableLanguages={
                        scormPackage.availableLanguages //Available languages should be defined in the Cmi5Package DB row
                    }
                    {...{
                        parentMatch,
                        companyId,
                        uniqueId,
                        assessmentId: uniqueId,
                        accessKey,
                        auId,
                    }}
                />
            )}{' '}
            {scormPackage.type === 'training' && (
                <>
                    <Training
                        availableLanguages={
                            scormPackage.availableLanguages //Available languages should be defined in the Cmi5Package DB row
                        }
                        {...{
                            uniqueId,
                        }}
                    />
                </>
            )}
        </>
    );
}

export default observer(PackageLoader);
