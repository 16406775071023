import BaseService from './BaseService';

class InfoSec extends BaseService {
    current(variant) {
        return this.get(`/${variant}`);
    }

    join(email, variant, emailOption) {
        return this.post('/auth/infosec?variant=' + variant, {
            email,
            emailOption,
        });
    }
}

export default InfoSec;
