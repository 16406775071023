import { reaction, makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import { extractErrorMessage } from '../utils/helpers';
import { addBeforeUnloadReactionToAssessmentStore } from './mixins/beforeUnload';
import { generateLoadEntity } from '../utils/mobx';
import { flatten } from 'lodash';

class AssessmentsStore {
    loadingData = false;
    loadingAssessmentResults = false;
    _questionAction = false;
    loadingAssessmentId = null;
    startingAssessmentId = null;
    error = null;
    assessments = [];
    results = {};
    assessmentAnswers = {};
    assessment = null;
    // subjects = [];
    statusUpdate = 0;
    reviewingQuestions = [];

    constructor(authStore, companyStore, mainStore, commonStore) {
        makeAutoObservable(this);
        this.authStore = authStore;
        this.companyStore = companyStore;
        this.mainStore = mainStore;
        this.commonStore = commonStore;

        reaction(
            () => this.companyStore.currentCompany,
            (company) => {
                if (company.isAssessmentEnabled) this.loadAssessments();
            }
        );

        addBeforeUnloadReactionToAssessmentStore(this);
    }

    logout = () => {
        this.assessments.replace([]);
        this.loadingData = false;
    };

    setError = (error) => {
        this.error = error;
        this.commonStore.error(error);
    };

    async loadAssessment(id, shareKey, background) {
        if (!background) {
            if (this.loadingAssessmentId === id) return;
            this.loadingAssessmentId = id;
        }
        this.setError(null);
        const currentUser = this.authStore.currentUser;
        try {
            let data = await services.Assessments.myAssessment(id, {
                l: this.mainStore.language,
                shareKey,
            });
            runInAction(() => {
                if (!(data.started || data.status.started)) {
                    try {
                        //clear block stored values
                        data.questions.forEach(({ questionId }) => {
                            delete localStorage[
                                `question-actions-${
                                    currentUser.id || currentUser.uid
                                }-${questionId}-${id}`
                            ];
                            delete localStorage[
                                `block-values-${
                                    currentUser.id || currentUser.uid
                                }-${questionId}-${id}`
                            ];
                            delete localStorage[
                                `block-num-${
                                    currentUser.id || currentUser.uid
                                }-${questionId}-${id}`
                            ];
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }
                console.log('setting assessment');
                this.assessment = data;
            });

            this.mainStore.setLayoutTitle(data.name);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingAssessmentId = false;
        }
    }

    async loadAssessments() {
        if (!this.companyStore.currentCompany) return null;
        if (this.loadingData) {
            return this.loadingData;
        }
        this.setError(null);

        this.loadingData = (async () => {
            try {
                let data = await services.Assessments.myAssessments(
                    this.contextParams
                );
                this.assessments.replace(data);
                return this.assessments;
            } catch (e) {
                this.setError(
                    (e.response && e.response.data && e.response.data.error) ||
                        e.message
                );
            } finally {
                this.loadingData = null;
            }
        })();
        return this.loadingData;
    }

    async startAssessment(id, targetObj) {
        if (this.startingAssessmentId === id) return;
        this.startingAssessmentId = id;

        this.setError(null);
        try {
            let data = await services.Assessments.startAssessment(id);
            if (data) Object.assign(targetObj || this.assessment, data);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.startingAssessmentId = null;
        }
    }

    async completeQuestion(assessmentId, id, questions, assessment) {
        if (this._questionAction) return;
        this._questionAction = true;

        this.setError(null);
        try {
            let data = await services.Assessments.questionActions(
                assessmentId,
                id,
                questions
            );
            if (data) {
                Object.assign(this.assessment, data);
                if (assessment) {
                    Object.assign(assessment, data);
                }
            }
            return true;
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this._questionAction = false;
        }
    }

    async questionAction(assessmentId, id, action, value) {
        if (this._questionAction) return;
        this._questionAction = true;

        this.setError(null);
        try {
            let data = await services.Assessments.questionAction(
                assessmentId,
                id,
                action,
                value
            );
            if (data) Object.assign(this.assessment, data);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this._questionAction = false;
        }
    }

    loadResults = generateLoadEntity(
        'results',
        this,
        'loadingAssessmentResults',
        async (companyId, assessmentId) => {
            return services.Assessments.results(assessmentId);
        },
        'results'
    );

    async loadSharedResults(sharedKey, variant) {
        if (this.loadingAssessmentResults || !sharedKey) return;
        this.loadingAssessmentResults = true;
        this.results = {};
        try {
            await this.loadAssessment(variant, sharedKey, true);
            const data = await services.Assessments.sharedResults(sharedKey);
            runInAction(() => {
                this.results = data;
            });
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAssessmentResults = false;
        }
    }

    async loadAnswers(companyId, assessmentId) {
        this.setError(null);
        try {
            this.assessmentAnswers = await services.Assessments.answers(
                assessmentId
            );
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingData = null;
        }
    }

    get contextParams() {
        let params = {};
        if (this.companyStore.currentCompany)
            params.o = this.companyStore.currentCompany.company_id;

        if (this.mainStore.language) params.l = this.mainStore.language;

        return params;
    }

    get isStarted() {
        return (
            this.assessment &&
            (this.assessment.started || this.assessment.status.started)
        );
    }

    get done() {
        return (
            this.assessment &&
            this.assessment.status &&
            (this.assessment.status.questionsDone.length /
                this.assessment.definition.questions.length) *
                100
        );
    }

    get isFinished() {
        return (
            this.assessment &&
            this.assessment.status &&
            (this.assessment.status.finished ||
                this.assessment.status.questionsDone.length ===
                    this.assessment.definition.questions.length)
        );
    }

    get isExpired() {
        return (
            this.assessment &&
            this.assessment.status &&
            this.assessment.status.expired &&
            !this.assessment.status.started
        );
    }

    get isTimedOut() {
        return (
            this.assessment &&
            this.assessment.status &&
            this.assessment.status.timeLeft <= 0
        );
    }

    setTimedOut() {
        this.assessment.status.timeLeft = 0;
    }

    shareUrl(variant) {
        if (!variant)
            variant =
                this.authStore.currentUser &&
                this.authStore.currentUser.variant;
        if (!variant) return null;
        return `${window.location.origin}/assessment-results/${variant}/${this.assessment.shareKey}`;
    }

    get resultsCount() {
        const results = this.assessment.results || this.results;
        return (
            results &&
            flatten(Object.values(results).map((r) => r.questions)).length
        );
    }
}

export default AssessmentsStore;
