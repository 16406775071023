import React from 'react';
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';
import Socket from './Socket';
import Theme from './Theme';

export default () => {
    const match = useRouteMatch();
    return (
        <Switch>
            <Route
                path={`${match.url}`}
                exact
                render={() => (
                    <>
                        <Link to={`${match.url}/socket`}>Socket</Link>
                        <br />

                        <Link to={`${match.url}/theme`}>Theme</Link>

                        <br />
                    </>
                )}
            />

            <Route path={`${match.url}/socket`} exact component={Socket} />
            <Route path={`${match.url}/theme`} exact component={Theme} />
        </Switch>
    );
};
