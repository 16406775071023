import React, {
    Fragment,
    useCallback,
    useState,
    useEffect,
    useMemo,
} from 'react';
import { Switch, Route, Redirect, Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import SubjectView from './SubjectView';
import ThemedFooterButton from '../../components/themed/ThemedFooterButton';

import { querystring } from '../../utils/helpers';
import QuizIntro from '../../components/assessment/intro';
import Review from '../../components/assessment/AssessmentFinalReview/Review';
import ShortSubjectsList from '../../components/training/ShortSubjectsList';
import classes from 'classnames';
import { useStores } from '../../hooks/use-stores';
import AssessmentsView from '../Assessments/AssessmentsView';
import AssessmentsAnswerView from '../Assessments/AssessmentsAnswerView';
import TrainingCompleted from '../Trainings/TrainingCompleted';
import {
    FlexDiv,
    Heading4,
    TextDefault,
    TextLabel,
} from '@awarego/awarego-components';
import ViewHeader from '../../components/ViewHeader';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import LanguageSelector from './language-selector';

export default observer(
    ({
        parentUrl,
        training,
        subjectId,
        trainingStore,
        store,
        videoTokenStore,
        onClose,
        isDemo,
        isScorm,
        availableLanguages,
        selectedLanguage,
        onSelectLanguage,
    }) => {
        const { t } = useTranslation();
        let history = useHistory();

        const [isFinished, setIsFinished] = useState(false);
        const [showMobileMenu, setShowMobileMenu] = useState(false);

        const {
            authStore,
            assessmentStore,
            companyStore,
            questionStore,
            brandingStore,
        } = useStores();

        const { brandingLoaded, account_name, logo, trainingSkipAutoFinish } =
            brandingStore;

        const subject = trainingStore.training.subjects.find(
            (x) => x.id === subjectId
        );
        const isStarted =
            training.assessment && training.assessment.status.started;

        const hasFinishedAssessment =
            training.assessment && training.assessment.status.finished; //training.assessment && training.assessment.status.questionsDone.length === training.assessment.definition.questions.length;

        const showLessonMenu = () => {
            setShowMobileMenu(true);
        };
        const hideLessonMenu = () => {
            setShowMobileMenu(false);
        };

        const onNextLesson = async () => {
            await trainingStore.reloadTraining();
        };
        const onQuizStart = async () => {
            await assessmentStore.startAssessment(
                training.assessment_id,
                training.assessment
            );
        };

        const handleClosePage = useCallback(() => {
            if (onClose) {
                return onClose();
            } else {
                history.push(parentUrl);
            }
        }, [history, onClose, parentUrl]);

        const onFinish = async () => {
            await trainingStore.reloadTraining();
            let lastNextEnabled = trainingStore.training.nextSubjectDate
                ? true
                : trainingStore.certificateQualified;

            console.log('onFinish: lastNextEnabled', lastNextEnabled);
            if (lastNextEnabled) setIsFinished(true);
        };

        const {
            certificateQualified,
            assessmentAvailable,
            availableUnfinishedSubjects,
            subjects,
        } = trainingStore;

        useEffect(() => {
            if (isScorm && certificateQualified) {
                if (!trainingSkipAutoFinish) setIsFinished(true);
            }
        }, [isScorm, certificateQualified]);

        const { currentUser } = authStore;

        let lastNextEnabled = training.nextSubjectDate
            ? true
            : certificateQualified;

        let score =
            training.subjectsSent > 0
                ? (100 * (training.viewed || 0)) / training.subjectsSent
                : 0;

        const trainingName = useMemo(() => {
            if (!training) return;
            if (isDemo) return 'Cybersecurity Awareness Training';
            return training.name;
        }, [training, isDemo]);

        const videosWatchedText = useMemo(() => {
            return (
                <Fragment>
                    {trainingStore.viewedVideos || training.viewed}/
                    {training.subjectsSent} {t('videos watched')}
                </Fragment>
            );
        }, [
            t,
            training.subjectsSent,
            training.viewed,
            trainingStore.viewedVideos,
        ]);

        return (
            <div
                className={classes('wizard', {
                    scorm: subject && subject.type === 2,
                })}
            >
                <ViewHeader
                    onClose={handleClosePage}
                    isDemo={isDemo}
                    brandingLoaded={brandingLoaded}
                    logo={logo}
                    account_name={account_name}
                    done={score}
                    mobileMainHeading={<Heading4>{trainingName}</Heading4>}
                    mobileSubheading={
                        <TextLabel bold lighter>
                            {videosWatchedText}
                        </TextLabel>
                    }
                    mobileRightCorner={
                        <FlexDiv marginLeftAuto>
                            <FlexDiv $noWrap alignCenter gap={16}>
                                {isScorm && availableLanguages ? (
                                    <LanguageSelector
                                        selectedLanguage={selectedLanguage}
                                        handleSelectLanguage={onSelectLanguage}
                                        availableLanguages={availableLanguages}
                                    />
                                ) : null}
                                <FlexDiv>
                                    {!showMobileMenu ? (
                                        <MenuIcon onClick={showLessonMenu} />
                                    ) : (
                                        <MenuOpenIcon
                                            onClick={hideLessonMenu}
                                        />
                                    )}
                                </FlexDiv>
                            </FlexDiv>
                        </FlexDiv>
                    }
                    desktopMainHeading={trainingName}
                    desktopRightCorner={
                        <FlexDiv $noWrap alignCenter gap={16}>
                            {isScorm && availableLanguages ? (
                                <LanguageSelector
                                    selectedLanguage={selectedLanguage}
                                    handleSelectLanguage={onSelectLanguage}
                                    availableLanguages={availableLanguages}
                                />
                            ) : null}
                            <TextDefault bold lighter noWrap>
                                {videosWatchedText}
                            </TextDefault>
                        </FlexDiv>
                    }
                />
                <Switch>
                    <Route
                        path={`${parentUrl}/subjects/quiz/answers`}
                        exact
                        render={() => {
                            if (
                                training.assessment &&
                                !training.assessment.status.started
                            )
                                return (
                                    <Redirect
                                        to={`${parentUrl}/subjects/quiz`}
                                    />
                                );

                            let questionIndex = querystring('index');
                            return (
                                <AssessmentsAnswerView
                                    companyId={
                                        companyStore.currentCompany.company_id
                                    }
                                    assessmentStore={assessmentStore}
                                    questionStore={questionStore}
                                    currentUser={currentUser}
                                    assessmentId={training.assessment_id}
                                    trainingId={training.id}
                                    parentUrl={parentUrl}
                                    index={questionIndex}
                                />
                            );
                        }}
                    />
                    <Route
                        path={`${parentUrl}/subjects/quiz/run`}
                        exact
                        render={() => {
                            if (
                                training.assessment &&
                                !training.assessment.status.started
                            )
                                return (
                                    <Redirect
                                        to={`${parentUrl}/subjects/quiz`}
                                    />
                                );

                            return (
                                <AssessmentsView
                                    companyId={
                                        companyStore.currentCompany.company_id
                                    }
                                    currentUser={currentUser}
                                    assessmentStore={assessmentStore}
                                    questionStore={questionStore}
                                    assessmentId={training.assessment_id}
                                    trainingId={training.id}
                                    parentUrl={parentUrl}
                                />
                            );
                        }}
                    />
                    <Route
                        path={`${parentUrl}/subjects/:id`}
                        render={(props) => {
                            const isQuiz = props.match.params.id === 'quiz';
                            const withQuiz = !!training.assessment_id;
                            const index = _.findIndex(
                                availableUnfinishedSubjects,
                                (x) => x.id === Number(props.match.params.id)
                            );
                            const isCompleted = index < 0;
                            const isFirst = index === 0;
                            const isLast = training.assessment_id
                                ? isQuiz
                                : index ===
                                  availableUnfinishedSubjects.length - 1;

                            return (
                                <Fragment>
                                    {isFinished ? (
                                        <div className="wizard-body">
                                            <TrainingCompleted
                                                training={training}
                                                isDemo={isDemo}
                                                store={store}
                                                isScorm={isScorm}
                                            />
                                        </div>
                                    ) : (
                                        <div className="wizard-body">
                                            <div
                                                className={classes('sidebar ', {
                                                    open: showMobileMenu,
                                                })}
                                                onClick={() => hideLessonMenu()}
                                            >
                                                <ShortSubjectsList
                                                    certificateQualified={
                                                        certificateQualified
                                                    }
                                                    assessment_id={
                                                        training.assessment_id
                                                    }
                                                    assessmentAvailable={
                                                        assessmentAvailable
                                                    }
                                                    onCertificateRequest={
                                                        onFinish
                                                    }
                                                    subjects={training.subjects}
                                                    parentUrl={parentUrl}
                                                    selectedId={
                                                        props.match.params.id
                                                    }
                                                    isQuiz={isQuiz}
                                                    hasFinishedAssessment={
                                                        hasFinishedAssessment
                                                    }
                                                />
                                                {subject && subject.type !== 2 && (
                                                    <div className="btn-fixed">
                                                        <Button className="btn-link">
                                                            {t(
                                                                'Back to training'
                                                            )}
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className={'wizard-content'}>
                                                {isQuiz ? (
                                                    <Fragment>
                                                        {hasFinishedAssessment ? (
                                                            <Review
                                                                assessmentStore={
                                                                    assessmentStore
                                                                }
                                                                parentUrl={
                                                                    parentUrl
                                                                }
                                                                assessment={
                                                                    training.assessment
                                                                }
                                                                assessmentId={
                                                                    training.assessment_id
                                                                }
                                                                trainingId={
                                                                    training.id
                                                                }
                                                            />
                                                        ) : isStarted ? (
                                                            <Redirect
                                                                to={`${parentUrl}/subjects/quiz/run`}
                                                            />
                                                        ) : (
                                                            <QuizIntro
                                                                onStart={
                                                                    onQuizStart
                                                                }
                                                                assessment={
                                                                    training.assessment
                                                                }
                                                            />
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    <SubjectView
                                                        training={training}
                                                        subject={subject}
                                                        store={store}
                                                        videoTokenStore={
                                                            videoTokenStore
                                                        }
                                                        isDemo={isDemo}
                                                        language={
                                                            store.language
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {((subject && subject.type !== 2) ||
                                        isCompleted) && (
                                        <div className={'wizard-footer'}>
                                            <div className={'actions'}>
                                                {!isFirst && !isCompleted && (
                                                    <Button
                                                        tag={Link}
                                                        className="mobile-arrow-back"
                                                        color={'link'}
                                                        size={'lg'}
                                                        to={`${parentUrl}/subjects/${
                                                            availableUnfinishedSubjects[
                                                                index - 1
                                                            ].id
                                                        }`}
                                                    >
                                                        {t('Previous')}
                                                    </Button>
                                                )}
                                                {!isLast &&
                                                    (withQuiz ? (
                                                        <ThemedFooterButton
                                                            mode="primary"
                                                            continue
                                                            to={`${parentUrl}/subjects/quiz`}
                                                            onClick={
                                                                onNextLesson
                                                            }
                                                            text={t('Next')}
                                                        ></ThemedFooterButton>
                                                    ) : (
                                                        <ThemedFooterButton
                                                            mode="primary"
                                                            continue
                                                            to={`${parentUrl}/subjects/${
                                                                availableUnfinishedSubjects[
                                                                    index + 1
                                                                ].id
                                                            }`}
                                                            onClick={
                                                                onNextLesson
                                                            }
                                                            text={t(
                                                                'Next lesson'
                                                            )}
                                                        ></ThemedFooterButton>
                                                    ))}
                                                {(isLast ||
                                                    hasFinishedAssessment) &&
                                                    !isFinished && (
                                                        <ThemedFooterButton
                                                            mode="primary"
                                                            disabled={
                                                                availableUnfinishedSubjects.length >
                                                                    0 ||
                                                                !lastNextEnabled
                                                            }
                                                            continue
                                                            onClick={onFinish}
                                                            text={t('Finish')}
                                                        ></ThemedFooterButton>
                                                    )}
                                                {isLast && isFinished && (
                                                    <ThemedFooterButton
                                                        mode="primary"
                                                        continue
                                                        onClick={
                                                            handleClosePage
                                                        }
                                                        text={t('Finish')}
                                                    ></ThemedFooterButton>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            );
                        }}
                    />

                    {assessmentAvailable &&
                    availableUnfinishedSubjects.length === 0 ? (
                        <Route
                            render={() => {
                                return (
                                    <Redirect
                                        to={`${parentUrl}/subjects/quiz`}
                                    />
                                );
                            }}
                        />
                    ) : (
                        <Route
                            render={() => {
                                return (
                                    training.subjects.length > 0 && (
                                        <Redirect
                                            to={`${parentUrl}/subjects/${
                                                availableUnfinishedSubjects.length >
                                                0
                                                    ? availableUnfinishedSubjects[0]
                                                          .id
                                                    : subjects[0].id
                                            }`}
                                        />
                                    )
                                );
                            }}
                        />
                    )}
                </Switch>
            </div>
        );
    }
);
