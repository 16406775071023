import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ThemedFooterButton from '../../themed/ThemedFooterButton';
import { Box, CircularProgress } from '@material-ui/core';

function AssessmentsViewFooter({
    isExpired,
    isStarted,
    isTimedOut,
    onStart,
    isFinished,
    isDemo,
    restartDemo,
    isNextAvailable,
    onNext,
    isLastStep,
    handleClosePage,
    skipStart,
    actionInProgress,
}) {
    const { t } = useTranslation();

    const footerButton = useMemo(() => {
        if (!isStarted) {
            if (isTimedOut) return '';
            if (skipStart) return '';
            return (
                <ThemedFooterButton
                    mode="primary"
                    continue
                    className="mobileOnly"
                    onClick={onStart}
                    text={t('Start now')}
                ></ThemedFooterButton>
            );
        } else if (!isFinished) {
            if (isDemo && isTimedOut)
                return (
                    <ThemedFooterButton
                        mode="primary"
                        continue
                        onClick={restartDemo}
                        text={t('Start again')}
                    ></ThemedFooterButton>
                );
            return (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    style={{ gap: 8, marginLeft: 'auto' }}
                >
                    {actionInProgress ? (
                        <CircularProgress
                            size={20}
                            style={{
                                margin: '8px',
                            }}
                        />
                    ) : null}
                    <ThemedFooterButton
                        mode="primary"
                        continue
                        disabled={
                            isTimedOut || !isNextAvailable || actionInProgress
                        }
                        onClick={onNext}
                        text={t(isLastStep() ? 'Finish' : 'Continue')}
                    ></ThemedFooterButton>
                </Box>
            );
        }
        return (
            <ThemedFooterButton
                mode="primary"
                continue
                onClick={handleClosePage}
                text={isDemo ? t('Finish Demo') : t('Finish')}
            ></ThemedFooterButton>
        );
    }, [
        handleClosePage,
        isDemo,
        isFinished,
        isLastStep,
        isNextAvailable,
        isStarted,
        isTimedOut,
        onNext,
        onStart,
        restartDemo,
        t,
    ]);

    if (isExpired) return;
    return (
        <div className={'wizard-footer'}>
            <div className={'actions'}>{footerButton}</div>
        </div>
    );
}

export default observer(AssessmentsViewFooter);
