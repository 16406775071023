import React from 'react';
import {
    FlexDiv,
    Heading3,
    Image,
    TextDefault,
    ThemedButton,
    Heading2,
    TextLead,
} from '@awarego/awarego-components';
import { resourcePath } from '../../utils/helpers';
import CopyLink from './copy-link';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const FlexDivWithBgImage = styled(FlexDiv)`
    background-image: url(${(props) => props.backgroundUrl});
    background-repeat: no-repeat;
    background-position: right top;
    background-size: auto 264px;
    @media (max-width: 992px) {
        background-size: auto 88px;
    }
`;

function AdminFinishStep({ copyLink, isAssessment }) {
    const { demoStore } = useStores();

    const { assessment, loadingAssessmentId } = demoStore;

    return (
        <div className="layout_wrapper">
            <FlexDivWithBgImage
                column
                backgroundUrl={resourcePath(
                    `/static/img/${
                        isAssessment ? 'blue' : 'green'
                    }-bubbles-bg.svg`
                )}
                gap={4}
                alignCenter
                height={264}
                justifyCenter
                pb={32}
                backgroundColor={isAssessment ? '#EBEDFC' : '#E9F6F1'}
            >
                <FlexDiv
                    column
                    gap={4}
                    alignCenter
                    height={260}
                    justifyCenter
                    pb={32}
                >
                    <Heading2 extraBold>Demo completed!</Heading2>
                    <TextLead>Here are the next steps you can take</TextLead>
                </FlexDiv>
            </FlexDivWithBgImage>
            <FlexDiv
                flexGrow={8}
                backgroundColor={'white'}
                px={16}
                pb={64}
                gap={32}
                alignStart
                justifyCenter
                rowGap={96}
            >
                {!loadingAssessmentId &&
                    assessment &&
                    assessment.showLearnMore && (
                        <FlexDiv
                            backgroundColor="white"
                            borderRadius={16}
                            column
                            padding={32}
                            mt={-64}
                            dropShadowBelow
                            alignCenter
                        >
                            <Image
                                src={resourcePath(
                                    `/static/img/${
                                        isAssessment ? 'assessment' : 'training'
                                    }_report_preview.png`
                                )}
                                height={176}
                                width={224}
                            />
                            <Heading3 mt={32} mb={4}>
                                Learn how to measure{' '}
                                {isAssessment ? 'assessment' : 'training'}{' '}
                                success
                            </Heading3>
                            <TextDefault lighter mb={24}>
                                Discover the actionable insights you'll get from
                                the reports
                            </TextDefault>
                            <ThemedButton
                                mode="primary"
                                as="a"
                                target="_blank"
                                rel="noreferrer"
                                height={48}
                                center
                                fullWidth
                                href={`https://help.awarego.com/${
                                    isAssessment
                                        ? 'evaluate-the-success-of-your-assessment'
                                        : 'training-programs'
                                }`}
                                text="Learn more"
                            />
                        </FlexDiv>
                    )}
                <FlexDiv
                    backgroundColor="white"
                    borderRadius={16}
                    column
                    padding={32}
                    mt={-64}
                    alignCenter
                    dropShadowBelow
                >
                    <Image
                        src={resourcePath(
                            `/static/img/${
                                isAssessment ? 'blue' : 'green'
                            }_feedback_thumbs.svg`
                        )}
                        height={176}
                    />
                    <Heading3 mb={4} mt={32}>
                        Share this demo to get feedback
                    </Heading3>
                    <TextDefault lighter mb={32}>
                        Get early feedback from your team members
                    </TextDefault>
                    <CopyLink link={copyLink} />
                </FlexDiv>
            </FlexDiv>
        </div>
    );
}

export default observer(AdminFinishStep);
