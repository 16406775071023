import BaseService from './BaseService';

class Auth extends BaseService {
    current() {
        return this.get('/auth/user');
    }

    signin(email, company_id, campaign_id, subject_id, redirect) {
        return this.post('/auth/email/signin', {
            email,
            company_id,
            campaign_id,
            subject_id,
            redirect,
        });
    }

    emailsignin(token) {
        return this.post(`/auth/email/signin/${token}`);
    }

    directSignin(token) {
        return this.post(`/auth/direct/signin/${token}`);
    }

    checkEmail(email) {
        return this.post('/auth/checkEmail', { email });
    }
}

export default Auth;
