import { reaction } from 'mobx';

function alertUser(event) {
    event.preventDefault();
    event.returnValue = '';
}

export function addBeforeUnloadReactionToAssessmentStore(store) {
    reaction(
        () => ({
            isStarted: store.isStarted,
            isCompleted: store.isCompleted,
            isFinished: store.isFinished,
        }),
        ({ isStarted, isFinished, isCompleted }) => {
            if (isStarted && !isFinished && !isCompleted) {
                if (!store.beforeUnloadAttached) {
                    window.addEventListener('beforeunload', alertUser);
                    store.beforeUnloadAttached = true;
                }
            } else {
                if (store.beforeUnloadAttached) {
                    window.removeEventListener('beforeunload', alertUser);
                    store.beforeUnloadAttached = false;
                }
            }
        }
    );
}
