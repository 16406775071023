import React from 'react';
import AssessmentsView from '../Assessments/AssessmentsView';
import { useRouteMatch } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';
import { useHistory } from 'react-router';
import { joinPath } from '../../utils/helpers';

function DemoAssessment({ parentMatch, companyId, uniqueId, isAdmin }) {
    const match = useRouteMatch();
    const history = useHistory();
    const { demoStore } = useStores();
    const { demoUser } = demoStore;

    const handleOnFinish = () => {
        isAdmin
            ? history.push(joinPath(match.url, 'finish'))
            : history.push(joinPath(match.url, 'give-feedback'));
    };

    const restartDemo = () => {
        demoStore.restart();
    };

    return (
        <AssessmentsView
            match={match}
            parentUrl={parentMatch && parentMatch.url}
            companyId={companyId}
            currentUser={demoUser}
            assessmentId={uniqueId}
            assessmentStore={demoStore}
            questionStore={demoStore}
            language={'en'}
            redirectOnError={false}
            isDemo
            restartDemo={restartDemo}
            onClose={handleOnFinish}
        />
    );
}

export default DemoAssessment;
