import React from 'react';
import {
    SpaceBetweenDiv,
    TextWithTooltipIcon,
} from '@awarego/awarego-components';
import Form from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import * as validatorjs from 'validatorjs';

const plugins = { dvr: dvr(validatorjs) };

const fields = {
    email: {
        name: 'email',
        label: (
            <SpaceBetweenDiv>
                Your email
                <TextWithTooltipIcon tooltipText="No data will be stored. We only need your email to send your feedback to the admin of this demo." />
            </SpaceBetweenDiv>
        ),
        rules: 'required|email|string',
        placeholder: 'Your email',
        type: 'email',
    },
    name: {
        name: 'name',
        label: (
            <SpaceBetweenDiv>
                Your name
                <TextWithTooltipIcon tooltipText="No data will be stored. We only need your name to send your feedback to the admin of this demo." />
            </SpaceBetweenDiv>
        ),
        rules: 'required|string',
        placeholder: 'Enter your name',
    },
};
class UserDemoFormDefinition extends Form {
    constructor(initials, hooks) {
        let f = { ...fields };
        if (initials) {
            Object.keys(initials).forEach((k) => {
                if (f[k]) f[k].value = initials[k];
            });
        }
        super({ fields: f }, { plugins, hooks });
    }
}

export default UserDemoFormDefinition;
