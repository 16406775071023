import React, { useMemo, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Progress } from 'reactstrap';
import { subjectProgress, percentToColor } from '../../utils/helpers';
import {
    palette,
    FlexDiv,
    Heading3,
    SpaceBetweenDiv,
    TextLabel,
    Image,
    TextWithTooltipIcon,
    TextDefault,
    Block,
    VideoThumbnailWrapper,
} from '@awarego/awarego-components';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import SubjectDurationAndCompletion from './subject-duration';

export default observer(({ match, subject, index, allSubjects, inactive }) => {
    const { t } = useTranslation();

    const heading = useMemo(() => {
        const text = <Heading3>{subject.title}</Heading3>;
        if (subject.isSubstitute)
            return (
                <TextWithTooltipIcon
                    tooltipText={t('Not available in current language')}
                    textComponent={text}
                    icon={
                        <WarningIcon
                            fontSize="small"
                            style={{
                                color: palette.vibrant.orange,
                            }}
                        />
                    }
                />
            );
        return text;
    }, [subject.isSubstitute, subject.title, t]);

    const durationAndCompletion = useMemo(
        () => (
            <SpaceBetweenDiv gap={8} mt={4}>
                <SubjectDurationAndCompletion subject={subject} />
                {subject.type !== 3 && (
                    <Progress
                        className="progress progress-bar-slim flex-fill"
                        color={percentToColor(subjectProgress(subject))}
                        value={subjectProgress(subject)}
                    />
                )}
                <TextLabel extraBold lighter>
                    {Math.round(subjectProgress(subject))}%
                </TextLabel>
            </SpaceBetweenDiv>
        ),
        [subject]
    );

    return (
        <Fragment>
            {/* desktop only */}
            <FlexDiv
                gap={24}
                as={Link}
                to={`${match.url}/subjects/${subject.id}`}
                $alignCenter
                py={16}
                mt={!inactive ? 24 : undefined}
                $backgroundColor="white"
                opacity={inactive ? 0.6 : undefined}
                // don't put bottom border after last subject
                $borderBottom={index !== allSubjects.length - 1}
                $desktopOnly
            >
                {/* thumbnail */}
                <VideoThumbnailWrapper>
                    <Image
                        src={subject.thumb}
                        width={220}
                        maxHeight={200}
                        cover
                        alt="video thumbnail"
                        borderRadius={4}
                    />
                </VideoThumbnailWrapper>

                {/* heading, description, duration, completion */}
                <FlexDiv column gap={8} maxWidth={392} flexGrow={1} desktopOnly>
                    {heading}
                    <TextDefault>{subject.description}</TextDefault>
                    {durationAndCompletion}
                </FlexDiv>
            </FlexDiv>

            {/* mobile only */}
            <Block
                $mobileOnly
                gap={8}
                opacity={inactive ? 0.6 : undefined}
                as={Link}
                to={`${match.url}/subjects/${subject.id}`}
            >
                {heading}

                {/* thumbnail */}
                <VideoThumbnailWrapper fullWidth>
                    <Image
                        src={subject.thumb}
                        fullWidth
                        cover
                        maxHeight={300}
                        alt="video thumbnail"
                        borderRadius={4}
                    />
                </VideoThumbnailWrapper>

                {/* description, duration & completion */}
                <TextDefault>{subject.description}</TextDefault>
                {durationAndCompletion}
            </Block>
        </Fragment>
    );
});
