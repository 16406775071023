import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import services from '../../services';
import { useStores } from '../../hooks/use-stores';
import io from 'socket.io-client';
import { Button } from '@material-ui/core';

export default observer(({ training, subject, language }) => {
    const { authStore, testStore } = useStores();
    const { logLines } = testStore;

    const handleAskEvent = () => {
        services.Events.askTestEvent();
    };

    useEffect(() => {
        //console.log(subject, training)
        testStore.addToLog('Connecting...');
        const socket = io.connect(process.env.REACT_APP_API_URL, {
            query: `token=${authStore.token}`,
            transports: ['websocket'],
        });

        socket.on('unauthorized', (error) => {
            if (
                error.data.type === 'UnauthorizedError' ||
                error.data.code === 'invalid_token'
            ) {
                testStore.addToLog('UnauthorizedError');
            }
        });

        socket.on('connect', function () {
            console.log('connected');
            testStore.addToLog('connected: ');
        });

        socket.on('training_complete', function (data) {
            console.log('training_complete', data);
            testStore.addToLog('training_complete: ', data);
        });

        socket.on('test-event', function (data) {
            console.log('event', data);
            testStore.addToLog('test-event: ', data);
        });

        socket.on('state_saved', function (data) {
            console.log('state_saved', data);
            testStore.addToLog('state_saved: ', data);
        });

        socket.on('disconnect', function () {
            console.log('disconnect');
            testStore.addToLog('disconnect ');
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <div>
            <Button
                variant={'contained'}
                color={'primary'}
                onClick={handleAskEvent}
            >
                Ask for test event
            </Button>
            <pre>{logLines.join('\n')}</pre>
        </div>
    );
});
