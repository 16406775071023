import RestService from './RestService';
import qs from 'querystring';

export default class Subjects extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/subjects`);
        } else super('/subjects');
    }

    myData(params) {
        return this.get(`${this.baseUrl}/my-data?${qs.stringify(params)}`);
    }

    async getTextContent(api_id_content) {
        // isomorphic-fetch runs in browsers and server-side
        const url =
            'https://www.awarego.com/wp-json/wp/v2/pages/' + api_id_content;
        let res = await fetch(url);
        let data = await res.json();
        return data;
    }

    getVideoPlayerUrl(videoId) {
        return this.post(`${this.baseUrl}/videoPlayerUrl`, { videoId });
    }

    getVideoToken(subjectId, lang) {
        return this.post(`${this.baseUrl}/videoToken`, {
            videoId: subjectId,
            lang,
        });
    }

    getScormInfo(subjectId, lang) {
        return this.post(`${this.baseUrl}/scormInfo`, {
            subjectId,
            preview: false,
            lang,
        });
    }
}
