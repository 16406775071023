import { makeAutoObservable } from 'mobx';
import services from '../services';
import { generateCreateEntity } from '../utils/mobx';

class InfosecStore {
    constructor(authStore, commonStore) {
        makeAutoObservable(this);
        this.authStore = authStore;
        this.commonStore = commonStore;
    }
    joining = false;
    error = null;
    emailOption = null;

    setError = (error) => {
        this.error = error;
    };

    join = generateCreateEntity(
        'join',
        this,
        'joining',
        async (email, variant, emailOption) => {
            this.authStore.logout();
            this.emailOption = emailOption;
            const token = await services.InfoSec.join(
                email,
                variant,
                emailOption
            );
            this.commonStore.saveAnalyticsEvent('mini-hra', 'join', {
                miniHRAType: variant,
                emailOption,
                page: window.location.pathname,
                eventName: 'userAction',
            });

            this.authStore.setToken(token);
        }
    );
}

export default InfosecStore;
