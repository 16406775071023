import React, { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import ThemedProgress from './themed/ThemedProgress';
import '@awarego/awarego-components/lib/index.css';
import {
    Heading2,
    FlexDiv,
    SpaceBetweenDiv,
    palette,
    StatusBadge,
    Image,
} from '@awarego/awarego-components';
import styled from 'styled-components';

const PageCentralizedHeading2 = styled(Heading2)`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
    align-items: center;
`;

function ViewHeader({
    onClose,
    isDemo,
    isInfosec,
    brandingLoaded,
    logo,
    account_name,
    isFinished,
    isStarted,
    done,
    desktopMainHeading,
    desktopRightCorner,
    mobileMainHeading,
    mobileSubheading,
    mobileRightCorner,
}) {
    const closeButton = useMemo(() => {
        return (
            <Image
                src="/static/img/close.svg"
                alt="Close"
                onClick={onClose}
                link
            />
        );
    }, [onClose]);

    const demoBadge = useMemo(() => {
        if (!isDemo) return;
        return <StatusBadge text="DEMO" color={palette.vibrant.blueberry} />;
    }, [isDemo]);

    return (
        <Fragment>
            <SpaceBetweenDiv
                minHeight={72}
                px={48}
                dropShadowBelow
                positionRelative
                desktopOnly
                maxWidth={1560}
            >
                {(isDemo || isInfosec) && brandingLoaded ? (
                    <>
                        <FlexDiv>
                            <span style={{ marginRight: '10px' }}>
                                {closeButton}
                            </span>
                            <Image
                                maxHeight={40}
                                maxWidth={130}
                                src={logo}
                                alt={account_name}
                                style={{maxHeight: 40}}
                            />
                        </FlexDiv>
                    </>
                ) : (
                    closeButton
                )}
                <PageCentralizedHeading2>
                    {desktopMainHeading}
                    {demoBadge}
                </PageCentralizedHeading2>
                {desktopRightCorner}
            </SpaceBetweenDiv>
            <FlexDiv
                minHeight={72}
                px={24}
                py={16}
                dropShadowBelow
                gap={16}
                fullWidth
                mobileOnly
                justifyCenter={isDemo || isInfosec || undefined}
                $noWrap
                alignCenter
            >
                {!(isDemo || isInfosec) && closeButton}
                <FlexDiv
                    column
                    gap={4}
                    justifyCenter={!isStarted || isFinished || undefined}
                >
                    <FlexDiv alignCenter gap={4}>
                        {mobileMainHeading}
                        {demoBadge}
                    </FlexDiv>
                    {mobileSubheading}
                </FlexDiv>
                {mobileRightCorner}
            </FlexDiv>
            <div className="wizard-header-progress">
                <ThemedProgress narrow value={done} />
            </div>
        </Fragment>
    );
}

export default observer(ViewHeader);
