import React from 'react';
import AssessmentsView from '../Assessments/AssessmentsView';
import { useRouteMatch } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';

function CMI5Assessment({
    parentMatch,
    companyId,
    uniqueId,
    availableLanguages,
}) {
    const match = useRouteMatch();
    const { cmi5Store } = useStores();
    const { cmiUser } = cmi5Store;

    return (
        <AssessmentsView
            match={match}
            parentUrl={parentMatch && parentMatch.url}
            availableLanguages={availableLanguages}
            companyId={companyId}
            currentUser={cmiUser}
            assessmentId={uniqueId}
            assessmentStore={cmi5Store}
            questionStore={cmi5Store}
            language={'en'}
            showCloseButton={false}
            onClose={() => {
                cmi5Store.rxd.ConcedeControl();
            }}
            isScorm={true}
        />
    );
}

export default CMI5Assessment;
