import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/use-stores';
import { useTranslation } from 'react-i18next';
import { VideoPlayer } from '@awarego/awarego-components';
import keyBy from 'lodash/keyBy';
import ThemedSpinner from '../components/themed/ThemedSpinner';

export default observer(
    ({
        subject,
        logEvent,
        onChange,
        enableAssessmentFinish,
        onLastDecision,
        resetDecisions,
        videoTokenStore,
    }) => {
        const { store } = useStores();
        const { t } = useTranslation();

        const { videoPlayerUrl } = store;
        const { tokens, tokenIndex, language } = videoTokenStore;

        const videoTokenRequested = async (predefinedSubjectId) => {
            const subjectId =
                predefinedSubjectId || subject.id || subject.subject_id;
            await videoTokenStore.videoToken(subjectId, language);
        };

        useEffect(() => {
            const loadVideoData = async () => {
                const subjectId = subject.id || subject.subject_id;
                if (!store.videoPlayerUrl)
                    await store.loadVideoPlayerUrl(subjectId);
                const key = videoTokenStore.getTokenKey(subjectId, language);
                if (subject && subjectId && !tokenIndex[key]) {
                    await videoTokenStore.videoToken(subjectId, language);
                }
            };

            loadVideoData();
        }, [subject, tokens, tokenIndex, language]);

        const token =
            tokenIndex[videoTokenStore.getTokenKey(subject.id, language)];

        return store.loadingVideoUrl ? (
            <ThemedSpinner />
        ) : (
            token && (
                <VideoPlayer
                    videoTokenRequested={videoTokenRequested}
                    token={token && token.token}
                    videoPlayerUrl={videoPlayerUrl}
                    t={t}
                    subject={subject}
                    logEvent={logEvent}
                    onChange={onChange}
                    enableAssessmentFinish={enableAssessmentFinish}
                    presetCaptionsLanguage={store.playerPresetCaptionsLanguage}
                    captionsSettings={store.playerCaptionsSettings}
                    onLastDecision={onLastDecision}
                    resetDecisions={() => {
                        resetDecisions();
                        subject.resetDecisions();
                    }}
                    onFinishedWatchingAnswer={() => {
                        subject.hasSeenAnswer();
                    }}
                />
            )
        );
    }
);
