import { makeAutoObservable } from 'mobx';

class TestStore {
    logLines = [];

    constructor() {
        makeAutoObservable(this);
    }

    addToLog(message, data) {
        this.logLines.push(message + (data ? JSON.stringify(data) : ''));
    }
}

export default TestStore;
