import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';
import { useHistory } from 'react-router';
import { joinPath } from '../../utils/helpers';
import TrainingView from '../Trainings/TrainingView';

function DemoTraining({ trainingId, isAdmin }) {
    const match = useRouteMatch();
    const history = useHistory();
    const { demoStore, store } = useStores();

    const handleOnFinish = () => {
        isAdmin
            ? history.push(joinPath(match.url, 'finish'))
            : history.push(joinPath(match.url, 'give-feedback'));
    };

    return (
        <TrainingView
            match={match}
            trainingId={trainingId}
            language={store.language}
            trainingStore={demoStore}
            store={demoStore}
            videoTokenStore={store}
            redirectOnError={false}
            onClose={handleOnFinish}
            isDemo
        />
    );
}

export default DemoTraining;
