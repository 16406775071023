import RestService from './RestService';
import EventsService from './AnalyticsEventService';

export default class AnalyticsService extends RestService {
    constructor() {
        super('/analytics');
    }

    get events() {
        return new EventsService(this);
    }
}
