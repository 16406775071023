import React from 'react';
import AssessmentsView from '../Assessments/AssessmentsView';
import { useRouteMatch } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';
import { joinPath } from '../../utils/helpers';
import { useHistory } from 'react-router';

function InfosecAssessment({ parentMatch, variant = 'infosec' }) {
    const match = useRouteMatch();
    const history = useHistory();
    const {
        assessmentStore,
        authStore,
        commonStore,
        questionStore,
        infosecStore,
    } = useStores();
    const { currentUser } = authStore;
    const handleOnFinish = () => {
        commonStore.saveAnalyticsEvent('mini-hra', 'finished', {
            miniHRAType: variant,
            emailOption:
                (currentUser && currentUser.emailOption) ||
                infosecStore.emailOption,
            page: window.location.pathname,
            eventName: 'userAction',
        });
        history.push(joinPath(parentMatch.url, 'finish'));
    };

    const handleOnQuestionComplete = (questionId) => {
        commonStore.saveAnalyticsEvent('mini-hra', 'question-complete', {
            isLastQuestion: false,
            miniHRAType: variant,
            questionId,
            emailOption:
                (currentUser && currentUser.emailOption) ||
                infosecStore.emailOption,
            page: window.location.pathname,
            eventName: 'userAction',
        });
    };
    const handleOnLastQuestionComplete = (questionId) => {
        commonStore.saveAnalyticsEvent('mini-hra', 'question-complete', {
            isLastQuestion: true,
            miniHRAType: variant,
            questionId,
            emailOption:
                (currentUser && currentUser.emailOption) ||
                infosecStore.emailOption,
            page: window.location.pathname,
            eventName: 'userAction',
        });
    };

    const handleOnShowResults = (questionId) => {
        commonStore.saveAnalyticsEvent('mini-hra', 'show-results', {
            miniHRAType: variant,
            questionId,
            emailOption:
                (currentUser && currentUser.emailOption) ||
                infosecStore.emailOption,
            page: window.location.pathname,
            eventName: 'page-view',
        });
    };

    return (
        <AssessmentsView
            match={match}
            parentUrl={parentMatch && parentMatch.url}
            currentUser={currentUser}
            companyId={variant}
            assessmentId={variant}
            assessmentStore={assessmentStore}
            questionStore={questionStore}
            language={'en'}
            isInfosec={true}
            onClose={handleOnFinish}
            handleOnQuestionComplete={handleOnQuestionComplete}
            handleOnFinish={handleOnLastQuestionComplete}
            onShowResults={handleOnShowResults}
            availableLanguages={['en', 'is']}
        />
    );
}

export default InfosecAssessment;
