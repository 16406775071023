import React from 'react';
import {
    FlexDiv,
    Heading1,
    Heading4,
    TextDefault,
    ThemedButton,
    Image,
} from '@awarego/awarego-components';
import styled from 'styled-components';
import { resourcePath } from '../../utils/helpers';
import CopyLink from './copy-link';
import { useStores } from '../../hooks/use-stores';

const LeftRoundCorner = styled(FlexDiv)`
    border-radius: 64px 0px 0px 0px;
`;

function StartStep({ copyLink, isAssessment }) {
    const { demoStore } = useStores();
    const { reportToEmail, reportToName } = demoStore;

    const startDemo = async () => {
        await demoStore.setDemoUser(reportToEmail, reportToName);
    };
    return (
        <FlexDiv
            layoutWrapper
            backgroundColor={isAssessment ? '#EBEDFC' : '#E9F6F1'}
        >
            {/* header */}
            <FlexDiv alignEnd justifyCenter flexGrow={1}>
                <Image
                    src={resourcePath(
                        `/static/img/${
                            isAssessment ? 'assessment' : 'training'
                        }-demo-preview.png`
                    )}
                    alt={`Preview of ${
                        isAssessment ? 'assessment' : 'training'
                    }`}
                    maxWidth={568}
                />
            </FlexDiv>

            {/* body */}
            <LeftRoundCorner
                justifyCenter
                flexGrow={8}
                backgroundColor={'white'}
                pt={56}
                px={16}
            >
                <FlexDiv column maxWidth={424}>
                    <Heading4 lighter center>
                        Sneak peek into a real{' '}
                        {isAssessment ? 'Assessment' : 'Training'} experience
                    </Heading4>
                    <Heading1 my={8} center>
                        3{' '}
                        {isAssessment
                            ? 'assessment questions'
                            : 'training lessons'}
                        . Selected by our cybersecurity experts.
                    </Heading1>
                    <TextDefault lighter mb={40} center>
                        Run this demo to see how employees will experience{' '}
                        {isAssessment ? 'assessments' : 'training programs'}.
                    </TextDefault>
                    <ThemedButton
                        mode="primary"
                        onClick={startDemo}
                        text="Let's do it"
                    />
                    <Heading4 lighter mt={40} mb={8}>
                        Share this demo to get feedback from your team
                    </Heading4>
                    <CopyLink link={copyLink} />
                </FlexDiv>
            </LeftRoundCorner>
        </FlexDiv>
    );
}

export default StartStep;
