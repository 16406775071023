import { observer } from 'mobx-react-lite';
import React from 'react';
import { TextLead, palette } from '@awarego/awarego-components';
import { useStores } from '../../../hooks/use-stores';
import { useTranslation } from 'react-i18next';

function AdditionalInfo({ text, linkTarget, linkText }) {
    const { t } = useTranslation();
    const { brandingStore } = useStores();

    return (
        <TextLead
            style={{
                padding: '5px 0px 5px 16px',
            }}
            color={palette.graphite.charcoal}
        >
            {text}{' '}
            {linkTarget && linkText && (
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={linkTarget || ''}
                >
                    <TextLead color={brandingStore.theme.primary} bold>
                        {t(linkText)}
                    </TextLead>
                </a>
            )}
        </TextLead>
    );
}

export default observer(AdditionalInfo);
