import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { withRouter } from 'react-router';

const max = 100;

const Progress = styled.div`
    border-radius: 8px;
    display: flex;
    height: 0.5rem;
    overflow: hidden;
    background-color: #f0efef;

    ${(props) =>
        props.narrow &&
        css`
            height: 5px;
            border-radius: 0;
            flex: 1 1 auto !important;
        `}
`;

const ProgressBar = styled.div`
    border-radius: 8px;
    background: none;
    padding: 0;
    background-color: ${(props) => props.theme.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease;
    width: 0%;
`;

/*export default class ThemedProgress extends React.Component {

    percent = ((_.toNumber(this.props.value) / _.toNumber(max)) * 100);

    render() {
        return (
            <Progress>
                <ProgressBar style={{ width: `${this.percent}%`, backgroundColor:  this.props.color}}/>
            </Progress>
        )
    }
}*/

const ThemedProgress = (props) => {
    const value = Math.min(props.value, 100);
    const percent = (_.toNumber(value) / _.toNumber(max)) * 100;
    return (
        <Progress {...props}>
            <ProgressBar
                style={{
                    width: `${percent}%`,
                    backgroundColor: props.color,
                    maxWidth: '1560px',
                }}
            />
        </Progress>
    );
};

export default withRouter(ThemedProgress);
