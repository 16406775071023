import { makeAutoObservable } from 'mobx';
import { USERS_VIEW } from '../utils/constants';

class UsersStore {
    companies = [];
    company = null;
    list = null;
    currentView = USERS_VIEW.LISTS;

    constructor() {
        makeAutoObservable(this);
    }

    setCurrentView(viewId) {
        this.currentView = viewId;
    }

    setCompany = (company) => {
        this.company = company;
    };

    setInitialData = (data) => {
        this.companies = data.companies;
        if (data.error) {
            console.log(
                'Error when getting data: ' + JSON.stringify(data.error)
            );
        }
        // init first company
        if (this.companies && this.companies.length > 0) {
            this.company = this.companies[0]; // FIXME: get from cookie or session
        }
    };

    setCompanyById = (id) => {
        if (id && this.companies && this.companies.length > 0) {
            for (
                let a = 0, lengthCompanies = this.companies.length;
                a < lengthCompanies;
                a++
            ) {
                if (this.companies[a]) {
                    if (this.companies[a].company_id === id) {
                        this.company = this.companies[a];
                        // init current list
                        this.list = null;
                        return;
                    }
                }
            }
        }
    };

    setListById = (id) => {
        if (!id) {
            this.list = null;
        } else {
            if (
                id &&
                this.company &&
                this.company.lists &&
                this.company.lists.length > 0
            ) {
                for (
                    let a = 0, lengthLists = this.company.lists.length;
                    a < lengthLists;
                    a++
                ) {
                    if (this.company.lists[a]) {
                        if (this.company.lists[a].list_id === id) {
                            this.list = this.company.lists[a];
                            return;
                        }
                    }
                }
            }
        }
    };

    addNewList(company_id, list_id, list_name) {
        if (company_id && this.companies && this.companies.length > 0) {
            for (
                let a = 0, lengthCompanies = this.companies.length;
                a < lengthCompanies;
                a++
            ) {
                if (this.companies[a]) {
                    if (
                        this.companies[a].company_id === company_id &&
                        this.companies[a].lists
                    ) {
                        let newList = {};
                        newList.list_id = list_id;
                        newList.list_name = list_name;
                        newList.list_active = 1;
                        newList.list_created = new Date();
                        newList.users = [];
                        this.companies[a].lists.push(newList);
                        // update current company object
                        this.company = this.companies[a];
                        return;
                    }
                }
            }
        }
    }

    updateListName(list_id, list_name) {
        const current_company_id = this.company.company_id;
        if (current_company_id && this.companies && this.companies.length > 0) {
            for (
                let a = 0, lengthCompanies = this.companies.length;
                a < lengthCompanies;
                a++
            ) {
                if (this.companies[a]) {
                    if (
                        this.companies[a].company_id === current_company_id &&
                        this.companies[a].lists
                    ) {
                        for (let c = 0; this.companies[a].lists.length; c++) {
                            if (
                                this.companies[a].lists[c].list_id === list_id
                            ) {
                                this.companies[a].lists[c].list_name =
                                    list_name;
                                // update current company object
                                this.company = this.companies[a];
                                return;
                            }
                        }
                    }
                }
            }
        }
    }

    get allCompanyUsers() {
        let allUsers = [];

        if (
            this.company &&
            this.company.lists &&
            this.company.lists.length > 0
        ) {
            for (let i = 0; i < this.company.lists.length; i++) {
                if (
                    this.company.lists[i] &&
                    this.company.lists[i].users &&
                    this.company.lists[i].users.length > 0
                ) {
                    for (
                        let a = 0;
                        a < this.company.lists[i].users.length;
                        a++
                    ) {
                        allUsers.push(this.company.lists[i].users[a]);
                    }
                }
            }
        }
        return allUsers;
    }
}

export default UsersStore;
