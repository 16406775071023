import React from 'react';
import { useStores } from '../../hooks/use-stores';
import styled from 'styled-components';

const StyledLogo = styled.img`
    position: absolute;
    top: 24px;
    left: 48px;
    max-height: 40px;
    max-width: 130px;
`;

function Logo() {
    const { brandingStore } = useStores();
    const { brandingLoaded, account_name, logo } = brandingStore;
    return brandingLoaded && <StyledLogo src={logo} alt={account_name} />;
}

export default Logo;
