import RestService from './RestService';
import qs from 'querystring';

export default class Subjects extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/trainings`);
        } else super('/trainings');
    }

    myTrainings(params) {
        return this.get(`${this.baseUrl}/my-trainings?${qs.stringify(params)}`);
    }

    myTraining(id, params) {
        return this.get(
            `${this.baseUrl}/my-training/${id}?${qs.stringify(params)}`
        );
    }

    idByEvent(eventId) {
        return this.get(`${this.baseUrl}/my-trainings/IDbyEvent/${eventId}`);
    }

    downloadCertificate(trainingId, params, options) {
        return this.get(`/download/certificate/${trainingId}`, params, options);
    }
}
