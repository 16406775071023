import React from 'react';
import { FlexDiv, Heading1 } from '@awarego/awarego-components';
import Spinner from './Spinner';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export default observer(() => {
    const { t } = useTranslation();
    return (
        <FlexDiv column alignCenter fullWidth padding={64}>
            <Heading1>{t('One moment')}</Heading1>
            <Spinner />
        </FlexDiv>
    );
});
