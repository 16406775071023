import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Col, Row, Progress } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';
import SubjectsHome from '../Subjects';
import Subjects from '../../components/training/subjects';
import Assessments from '../../components/assessment/assessments';
import {
    Heading3,
    Heading2,
    TextWithTooltipIcon,
    TextDefault,
    Block,
    FlexDiv,
    SpaceBetweenDiv,
} from '@awarego/awarego-components';
import ThemedProgress from '../../components/themed/ThemedProgress';
import { useTranslation } from 'react-i18next';
import { joinPath, percentToColor } from '../../utils/helpers';
import TrainingCompleted from './TrainingCompleted';
import OneMomentSpinner from '../../components/OneMomentSpinner';
import TrainingViewHeader from '../../components/training/training-view-header';
import TimelineText from '../../components/training/timeline-text';

function TrainingView({
    match,
    availableLanguages,
    trainingId,
    language,
    isDemo,
    isScorm,
    trainingStore,
    store,
    videoTokenStore,
    onClose,
}) {
    const {
        subjectsTODO,
        subjectsDone,
        training,
        loadingData,
        loadingTrainingId,
    } = trainingStore;

    const history = useHistory();
    const { t } = useTranslation();

    const subjectHomeMatch = useRouteMatch(`${match.url}/subjects`);
    const subjectMatch = useRouteMatch(`${match.url}/subjects/:subjectId`);
    const subjectId = subjectMatch && Number(subjectMatch.params.subjectId);

    const [selectedLanguage, setSelectedLanguage] = useState(store.language);

    useEffect(() => {
        setSelectedLanguage(store.language);
    }, [store.language]);

    useEffect(() => {
        loadData();
    }, [trainingId, language, loadData, selectedLanguage]);

    const loadData = useCallback(async () => {
        await trainingStore.loadTraining(trainingId);
        if (!trainingStore.training && trainingStore.loadTrainings) {
            let trainings = await trainingStore.loadTrainings();
            if (trainings && subjectId) {
                let existing = trainings.find((x) =>
                    x.subjectsSentIds.includes(subjectId)
                );
                if (existing)
                    return history.push(`${match.url}/subjects/${subjectId}/`);
            }
            if (!isDemo) history.push('/trainings');
        }
    }, [history, isDemo, match.url, subjectId, trainingId, trainingStore]);

    const switchLanguage = async (language_code) => {
        await store.setLanguage(language_code, true);
        setSelectedLanguage(language_code);
    };

    const score = useMemo(() => {
        if (!training) return;
        if (training.subjectsSent > 0)
            return (100 * (training.viewed || 0)) / training.subjectsSent;
        if (training.subjectsSent > 0) return 0;
    }, [training]);

    const sent = useMemo(() => {
        if (!training) return;
        return (100 * (training.subjectsSent || 0)) / training.totalSubjects;
    }, [training]);

    const training_status_label = useMemo(() => {
        if (score >= 100) return t('Completed');
        if (score < 100) return t('In progress');
        if (!score !== 0) return t('Not started');
    }, [score, t]);

    const subjectsContent = useMemo(() => {
        if (!training) return;
        return (
            <Fragment>
                {subjectsTODO.length > 0 ? (
                    <Subjects match={match} subjects={subjectsTODO} />
                ) : (
                    <Fragment>
                        <Block mobileOnly>
                            <TrainingCompleted
                                training={training}
                                isDemo={isDemo}
                                store={store}
                                isScorm={isScorm}
                            />
                        </Block>
                        <FlexDiv desktopOnly>
                            <TrainingCompleted
                                training={training}
                                isDemo={isDemo}
                                store={store}
                                isScorm={isScorm}
                            />
                        </FlexDiv>
                    </Fragment>
                )}
                {training.assessment && (
                    <Assessments
                        assessments={[training.assessment]}
                        match={match}
                    />
                )}
                {subjectsDone.length > 0 && (
                    <Fragment>
                        <Heading2 mt={24}>{t('Completed lessons')}</Heading2>
                        <Subjects
                            subjects={subjectsDone}
                            match={match}
                            inactive
                        />
                    </Fragment>
                )}
            </Fragment>
        );
    }, [isDemo, match, store, subjectsDone, subjectsTODO, t, training]);

    if (isScorm && !subjectHomeMatch)
        return <Redirect to={joinPath(match.url, 'subjects')} />;

    if (loadingData || loadingTrainingId) {
        return <OneMomentSpinner />;
    }

    if (!training) {
        return null;
    }

    return (
        <Fragment>
            <div className="dashboard">
                {subjectHomeMatch ? (
                    <SubjectsHome
                        parentUrl={match.url}
                        training={training}
                        subjectId={Number(subjectId)}
                        trainingStore={trainingStore}
                        store={store}
                        videoTokenStore={videoTokenStore}
                        isDemo={isDemo}
                        onClose={onClose}
                        isScorm={isScorm}
                        selectedLanguage={selectedLanguage}
                        availableLanguages={availableLanguages}
                        onSelectLanguage={(language) => {
                            switchLanguage(language);
                        }}
                    />
                ) : (
                    <div className="container">
                        <Helmet>
                            <title>{t('Training program')}</title>
                        </Helmet>

                        {/* back to trainings & extra mobile header */}
                        <SpaceBetweenDiv alignEnd>
                            <Link to={`/trainings/`}>
                                <div className={'navigation back-button'}>
                                    {t('Back to training')}
                                </div>
                            </Link>
                            <TextWithTooltipIcon
                                mobileOnly
                                textComponent={
                                    <Heading3 mobileOnly>
                                        {t('Training details')}
                                    </Heading3>
                                }
                                tooltipText={t(
                                    'Scores improve once you watch the assigned training videos and successfully complete the training quizzes.'
                                )}
                            />
                        </SpaceBetweenDiv>

                        {/* page content */}
                        <Row>
                            {/* subjects */}
                            <Col md="8">
                                <Block desktopOnly>
                                    <TrainingViewHeader
                                        training={training}
                                        subjectsTODO={subjectsTODO}
                                        trainingId={trainingId}
                                        subjectsDone={subjectsDone}
                                    />
                                    {subjectsContent}
                                </Block>
                                <Block mobileOnly>
                                    <TrainingViewHeader
                                        training={training}
                                        subjectsTODO={subjectsTODO}
                                        trainingId={trainingId}
                                        subjectsDone={subjectsDone}
                                    />
                                </Block>
                                <FlexDiv column mobileOnly>
                                    {subjectsContent}
                                </FlexDiv>
                            </Col>

                            {/* stats */}
                            <Col md="4">
                                <div className=" d-flex flex-column">
                                    <Block gap={16}>
                                        <TextWithTooltipIcon
                                            tooltipText={t(
                                                'Improve your awareness score by making sure you’ve watched all the assigned training videos and successfully completed the training quizzes.'
                                            )}
                                            textComponent={
                                                <Heading2>
                                                    {t('Your Awareness Score')}
                                                </Heading2>
                                            }
                                        />
                                        <Progress
                                            color={percentToColor(score)}
                                            value={score}
                                        />
                                        <TextDefault lighter>
                                            {training.viewed}/
                                            {training.subjectsSent}{' '}
                                            {training_status_label}
                                        </TextDefault>
                                        {/* TODO: fix and use 0/100 Not started  and company average       <p >Company average 80/100</p>    */}
                                    </Block>
                                    <Block gap={16}>
                                        <TextWithTooltipIcon
                                            tooltipText={t(
                                                'Your assigned lessons are delivered and made available over a period of time. The training timeline shows you how much of the scheduled training course has been delivered.'
                                            )}
                                            textComponent={
                                                <Heading2>
                                                    {t('Training timeline')}
                                                </Heading2>
                                            }
                                        />
                                        <ThemedProgress value={sent} />
                                        <TextDefault lighter>
                                            <TimelineText training={training} />
                                        </TextDefault>
                                    </Block>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        </Fragment>
    );
}

export default observer(TrainingView);
