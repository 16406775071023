import React, { useEffect } from 'react';
//import Review from '../../components/assessment/AssessmentFinalReview/Review';
import { useParams, useHistory } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import { observer } from 'mobx-react';
import MetaTags from 'react-meta-tags';
import { useStores } from '../../hooks/use-stores';

function AssessmentResults() {
    const history = useHistory();
    const { variant, shareKey } = useParams();
    const { assessmentStore, authStore, demoStore } = useStores();
    const { error, loadingAssessmentResults, assessment, results } =
        assessmentStore;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (shareKey) {
            const path = authStore.buildGuestHomePath(variant);
            // assessmentStore.loadSharedResults(shareKey, variant);
            demoStore.restart();
            authStore.logout();
            history.push(path);
        }
    }, [shareKey]);

    return (
        <>
            <ErrorMessage error={error} />
            {loadingAssessmentResults ||
            !assessmentStore.assessment ||
            !results ? (
                <> loading</>
            ) : (
                <>
                    <MetaTags>
                        <title>My cyber security score</title>
                        <meta property="og:type" content="article" />
                        <meta
                            property="og:title"
                            content="My cyber security score"
                        />
                        <meta
                            property="og:description"
                            content="See how well you do on our cyber security quiz"
                        />
                        <meta
                            id="og-image"
                            property="og:image"
                            content={
                                window.location.origin +
                                `/scores/Score-` +
                                (assessment.score &&
                                    assessment.score
                                        .toString()
                                        .padStart(2, '0')) +
                                '.png'
                            }
                        />
                    </MetaTags>
                    {/*<Review*/}
                    {/*    parentUrl={parentMatch && parentMatch.url}*/}
                    {/*    assessmentStore={assessmentStore}*/}
                    {/*    assessment={assessment}*/}
                    {/*    assessmentId={variant}*/}
                    {/*    preloadedResults={results}*/}
                    {/*    shareResults={true}*/}
                    {/*    companyId={variant}*/}
                    {/*/>*/}
                </>
            )}
        </>
    );
}

export default observer(AssessmentResults);
