import React, { useEffect, Fragment, useMemo, useCallback } from 'react';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../hooks/use-stores';
import classes from 'classnames';
import ThemedFooterButton from '../../components/themed/ThemedFooterButton';
import ErrorMessage from '../../components/errormessage';
import QuestionAnswer from '../QuestionAnswer';
import pluralize from 'pluralize';
import '@awarego/awarego-components/lib/index.css';
import { calculateColor, getScoreTitle } from '../../utils/helpers';
import {
    FlexDiv,
    TextDefault,
    SemiCircleProgress,
    Heading4,
    TextLabel,
} from '@awarego/awarego-components';
import OneMomentSpinner from '../../components/OneMomentSpinner';
import ViewHeader from '../../components/ViewHeader';

export default observer(
    ({
        companyId,
        parentUrl,
        assessmentId,
        trainingId,
        language,
        index,
        assessmentStore,
        questionStore,
        currentUser,
        isDemo,
    }) => {
        const { store, brandingStore } = useStores();
        const { brandingLoaded, account_name, logo } = brandingStore;
        const {
            loadingData,
            assessment,
            assessmentAnswers,
            loadingAssessmentId,
        } = assessmentStore;
        const history = useHistory();
        const { t } = useTranslation();
        const [reviewingQuestionIndex, setReviewingQuestionIndex] =
            React.useState(0);
        const [questionId, setQuestionId] = React.useState(null);
        const {
            error,
            question,
            loadingData: loadingQuestionData,
        } = questionStore;
        const [blockNum, setBlockNum] = React.useState(0);
        const [visibleBlocks, setVisibleBlocks] = React.useState(null);
        const isInfosec = useRouteMatch('/hra-demo');

        useEffect(() => {
            if (blockNum !== -1 && questionId) {
                window.localStorage.setItem(
                    `block-num-${currentUser.id}-${questionId}-${assessmentId}`,
                    blockNum.toString()
                );
            }
        }, [assessmentId, blockNum, currentUser.id, questionId]);

        useEffect(() => {
            loadData();
        }, [assessmentId, language, loadData]);

        useEffect(() => {
            if (assessmentAnswers[questionId]) {
                //Only show blocks with an answer
                let visibleBlocks =
                    question &&
                    question.ui.blocks.filter((block) => {
                        return !!assessmentAnswers[questionId].blocks[
                            block.actionId
                        ];
                    });
                setVisibleBlocks(visibleBlocks);
            }
        }, [assessmentAnswers, question, questionId]);

        useEffect(() => {
            (async () => {
                if (companyId && questionId) {
                    await questionStore.loadQuestion(
                        companyId,
                        questionId,
                        assessmentId
                    );

                    let _blockNum;
                    _blockNum =
                        parseInt(
                            window.localStorage.getItem(
                                `block-num-${currentUser.id}-${questionId}-${assessmentId}`
                            )
                        ) || 0;
                    setBlockNum(_blockNum);
                }
            })();
        }, [
            questionId,
            companyId,
            questionStore,
            assessmentId,
            currentUser.id,
        ]);

        const loadData = useCallback(async () => {
            await assessmentStore.loadAssessment(assessmentId);
            await assessmentStore.loadAnswers(companyId, assessmentId);
            const assessment = assessmentStore.assessment;

            if (!assessment) {
                return history.push('/assessments');
            }

            const isFinished =
                assessment &&
                (assessment.status.finished ||
                    assessment.status.questionsDone.length ===
                        assessment.definition.questions.length);

            if (!isFinished) {
                return history.push('/assessments');
            }

            //If supplying index to start at a specific question
            const specifiedIndex = parseInt(index, 10) || 0;
            let q = assessment.definition.questions[specifiedIndex];

            setReviewingQuestionIndex(specifiedIndex);
            setQuestionId(q);
        }, [assessmentId, assessmentStore, companyId, history, index]);

        const onMoveLessonBy = async (indexJump, startAtLastBlock) => {
            let nextReviewQuestionIndex = reviewingQuestionIndex + indexJump;
            setQuestionId(
                assessment.definition.questions[nextReviewQuestionIndex]
            );
            if (indexJump < 0 && startAtLastBlock) {
                setBlockNum(
                    Object.keys(
                        assessmentAnswers[
                            assessment.definition.questions[
                                nextReviewQuestionIndex
                            ]
                        ].blocks
                    ).length - 1 || 0
                );
            } else {
                setBlockNum(0);
            }
            setReviewingQuestionIndex(nextReviewQuestionIndex);
        };

        const onNextBlock = async () => {
            let newBlockNum = Math.min(
                blockNum + 1,
                question.ui.blocks.length - 1
            );
            setBlockNum(newBlockNum);
        };
        const onPrevBlock = async () => {
            let newBlockNum = Math.max(blockNum - 1, 0);
            setBlockNum(newBlockNum);
        };

        const onNextLesson = async () => {
            return onMoveLessonBy(1);
        };

        const onPreviousLesson = async (e, startAtLastBlock = false) => {
            return onMoveLessonBy(-1, startAtLastBlock);
        };

        const onNext = async () => {
            if (!question) return;
            if (hasNextBlock()) return onNextBlock();
            return onNextLesson();
        };

        const onPrevious = async () => {
            if (!question) return;
            if (hasPreviousBlock()) return onPrevBlock();
            return onPreviousLesson(null, true);
        };

        const hasNextBlock = function () {
            return (
                visibleBlocks &&
                blockNum < visibleBlocks.length - 1 &&
                visibleBlocks[blockNum]
            );
        };

        const hasPreviousBlock = function () {
            return blockNum !== 0;
        };

        const done = useMemo(() => {
            if (!assessment) return;
            return (
                ((reviewingQuestionIndex + 1) /
                    assessment.definition.questions.length) *
                100
            );
        }, [assessment, reviewingQuestionIndex]);

        const isLast = useMemo(() => {
            if (!assessment) return;
            return (
                reviewingQuestionIndex + 1 ===
                assessment.definition.questions.length
            );
        }, [assessment, reviewingQuestionIndex]);

        const isFirst = reviewingQuestionIndex === 0;

        const closeAssessment = useCallback(() => {
            if (!assessment) return;
            history.push(parentUrl);
        }, [assessment, history, parentUrl]);

        const scorePercentage = assessmentAnswers[questionId]
            ? Math.max(
                  0,
                  Math.round(
                      (assessmentAnswers[questionId].totalScore /
                          assessmentAnswers[questionId].maxScore) *
                          100
                  )
              )
            : 0;

        if (loadingData || loadingAssessmentId || !assessment) {
            return <OneMomentSpinner />;
        }

        if (
            assessment &&
            !assessment.status.started &&
            !assessment.status.finished
        ) {
            if (trainingId)
                return <Redirect to={`${parentUrl}/subjects/quiz`} />;
            else return <Redirect to={`${parentUrl}/${assessmentId}`} />;
        }

        return (
            <div className={classes('wizard')}>
                <ViewHeader
                    onClose={closeAssessment}
                    isDemo={isDemo}
                    isInfosec={isInfosec}
                    brandingLoaded={brandingLoaded}
                    logo={logo}
                    account_name={account_name}
                    done={done}
                    mobileMainHeading={
                        <Heading4>
                            {question ? t('assessment:' + question.name) : ''}
                        </Heading4>
                    }
                    mobileSubheading={
                        <FlexDiv alignEnd gap={4}>
                            <TextLabel bold>
                                {reviewingQuestionIndex + 1}/
                                {assessment.definition.questions.length}{' '}
                                {t(
                                    pluralize(
                                        'question',
                                        assessment.definition.questions.length
                                    )
                                )}
                            </TextLabel>
                        </FlexDiv>
                    }
                    desktopMainHeading={
                        <FlexDiv column alignCenter>
                            <TextDefault lighter>
                                {reviewingQuestionIndex + 1}/
                                {assessment.definition.questions.length}{' '}
                                {t(
                                    pluralize(
                                        'question',
                                        assessment.definition.questions.length
                                    )
                                )}
                            </TextDefault>

                            {question ? t('assessment:' + question.name) : ''}
                        </FlexDiv>
                    }
                    desktopRightCorner={
                        <FlexDiv gap={32} $noWrap alignEnd>
                            {assessmentAnswers[questionId] ? (
                                <Fragment>
                                    <TextDefault>
                                        {t('Your score is')} {''}
                                        <TextDefault
                                            bold
                                            color={calculateColor(
                                                scorePercentage,
                                                brandingStore
                                            )}
                                        >
                                            {t(
                                                getScoreTitle(
                                                    scorePercentage,
                                                    brandingStore
                                                )
                                            )}
                                        </TextDefault>
                                    </TextDefault>
                                    <SemiCircleProgress
                                        percentage={scorePercentage}
                                        width={48}
                                        percentageText
                                        top={10}
                                        halfCircle
                                    />
                                </Fragment>
                            ) : (
                                ''
                            )}
                        </FlexDiv>
                    }
                />
                <div className="wizard-body">
                    <div className={'wizard-content wide'}>
                        <div className="score-line">
                            <div className="score-group">
                                <SemiCircleProgress
                                    percentage={scorePercentage}
                                    width={48}
                                    percentageText
                                    top={10}
                                    halfCircle
                                />
                                <span
                                    className="score-in-words"
                                    style={{
                                        color: calculateColor(
                                            scorePercentage,
                                            brandingStore
                                        ),
                                    }}
                                >
                                    {t(
                                        getScoreTitle(
                                            scorePercentage,
                                            brandingStore
                                        )
                                    )}
                                </span>
                            </div>
                            <a href="#question-answer-anchor">
                                <div className="score-details">
                                    {t('Score details')} ↓
                                </div>
                            </a>
                        </div>
                        <ErrorMessage error={error} />

                        <QuestionAnswer
                            loadingData={loadingQuestionData}
                            question={question}
                            blockNum={blockNum}
                            onPrevBlock={onPrevBlock}
                            onNextBlock={onNextBlock}
                            questionId={questionId}
                            assessmentId={assessmentId}
                            userId={currentUser && currentUser.id}
                            userName={currentUser && currentUser.name}
                            userEmail={currentUser && currentUser.email}
                            language={store.language}
                            questionAnswers={assessmentAnswers[questionId]}
                            visibleBlocks={visibleBlocks}
                        />
                    </div>
                </div>

                <div className={'wizard-footer'}>
                    <div className={'actions answers'}>
                        <ThemedFooterButton
                            mode="secondary"
                            continue
                            style={{ marginLeft: 'auto' }}
                            disabled={
                                loadingQuestionData ||
                                (isFirst && !hasPreviousBlock())
                            }
                            onClick={onPrevious}
                            text={t('Previous')}
                        ></ThemedFooterButton>
                        {isLast && !hasNextBlock() ? (
                            <Link to={parentUrl}>
                                <ThemedFooterButton
                                    mode="primary"
                                    continue
                                    style={{ marginLeft: '20px' }}
                                    text={t('Close')}
                                ></ThemedFooterButton>
                            </Link>
                        ) : (
                            <ThemedFooterButton
                                mode="secondary"
                                continue
                                style={{ marginLeft: '20px' }}
                                disabled={loadingQuestionData}
                                onClick={onNext}
                                text={t('Next')}
                            ></ThemedFooterButton>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);
