import React from 'react';
import 'whatwg-fetch';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react';
import stores from './stores';
import { Router } from 'react-router-dom';
import { querystring as qs } from './utils/helpers';
import querystring from 'querystring';
import includes from 'array-includes';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import { version } from '../package.json';

(async function init() {
    const history = createBrowserHistory();
    if (process.env.REACT_APP_SENTRY_DSN) {
        try {
            await Sentry.close();
        } catch (e) {}
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            release: 'awarego-my@' + version,
            autoSessionTracking: true,
            // beforeSend(event, hint) {
            //     // Check if it is an exception, and if so, show the report dialog
            //     if (event.exception) {
            //         Sentry.showReportDialog({ eventId: event.event_id });
            //     }
            //     return event;
            // },
            integrations: [
                new Integrations.BrowserTracing({
                    // Can also use reactRouterV4Instrumentation
                    routingInstrumentation:
                        Sentry.reactRouterV5Instrumentation(history),
                }),
            ],

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 0.0,
        });
    }
    includes.shim();

    window.__stores = stores;

    function main() {
        ReactDOM.render(
            // <Sentry.ErrorBoundary fallback={"An error has occurred"}>

            <Provider {...stores}>
                <Router history={history}>
                    <App />
                </Router>
            </Provider>,
            //   </Sentry.ErrorBoundary>
            document.getElementById('root')
        );
    }

    const management_url = process.env.REACT_APP_LMS_URL;

    //to window only for debug

    if (window.location.pathname === '/manager') {
        window.location.replace(management_url);
    } else if (window.location.pathname === '/auth/direct/signin') {
        let c = qs('c', window.location.search);
        let o = qs('o', window.location.search);
        let s = qs('s', window.location.search);
        let token = qs('at', window.location.search);
        window.location.replace(
            `${
                window.location.origin
            }/auth/direct/signin/${token}?${querystring.stringify({
                c,
                o,
                s,
            })}`
        );
    } else if (window.location.pathname === '/') {
        let a = qs('a', window.location.search);
        let t = qs('t', window.location.search);
        if (a || t) {
            // TODO: need fix, a is not training id, its event id. need remap
            window.location.replace(
                `${window.location.origin}/training-event/${a || t}?`
            );
        } else {
            main();
        }
    } else {
        main();
    }
})();

//auth/direct/signin?c=167&at=2be79964-cb17-11e8-a4d6-0253380170da&o=2

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
