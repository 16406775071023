import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import '../css/index.scss';
import UserMenu from '../containers/UserMenu';
import { Alert } from 'reactstrap';
import { useStores } from '../hooks/use-stores';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { FlexDiv, TextTiny } from '@awarego/awarego-components';
import styled, { css } from 'styled-components';
import { publicHRASubstrings } from '../utils/constants';

const Logo = styled.div`
    img {
        max-height: 40px;
        max-width: 130px;
    }
    padding: 24px;
    position: relative;
    margin-right: 32px;
    ${(props) =>
        props.noMargin &&
        css`
            margin: 0;
        `}
    cursor: pointer;
`;

function Layout({ children, sendFeedback, handleSignout, switchLanguage }) {
    const { store, brandingStore, authStore } = useStores();

    const { logo, account_name, favicon, brandingLoaded, privacyUrl } =
        brandingStore;
    const { layoutTitle } = store;
    const { isAuthenticated } = authStore;

    const { t } = useTranslation();

    const [notification, setNotification] = useState(false);
    const [notificationType, setNotificationType] = useState('warning');
    const [notificationMessage, setNotificationMessage] =
        useState('All is good');
    const [isOpen, setIsOpen] = useState(false);

    const infosecPages = useRouteMatch('/mini-hra');
    const location = useLocation();

    const miniHRAtype = useMemo(() => {
        const type = publicHRASubstrings.find((x) =>
            location.pathname.startsWith(`/${x}`)
        );
        brandingStore.setDefaultMiniHraType(type);
        return type;
    }, [location.pathname]);

    const miniHRARoot = useMemo(() => {
        return publicHRASubstrings.find((x) => location.pathname === `/${x}`);
    }, [location.pathname]);

    const contentVerticallyCentered = !isAuthenticated;
    const date = new Date();

    const loadResources = () => {
        fetch('https://resources.awarego.com/notification-my.json')
            .then((response) => response.json())
            .then((jsonData) => {
                setNotification(jsonData.status);
                setNotificationType(jsonData.type);
                setNotificationMessage(jsonData.message);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        loadResources();
        setBodyBackground();
    }, []);

    const setBodyBackground = () => {
        const currentURL = window.location.pathname;

        if (currentURL.includes('mini-hra')) {
            document.body.classList.add('infosec');
        }
    };

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const updateFavicon = (logo) => {
        const favicon = document.getElementById('favicon');
        favicon.href = logo;
        return true;
    };

    const logoContainer = (brandingLoaded || infosecPages) && (
        <Link to="/">
            <img src={logo} alt={t('logoAlt', account_name)} />
        </Link>
    );

    return (
        <Fragment>
            {brandingLoaded && updateFavicon(favicon) && (
                <Helmet
                    titleTemplate={'%s | ' + account_name}
                    defaultTitle={`${account_name}`}
                />
            )}
            <div
                className={classnames('layout_wrapper', {
                    ['infosec_bg']: infosecPages,
                })}
            >
                {!isAuthenticated || infosecPages ? (
                    miniHRAtype == null ||
                    (miniHRAtype != null && !miniHRARoot && (
                        <>
                            <Logo>{logoContainer}</Logo>
                        </>
                    ))
                ) : (
                    <Fragment>
                        <div
                            onClick={() => toggleNavbar()}
                            className={
                                isOpen
                                    ? 'overlay-black visible'
                                    : 'overlay-black'
                            }
                        />
                        {isAuthenticated && (
                            <ul
                                id="navToggle"
                                onClick={() => toggleNavbar()}
                                className="burger slide"
                            >
                                <li />
                                <li />
                                <li />
                            </ul>
                        )}
                        <header className={isOpen ? 'slide open' : 'slide'}>
                            <div className="container full">
                                <div className="header">
                                    {brandingLoaded ? (
                                        <Link to="/" className="logo_box">
                                            <img
                                                src={logo}
                                                alt={t('logoAlt', account_name)}
                                            />
                                        </Link>
                                    ) : (
                                        <span className="logo_box"></span>
                                    )}
                                    <div className="header-title">
                                        {layoutTitle}
                                    </div>
                                    <div className="close">
                                        <img
                                            src="/static/img/icon-close.png"
                                            alt={t('Close the menu')}
                                        />
                                    </div>

                                    <UserMenu
                                        sendFeedback={sendFeedback}
                                        handleSignout={handleSignout}
                                        switchLanguage={switchLanguage}
                                    />
                                </div>
                            </div>
                        </header>
                        {notification && (
                            <Alert color={notificationType}>
                                {notificationMessage}
                            </Alert>
                        )}

                        <div className="mobile-header">
                            <div className="logo_mobile">
                                {brandingLoaded && (
                                    <Link to="/" className="logo_box">
                                        <img
                                            src={logo}
                                            alt={t('logoAlt', account_name)}
                                        />
                                    </Link>
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}

                <div
                    className={classnames('container-wrapper', {
                        'd-flex container-wrapper__spacy':
                            contentVerticallyCentered,
                    })}
                >
                    {children}
                </div>

                {!infosecPages && (
                    <footer>
                        <FlexDiv justifyCenter my={24} alignCenter>
                            <TextTiny lighter>
                                <a
                                    href={privacyUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('Privacy Policy')}
                                </a>{' '}
                                &nbsp;&#183; © {date.getFullYear()}{' '}
                                {(brandingLoaded && account_name) || 'AwareGO'}
                            </TextTiny>
                        </FlexDiv>
                    </footer>
                )}
            </div>
        </Fragment>
    );
}

export default observer(Layout);
