import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/use-stores';
import {
    FlexDiv,
    Heading1,
    TextDefault,
    TextLead,
    EmailAnimation,
} from '@awarego/awarego-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

function CheckEmail() {
    const { authStore, brandingStore } = useStores();
    const { email } = authStore;

    const { t } = useTranslation();

    let history = useHistory();

    return (
        <FlexDiv justifyCenter alignCenter wrapper>
            <FlexDiv justifyCenter alignCenter column width={464}>
                <EmailAnimation primaryColor={brandingStore.theme.primary} />
                <Heading1 mb={8} mt={48}>
                    {t('Check your email')}{' '}
                </Heading1>
                <TextLead mb={16} alignCenter>
                    {email && email.length > 0 && (
                        <Fragment>
                            {t('emailSent')} <TextLead bold>{email}</TextLead>.{' '}
                        </Fragment>
                    )}
                    {t('followLinkInEmail')}
                </TextLead>
                <FlexDiv mb={16}>
                    <TextDefault mr={4}>{t('noEmailYet')} </TextDefault>
                    <TextDefault
                        themed
                        bold
                        link
                        onClick={() => history.push('/auth/signin')}
                    >
                        {t('Request a new sign in link')}
                    </TextDefault>
                </FlexDiv>
            </FlexDiv>
        </FlexDiv>
    );
}

export default observer(CheckEmail);
