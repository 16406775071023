import styled, { css } from 'styled-components';
import { lighten, rgba } from 'polished';
import { palette } from '@awarego/awarego-components';

export const ThemedStep = styled.div`
    position: relative;
    white-space: nowrap;
    line-height: 36px;
    padding-left: 30px;
    padding-right: 10px;
    font-size: 12px;
    text-transform: uppercase;
    color: ${lighten(0.6, palette.graphite.heavyMetal)};
    font-weight: 700;
    border-left-color: white;
    border-left-style: solid;
    border-left-width: 4px;
    border-right-color: white;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    letter-spacing: 0.04em;
    padding-right: 20px;
    a {
        width: 100%;
    }
    &:after {
        position: absolute;
        content: '';
        width: 20px;
        height: 36px;
        right: 0px;
        top: 0px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 14px auto;
        transition: ease 0.2s all;
    }

    ${(props) =>
        props.enabled &&
        css`
            opacity: 1;
            cursor: pointer;
            color: ${palette.graphite.heavyMetal};
            &:hover {
                color: ${(props) =>
                    lighten(0.1, props.color || props.theme.primary)};
                border-left-color: ${lighten(0.4, palette.graphite.heavyMetal)};
                border-right-color: ${lighten(
                    0.4,
                    palette.graphite.heavyMetal
                )};
            }
            &:after {
                position: absolute;
                content: '';
                width: 20px;
                height: 36px;
                right: 0px;
                top: 0px;
                background-size: 14px auto;
            }
        `};
    ${(props) =>
        props.active &&
        css`
            border-left-color: ${(props) => props.color || props.theme.primary};
            border-right-color: ${(props) =>
                props.color || props.theme.primary};
            border-left-style: solid
                ${(props) => props.color || props.theme.primary};
            border-left-width: 4px;
            a {
                color: ${(props) => props.color || props.theme.primary};
                font-weight: 800;
            }
            span {
                font-weight: 600;
            }
        `};
    ${(props) =>
        props.viewed &&
        css`
            &:after {
                background-image: url('/static/img/icon-subject-inprogress.svg');
            }
            &.answered {
                &:after {
                    background-image: url('/static/img/icon-subject-completed.svg');
                }
            }
        `};
    ${(props) =>
        props.answered &&
        css`
            &:after {
                background-image: url('/static/img/icon-subject-completed.svg');
            }
        `};
    ${(props) =>
        props.incorrect &&
        css`
            &:after {
                background-image: url('/static/img/icon-subject-incorrect.svg');
            }
        `};
    ${(props) =>
        props.certificate &&
        css`
            &:after {
                background-image: url('/static/img/icon-certificate2.svg');
                background-repeat: no-repeat;
                background-size: 14px auto;
                top: 0;
            }
        `};
    ${(props) =>
        props.assessment &&
        css`
            &:after {
                background-image: url('/static/img/icon-quiz.svg');
                background-repeat: no-repeat;
                background-size: 14px auto;
                top: 0;
            }
        `};
`;

export const ThemedAnswer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${(props) => rgba(props.color || props.theme.primary, 0.1)};
    background: ${(props) => rgba(props.color || props.theme.primary, 0.1)};
    border-radius: 3px;
    transition: 0.2s ease all;
    font-size: 0.9em;
    color: ${(props) => props.color || props.theme.primary};
    font-weight: 700;
    min-height: 64px;
    text-align: center;
    min-width: 100%;
    gap: 8px;
    padding: 8px;
    &:hover {
        background: ${(props) => props.color || props.theme.primary};
        color: white;
        cursor: pointer;
    }
    &:active {
        background: ${(props) => rgba(props.color || props.theme.primary, 0.4)};
        color: white;
        cursor: pointer;
    }

    ${(props) =>
        props.correct &&
        css`
            background: ${palette.evergreen.aspargus};
            color: white;
            pointer-events: none;
        `};

    ${(props) =>
        props.incorrect &&
        css`
            background: ${palette.vibrant.raspberry};
            color: white;
            pointer-events: none;
        `};
`;
