import RestService from './RestService';

/**
 * @typedef {{
 *      context: {application: (string)},
 *      eventName: string,
 *      category: string
 * }} Event
 */

/**
 * @typedef {{
 *    category: string,
 *    action: string,
 *    context: object,
 * }} Entity
 */

const APPLICATION = 'MY';

export default class AnalyticsEventService extends RestService {
    /**
     * @param {RestService} parentService
     */
    constructor(parentService) {
        if (parentService) {
            super(`${parentService.baseUrl}/event`);
        } else {
            throw new Error(
                'EventsService can only be used as a nested service'
            );
        }
    }

    /**
     * @param {Entity} entity
     * @param {object?} options
     * @return {*}
     */
    create(entity, options) {
        return super.create(
            AnalyticsEventService.prepareEntity(entity),
            options
        );
    }

    /**
     * @param {Entity} entity
     * @returns {Event}
     */
    static prepareEntity(entity) {
        return {
            category: entity.category,
            eventName: entity.action,
            context: {
                ...entity.context,
                application: APPLICATION,
            },
        };
    }
}
