import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { formatDate } from '../../utils/helpers';
import { ThemedStep } from '../themed/ThemedComponents';

@withTranslation()
@observer
class ShortSubjectsList extends React.Component {
    render() {
        const selectedId = Number(this.props.selectedId);
        const {
            parentUrl,
            subjects,
            onCertificateRequest,
            certificateQualified,
            assessmentAvailable,
            t,
            assessment_id,
            isQuiz,
            hasFinishedAssessment,
        } = this.props;

        // Display place holder if campaign is still loading (and no error)
        return (
            <div className="block block-steps no-top-padd">
                {subjects.map((subject, a) => {
                    const stepProps = {
                        viewed: subject.status && subject.status.VIDEO_100,
                        answered:
                            subject.status && subject.status.ANSWARE_CORRECT,
                        correctly:
                            subject.status && subject.status.ANSWARE_CORRECT,
                        incorrect:
                            subject.status &&
                            !subject.status.ANSWARE_CORRECT &&
                            subject.status.ANSWARE_INCORRECT,
                        enabled: subject.sent > 0,
                        active: selectedId === subject.id,
                    };
                    return (
                        <ThemedStep {...stepProps} key={a}>
                            {subject.sent > 0 ? (
                                <Link
                                    to={`${parentUrl}/subjects/${subject.id}`}
                                >
                                    {' '}
                                    {subject.title}{' '}
                                </Link>
                            ) : (
                                <span
                                    title={
                                        t('Will be delivered at') +
                                        ` ${formatDate(subject.sendDate)}`
                                    }
                                >
                                    {' '}
                                    {subject.title}{' '}
                                </span>
                            )}
                        </ThemedStep>
                    );
                })}
                {assessment_id ? (
                    <ThemedStep
                        {...{
                            assessment: true,
                            enabled: assessmentAvailable,
                            active: isQuiz,
                            answered: hasFinishedAssessment,
                        }}
                    >
                        {assessmentAvailable ? (
                            <Link to={`${parentUrl}/subjects/quiz`}>
                                {t('Quiz')}
                            </Link>
                        ) : (
                            t('Quiz')
                        )}
                    </ThemedStep>
                ) : null}
                <ThemedStep
                    {...{ certificate: true, enabled: certificateQualified }}
                    onClick={
                        certificateQualified ? onCertificateRequest : undefined
                    }
                >
                    {t('Certificate')}
                </ThemedStep>
            </div>
        );
    }
}

export default withRouter(ShortSubjectsList);
