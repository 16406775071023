import React, { Fragment, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useStores } from '../../hooks/use-stores';
import ErrorMessage from '../../components/errormessage';
import { Block, palette, Heading2 } from '@awarego/awarego-components';
import styled from 'styled-components';

const BlockTabs = styled.div`
    border-bottom: 1px solid ${palette.lightroom.heavyMetalLightHigh};
    margin-left: -32px;
    margin-right: -32px;
    margin-bottom: 16px;
    @media (max-width: 992px) {
        margin-left: -16px;
        margin-right: -16px;
    }

    ul {
        display: flex;
        padding-inline-start: 0px;
        margin-bottom: 0;
        margin-left: 56px;
        @media (max-width: 992px) {
            justify-content: center;
            margin-left: unset;
        }

        li {
            list-style: none;
            font-weight: 800;
            font-size: 20px;
            padding-bottom: 16px;
            margin-right: 40px;
            @media (max-width: 992px) {
                margin-right: 16px;
            }
            border-radius: 2px;
            transition: 0.2s border-bottom ease-out;

            &.active,
            &:hover,
            &:focus {
                border-bottom: 3px solid
                    ${(props) =>
                        // use secondary color if primary is aspargus, as aspargus doesn't look good with the rest of the layout
                        props.theme.primary === palette.evergreen.aspargus
                            ? props.theme.secondary
                            : props.theme.primary};
            }
        }
    }
`;

function HomeViewContainer({ layoutTitle, code, pageTitle, content }) {
    const { t } = useTranslation();
    const { store, companyStore } = useStores();
    const { currentCompany } = companyStore;
    const { error } = store;

    useEffect(() => {
        store.setLayoutTitle(t(layoutTitle));
    }, [store, t, layoutTitle]);

    const currentUserName = store.authStore.currentUser.name;

    const tabs = useMemo(
        () => [
            {
                code: 'trainings',
                title: 'Trainings',
                enabled: currentCompany && currentCompany.isTrainingEnabled,
                active: code === 'trainings',
            },
            {
                code: 'assessments',
                title: 'Assessments',
                enabled: currentCompany && currentCompany.isAssessmentEnabled,
                active: code === 'assessments',
            },
        ],
        [currentCompany, code]
    );

    return (
        <Fragment>
            <Helmet>
                <title>{t(`Your ${pageTitle}`)}</title>
            </Helmet>
            <div className="container max-1280">
                <ErrorMessage error={error} />
                <div className="helmet">
                    <Heading2>
                        {t('Welcome back')}, {currentUserName}
                    </Heading2>
                    <p>{t(`Here are your ${code}`)}.</p>
                </div>
                <Row>
                    <Col md="8" xs="12">
                        <Block>
                            <BlockTabs>
                                <ul>
                                    {tabs.map(
                                        (tab) =>
                                            tab.enabled && (
                                                <Link
                                                    to={`/${tab.code}`}
                                                    key={tab.code}
                                                >
                                                    <li
                                                        className={
                                                            tab.active
                                                                ? 'active'
                                                                : undefined
                                                        }
                                                    >
                                                        {t(tab.title)}
                                                    </li>
                                                </Link>
                                            )
                                    )}
                                </ul>
                            </BlockTabs>
                            {content}
                        </Block>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
}

export default observer(HomeViewContainer);
