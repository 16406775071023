import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { Route, useHistory } from 'react-router';
import Assessment from './assessment';
import UserFinishStep from './user-finish-step';
import { isProduction, joinPath, querystring } from '../../utils/helpers';
import AssessmentsAnswerView from '../Assessments/AssessmentsAnswerView';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import UserStartStep from './user-start-step';
import AdminStartStep from './admin-start-step';
import AdminFinishStep from './admin-finish-step';
import { Helmet } from 'react-helmet';
import Training from './training';
import { Container } from 'reactstrap';
import Waiting from '../../components/waiting';
import { Buffer } from 'buffer';
import { ThemedButton } from '@awarego/awarego-components';
function DemoHome({ isAdmin, isTraining, isAssessment }) {
    const match = useRouteMatch();
    const isRootMatch = useRouteMatch({ path: match.path, exact: true });
    const history = useHistory();
    let { companyId: paramsCompanyId, reportTo: reportToBase64 } = match.params;
    const { demoStore, brandingStore } = useStores();
    const [inititalized, setInitialized] = useState();
    const {
        sessionId,
        companyId,
        demoUser,
        isAuthenticated,
        reportToName,
        reportToEmail,
    } = demoStore;
    const { brandingLoaded } = brandingStore;

    useEffect(() => {
        // ensure there is no session and demo user saved when you load the demo page
        if (isRootMatch && !inititalized) {
            restartDemo();
        }

        demoStore.setCompanyId(paramsCompanyId);
        if (reportToBase64) {
            try {
                let reportTo = Buffer.from(reportToBase64, 'base64').toString(
                    'utf8'
                );
                demoStore.setReportTo(reportTo);
            } catch (e) {
                console.error(e);
            }
        }
        setInitialized(true);
    }, [
        demoStore,
        paramsCompanyId,
        history,
        restartDemo,
        inititalized,
        companyId,
        isRootMatch,
        reportToBase64,
    ]);

    const restartDemo = useCallback(() => {
        demoStore.restart();
    }, [demoStore]);

    const userDemoLink = useMemo(() => {
        let path = match.url.replace('try-demo', 'demo');
        return `${new URL(window.location).origin}${path}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.url, reportToName, reportToEmail]);

    if (!inititalized || !brandingLoaded)
        return (
            <Container className="main-content">
                <Waiting waitingActive={true} from={'demo'} />
            </Container>
        );

    return (
        <>
            {/*debug button*/}
            {!isProduction && (
                <ThemedButton
                    onClick={restartDemo}
                    style={{
                        position: 'fixed',
                        top: 20,
                        right: 0,
                        zIndex: 10000,
                        background: 'red',
                    }}
                    text="restart"
                />
            )}
            <Helmet>
                <title>
                    {isAssessment
                        ? 'Human Risk Assessment'
                        : 'Security Awareness Training'}{' '}
                    demo
                </title>
            </Helmet>
            <Switch>
                <Route
                    exact
                    path={match.url}
                    render={() => {
                        if (isAuthenticated) {
                            if (isAssessment)
                                return (
                                    <Redirect
                                        to={`${joinPath(
                                            match.url,
                                            'assessment'
                                        )}`}
                                    />
                                );
                            if (isTraining)
                                return (
                                    <Redirect
                                        to={`${joinPath(
                                            match.url,
                                            'training/subjects'
                                        )}`}
                                    />
                                );
                        }
                        if (isAdmin)
                            return (
                                <AdminStartStep
                                    copyLink={userDemoLink}
                                    match={match}
                                    isAssessment={isAssessment}
                                />
                            );
                        return (
                            <UserStartStep
                                parentMatch={match}
                                isAssessment={isAssessment}
                            />
                        );
                    }}
                />
                <Route
                    render={() => {
                        if (!isAuthenticated) {
                            return <Redirect to={match.url} />;
                        } else
                            return (
                                <Switch>
                                    <Route
                                        path={`${joinPath(
                                            match.url,
                                            'assessment/answers'
                                        )}`}
                                        render={() => {
                                            let questionIndex =
                                                querystring('index');
                                            return (
                                                <AssessmentsAnswerView
                                                    companyId={companyId}
                                                    assessmentId={sessionId}
                                                    currentUser={demoUser}
                                                    assessmentStore={demoStore}
                                                    questionStore={demoStore}
                                                    parentUrl={match.url}
                                                    index={questionIndex}
                                                    isDemo
                                                />
                                            );
                                        }}
                                    />

                                    <Route
                                        path={`${joinPath(
                                            match.url,
                                            'assessment/give-feedback'
                                        )}`}
                                        render={() => (
                                            <UserFinishStep
                                                parentMatch={match}
                                                isAssessment
                                                isFeedbackPage
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${joinPath(
                                            match.url,
                                            'training/give-feedback'
                                        )}`}
                                        render={() => (
                                            <UserFinishStep
                                                parentMatch={match}
                                                isFeedbackPage
                                            />
                                        )}
                                    />

                                    <Route
                                        path={`${joinPath(
                                            match.url,
                                            'training/finish'
                                        )}`}
                                        render={() => {
                                            if (isAdmin)
                                                return (
                                                    <AdminFinishStep
                                                        copyLink={userDemoLink}
                                                        isAssessment={false}
                                                    />
                                                );
                                            return (
                                                <UserFinishStep
                                                    parentMatch={match}
                                                    isAssessment={false}
                                                />
                                            );
                                        }}
                                    />

                                    <Route
                                        path={`${joinPath(
                                            match.url,
                                            'assessment/finish'
                                        )}`}
                                        render={() => {
                                            if (isAdmin)
                                                return (
                                                    <AdminFinishStep
                                                        copyLink={userDemoLink}
                                                        isAssessment={
                                                            isAssessment
                                                        }
                                                    />
                                                );
                                            return (
                                                <UserFinishStep
                                                    parentMatch={match}
                                                    isAssessment={isAssessment}
                                                />
                                            );
                                        }}
                                    />

                                    <Route
                                        path={`${joinPath(
                                            match.url,
                                            'assessment'
                                        )}`}
                                        render={() => (
                                            <Assessment
                                                parentMatch={match}
                                                companyId={companyId}
                                                uniqueId={sessionId}
                                                assessmentId={sessionId}
                                                isAdmin={isAdmin}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${joinPath(
                                            match.url,
                                            'training'
                                        )}`}
                                        render={() => (
                                            <Training
                                                parentMatch={match}
                                                companyId={companyId}
                                                trainingId={sessionId}
                                                isAdmin={isAdmin}
                                            />
                                        )}
                                    />
                                </Switch>
                            );
                    }}
                />
            </Switch>
        </>
    );
}

export default observer(DemoHome);
