import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router';
import TestPage from './testing';
import Trainings from './Trainings';
import Assessments from './Assessments';
import AssessmentsTest from './Assessments/AssessmentsTest';
import Testcomp from './Testcomp';
import Home from './Home/Home';
import NotFound from '../components/NotFound';
import { Container } from 'reactstrap';
import Waiting from '../components/waiting';
import { observer } from 'mobx-react';
import Question from './Question';

const management_url = process.env.REACT_APP_LMS_URL;
function AppRouting({ appLoading, currentCompany, companyId }) {
    return (
        <Fragment>
            {!appLoading ? (
                <Switch>
                    {process.env.REACT_APP_TESTING && (
                        <Route path="/testing" component={TestPage} />
                    )}
                    <Route
                        path="/trainings"
                        component={() => (
                            <Trainings
                                isTrainingEnabled={
                                    currentCompany &&
                                    currentCompany.isTrainingEnabled
                                }
                                currentCompany={currentCompany}
                                companyId={companyId}
                            />
                        )}
                    />
                    <Route
                        path="/assessments"
                        component={() => (
                            <Assessments
                                isAssessmentEnabled={
                                    currentCompany &&
                                    currentCompany.isAssessmentEnabled
                                }
                                currentCompany={currentCompany}
                                companyId={companyId}
                            />
                        )}
                    />
                    <Route
                        path={`/assessment/question/:id`}
                        render={(props) => (
                            <Question
                                match={props.match}
                                companyId={companyId}
                                questionId={props.match.params.id}
                            />
                        )}
                    />
                    <Route
                        path={`/assessment/playground/`}
                        render={() => <AssessmentsTest />}
                    />
                    <Route path={`/testcomp`} render={() => <Testcomp />} />
                    <Route path="/" exact component={Home} />
                    <Route
                        path="/manager"
                        component={() => (window.location = management_url)}
                    />
                    {/* Finally, catch all unmatched routes */}
                    <Route component={NotFound} />
                </Switch>
            ) : (
                <Container className="main-content">
                    <Waiting waitingActive={appLoading} key={'Approuting'} />
                </Container>
            )}
        </Fragment>
    );
}

export default observer(AppRouting);
