import React, { useMemo } from 'react';
import HearingIcon from '@material-ui/icons/Hearing';
import StopIcon from '@material-ui/icons/Stop';
import { useStores } from '../hooks/use-stores';
import CryptoJS from 'crypto-js';
import { Box, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import { stripHtml } from '../utils/helpers';

function CircularProgressWithStop(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size={20} variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <StopIcon />
            </Box>
        </Box>
    );
}

function Speech({ text }) {
    const { brandingStore, speechStore } = useStores();
    const { mediaUrls, mediaUrlsCreating, playingAudio, progress } =
        speechStore;

    const hash = useMemo(() => {
        return CryptoJS.SHA256(stripHtml(text)).toString();
    }, [text]);

    const handleSpeechText = () => {
        speechStore.speak(stripHtml(text), hash);
    };

    if (!brandingStore.speechOverEnabled) return null;
    if (mediaUrlsCreating.includes(hash)) return <CircularProgress size={20} />;

    if (playingAudio === hash)
        return <CircularProgressWithStop value={progress * 100} />;
    return <HearingIcon onClick={handleSpeechText} />;
}

export default observer(Speech);
