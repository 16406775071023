import React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Container } from 'reactstrap';
import Assessment from './assessment';

export default observer(({ match, inactive, assessments }) => {
    if (!assessments) {
        return (
            <div>
                <Container>
                    <Row>
                        <Col md="6" xs="12">
                            <div className="loading-group" />
                        </Col>
                        <Col md="6" xs="12">
                            <div className="loading-group" />
                        </Col>
                        <Col md="6" xs="12">
                            <div className="loading-group" />
                        </Col>
                        <Col md="6" xs="12">
                            <div className="loading-group" />
                        </Col>
                        <Col md="6" xs="12">
                            <div className="loading-group" />
                        </Col>
                        <Col md="6" xs="12">
                            <div className="loading-group" />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    } else if (assessments) {
        // Display each group

        return (
            <div className={'videos' + (inactive ? ' inactive' : ' ')}>
                {
                    <div className="d-flex flex-column">
                        {assessments.map((assessment) => {
                            return (
                                <Assessment
                                    match={match}
                                    assessment={assessment}
                                    key={assessment.id}
                                />
                            );
                        })}
                    </div>
                }
            </div>
        );
    } else {
        return null;
    }
});
