import React, { useMemo } from 'react';
import {
    FlexDiv,
    Heading1,
    Heading4,
    TextDefault,
    ThemedButton,
    Image,
    TextInput,
} from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';
import { observer, useLocalObservable } from 'mobx-react';
import styled from 'styled-components';
import { resourcePath } from '../../utils/helpers';
import UserDemoFormDefinition from '../../forms/user-demo';
import Logo from './logo';

const LeftRoundCorner = styled(FlexDiv)`
    border-radius: 64px 0px 0px 0px;
`;

const FlexDivWithBgImage = styled(FlexDiv)`
    background-image: url(${(props) => props.backgroundUrl});
    background-repeat: no-repeat;
    background-position: right top;
`;

function UserStartStep({ isAssessment }) {
    const { demoStore, authStore } = useStores();
    const { joining, nameError, emailError, reportToEmail } = demoStore;
    const { currentUser } = authStore;

    const initialData = useMemo(() => {
        if (!currentUser) return;
        return { name: currentUser.name, email: currentUser.email };
    }, [currentUser]);

    const localStore = useLocalObservable(() => {
        let userDemoForm = new UserDemoFormDefinition(initialData, {
            onSuccess() {
                handleSubmit();
            },
        });
        return { userDemoForm };
    });

    const definition = useMemo(() => {
        return {
            heading: `${
                isAssessment ? 'Risk assessment' : 'Training program'
            } in less than 5 minutes`,
            subheading:
                'Help build a cybersecurity culture within your organization',
            description: `has invited you to go through this ${
                isAssessment ? 'assessment' : 'training'
            } demo and share your feedback at the end.`,
            image: {
                url: `/static/img/${
                    isAssessment ? 'assessment' : 'training'
                }-demo-preview.png`,
                alt: `Preview of ${isAssessment ? 'assessment' : 'training'}`,
            },
        };
    }, [isAssessment]);

    const handleSubmit = async () => {
        await demoStore.setDemoUser(
            localStore.userDemoForm.$('email').value,
            localStore.userDemoForm.$('name').value
        );
    };

    const inputHasError = (input) =>
        localStore.userDemoForm.$(input).error &&
        localStore.userDemoForm.$(input).error.length > 0;

    return (
        <FlexDiv
            layoutWrapper
            backgroundColor={isAssessment ? '#EBEDFC' : '#E9F6F1'}
            positionRelative
        >
            <Logo />
            {/* header */}
            <FlexDivWithBgImage
                backgroundUrl={resourcePath(
                    `/static/img/${
                        isAssessment ? 'blue' : 'green'
                    }-bubbles-bg.svg`
                )}
                mobileOnly
                minHeight={88}
            />
            <FlexDiv alignEnd justifyCenter flexGrow={1} desktopOnly>
                <Image
                    src={resourcePath(definition.image && definition.image.url)}
                    alt={definition.image.alt}
                    maxWidth={568}
                />
            </FlexDiv>

            {/* body */}
            <LeftRoundCorner
                justifyCenter
                flexGrow={1}
                backgroundColor={'white'}
                py={56}
                px={16}
            >
                <FlexDiv column maxWidth={424}>
                    <Heading4 lighter center>
                        {definition.heading}
                    </Heading4>
                    <Heading1 my={8} center>
                        {definition.subheading}
                    </Heading1>
                    <TextDefault lighter mb={32} center>
                        {reportToEmail} {definition.description}
                    </TextDefault>
                    <FlexDiv
                        column
                        gap={nameError || emailError ? 0 : 8}
                        mb={24}
                    >
                        <TextInput
                            error={inputHasError('email', localStore.form)}
                            helperText={
                                inputHasError('email', localStore.form)
                                    ? 'Please enter your email'
                                    : undefined
                            }
                            overwriteGlobalStyles
                            {...localStore.userDemoForm.$('email').bind()}
                        />
                        <TextInput
                            error={inputHasError('name', localStore.form)}
                            helperText={
                                inputHasError('name', localStore.form)
                                    ? 'Please enter your name'
                                    : undefined
                            }
                            stretched
                            {...localStore.userDemoForm.$('name').bind()}
                            overwriteGlobalStyles
                        />
                    </FlexDiv>
                    <ThemedButton
                        mode="primary"
                        disabled={joining}
                        size="fullWidth"
                        onClick={() => localStore.userDemoForm.submit()}
                        text="Let's do it"
                    />
                </FlexDiv>
            </LeftRoundCorner>
        </FlexDiv>
    );
}

export default observer(UserStartStep);
