import React, { Fragment, useMemo } from 'react';
import { formatTime } from '../../utils/helpers';
import {
    FlexDiv,
    TextLabel,
    StatusBadge,
    CancelCircle,
    CheckCircle,
} from '@awarego/awarego-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

function SubjectDuration({ subject }) {
    const { t } = useTranslation();

    const { numberOfPossibleDecisions = 0 } = subject;

    const decisions = useMemo(
        () =>
            subject.type === 3 &&
            subject.decisionFeedbackTable.slice(0, numberOfPossibleDecisions),
        [numberOfPossibleDecisions, subject.decisionFeedbackTable, subject.type]
    );
    return (
        <FlexDiv alignCenter gap={16}>
            {subject.duration && subject.duration > 0 && (
                <TextLabel lighter extraBold>
                    {formatTime(subject.duration)} {t('min')}
                </TextLabel>
            )}
            {subject.type === 3 && (
                <StatusBadge
                    text={t('decisionSubjectInteractiveBadge')}
                    thinner
                />
            )}
            {subject.questions_count !== 0 && subject.type === 4 && (
                <TextLabel lighter extraBold>
                    {subject.questions_count} {t('questions')}
                </TextLabel>
            )}
            {subject.type === 3 && decisions.length > 0 && (
                <FlexDiv gap={2}>
                    {decisions.map((decision, i) =>
                        decision.decision_1.key === decision.answer ? (
                            <Fragment key={i}>
                                {decision.decision_1.correct ? (
                                    <CheckCircle key={i} />
                                ) : (
                                    <CancelCircle key={i} />
                                )}
                            </Fragment>
                        ) : (
                            <Fragment key={i}>
                                {decision.decision_2.correct ? (
                                    <CheckCircle key={i} />
                                ) : decision.answer === 2 ? (
                                    <CheckCircle inactive={true} key={i} />
                                ) : (
                                    <CancelCircle key={i} />
                                )}
                            </Fragment>
                        )
                    )}
                </FlexDiv>
            )}
        </FlexDiv>
    );
}

export default observer(SubjectDuration);
