import React, { Fragment, useCallback } from 'react';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import {
    Heading2,
    SpaceBetweenDiv,
    TextTiny,
} from '@awarego/awarego-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function TrainingsListHeader({ training }) {
    const { t } = useTranslation();

    const getWeeksFromStart = useCallback(
        (training) =>
            Math.max(moment(training.created).diff(moment(), 'weeks'), 1),
        []
    );
    return (
        <SpaceBetweenDiv rowGap={4} gap={8}>
            <Heading2>{training.name || `${t('untitledTraining')}`}</Heading2>
            <TextTiny lighter bold>
                {training.minutesSent && (
                    <Fragment>
                        {training.minutesSent} {t('minutes delivered')}{' '}
                        {t('through')} {getWeeksFromStart(training)}{' '}
                        {t(pluralize('week', getWeeksFromStart(training)))}
                    </Fragment>
                )}
            </TextTiny>
        </SpaceBetweenDiv>
    );
}

export default observer(TrainingsListHeader);
