import { makeAutoObservable, toJS } from 'mobx';
import { createDecisionFeedbackTable } from '../../utils/helpers';
import { SUBJECT_TYPE } from '../../utils/constants';

class subjectStore {
    numberOfDecisions = 0;
    description = null;
    duration = 0;
    id = null;
    language = null;
    sendData = null;
    sent = null;
    slug = null;
    status = [];
    subject_id = null;
    thumb = null;
    title = null;
    type = null;
    video_decisions = [];
    video_id_jw = null;
    completed = false;
    started = false;
    decisionFeedbackTable = [];
    stored_decisions = [];
    decision_longestPossiblePath = 0;
    hasBeenTried = false;
    answerSubjectId = null;
    answerSubjectLength = 0;
    correctPath = [];

    constructor(subject) {
        makeAutoObservable(this);
        this.numberOfDecisions = subject.numberOfDecisions;
        this.description = subject.description;
        this.duration = subject.duration;
        this.id = subject.id;
        this.language = subject.language;
        this.sendData = subject.sendData;
        this.sent = subject.sent;
        this.slug = subject.slug;
        this.status = subject.status;
        this.subject_id = subject.subject_id;
        this.thumb = subject.thumb;
        this.title = subject.title;
        this.type = subject.type;
        this.video_decisions = subject.video_decisions.decisions;
        this.answerSubjectId = subject.video_decisions.answerSubjectId;
        this.answerSubjectLength = subject.video_decisions.answerSubjectLength;
        this.correctPath = subject.video_decisions.correctPath;
        this.video_id_jw = subject.video_id_jw;

        if (subject.type === SUBJECT_TYPE.DECISION) {
            //If this subject has been answered (correctly or incorrectly) we might need to load its stored_decisions, so we know how the users last decision path was
            if (this.status.ANSWARE_CORRECT || this.status.ANSWARE_INCORRECT) {
                if (this.status.ANSWARE_CORRECT) {
                    //Setting correct path as answer, regardless of if user answered correctly (100%) or watched answer since both should result in 100% score
                    this.stored_decisions = this.correctPath;
                } else {
                    this.stored_decisions = this.status.ANSWARE_INCORRECT;
                }
                this.decision_longestPossiblePath =
                    this.stored_decisions.length;
                this.hasBeenTried =
                    !!this.status.ANSWARE_CORRECT ||
                    !!this.status.ANSWARE_INCORRECT;
                this.decisionFeedbackTable = createDecisionFeedbackTable(
                    this.video_decisions,
                    toJS(this.stored_decisions),
                    [],
                    0
                );
            } else {
                this.decisionFeedbackTable = createDecisionFeedbackTable(
                    this.video_decisions,
                    [],
                    [],
                    0
                );
            }
        }
    }

    isSubjectDone() {
        return !!this.status.ANSWARE_CORRECT;
    }

    isSubjectDelivered() {
        return this.sent > 0;
    }

    resetDecisions() {
        this.stored_decisions = [];
        this.decision_longestPossiblePath = this.numberOfDecisions;
    }

    hasSeenAnswer() {
        this.stored_decisions = this.correctPath;
        this.decision_longestPossiblePath = this.stored_decisions.length;

        createDecisionFeedbackTable(
            this.video_decisions,
            toJS(this.correctPath),
            [],
            0
        );
    }

    setLogDecision(decision) {
        this.stored_decisions.push(decision);
    }

    updateLongestDecisionPath(decision) {
        if (!this.video_decisions[decision.next].decision_1)
            this.decision_longestPossiblePath = this.stored_decisions.length;
        else this.decision_longestPossiblePath = this.numberOfDecisions;
    }

    existsNextDecision(decision) {
        if (!this.video_decisions[decision.next].questionText) {
            this.hasBeenTried = true;
        }
        return !!this.video_decisions[decision.next].questionText;
    }

    createDecisionFeedbackTable() {
        createDecisionFeedbackTable(
            this.video_decisions,
            toJS(this.stored_decisions),
            [],
            0
        );
    }

    get storedDecisions() {
        return this.stored_decisions;
    }

    get numberOfRightAnswers() {
        return (
            (this.stored_decisions &&
                !this.stored_decisions.previewed &&
                this.stored_decisions.reduce((n, x) => n + x.result, 0)) ||
            0
        );
    }

    get numberOfPossibleDecisions() {
        return this.hasBeenTried
            ? this.decision_longestPossiblePath
            : this.numberOfDecisions;
    }
}

export default subjectStore;
