import React, { useMemo } from 'react';
import { Image, FlexDiv } from '@awarego/awarego-components';
function Banner({ imageSrc, linkUrl, language }) {
    const effectiveImageSrc = useMemo(() => {
        if (typeof imageSrc === 'string') return imageSrc;
        if (typeof imageSrc === 'object')
            return (
                imageSrc[language] ||
                imageSrc.en ||
                imageSrc[Object.keys(imageSrc)[0]]
            );
    }, [imageSrc, language]);

    const effectiveLinkUrl = useMemo(() => {
        if (typeof linkUrl === 'string') return linkUrl;
        if (typeof linkUrl === 'object')
            return (
                linkUrl[language] ||
                linkUrl.en ||
                linkUrl[Object.keys(linkUrl)[0]]
            );
    }, [linkUrl, language]);

    return (
        <FlexDiv maxWidth={760} my={32} dropShadowAbove borderRadius={6}>
            <a
                href={effectiveLinkUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Image
                    link
                    src={effectiveImageSrc}
                    alt="More information"
                    style={{ borderRadius: '6px' }}
                />
            </a>
        </FlexDiv>
    );
}

export default Banner;
