import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../hooks/use-stores';
import EmptyItem from '../Home/EmptyItem';
import { FlexDiv } from '@awarego/awarego-components';
import OneMomentSpinner from '../../components/OneMomentSpinner';
import styled from 'styled-components';
import TrainingsListHeader from '../../components/training/trainings-list-header';
import TrainingsListDetails from '../../components/training/trainings-list-details';

const StyledFlexDiv = styled(FlexDiv)`
    transition: 0.4s ease-out;

    padding: 24px;
    border-radius: 4px;
    @media (max-width: 992px) {
        padding: 16px;
    }

    &:hover {
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
            0px 4px 24px rgba(29, 30, 29, 0.08);
    }
`;

function TrainingsList() {
    const { t } = useTranslation();
    const { trainingStore, store, brandingStore } = useStores();
    const { loadingData, trainings } = trainingStore;

    useEffect(() => {
        store.setLayoutTitle(t('Awareness and Compliance Training'));
    }, [store, t]);

    if (loadingData) return <OneMomentSpinner />;

    return trainings.length > 0 ? (
        <FlexDiv column>
            {trainings
                .slice()
                .reverse()
                .filter((t) => t.active !== -1)
                .sort(
                    (trainingA, trainingB) =>
                        new Date(trainingB.created) -
                        new Date(trainingA.created)
                )
                .map((training) => {
                    return (
                        <StyledFlexDiv
                            key={training.id}
                            gap={24}
                            as={Link}
                            to={`/trainings/${training.id}`}
                        >
                            {/* heading & delivery info */}
                            <TrainingsListHeader training={training} />

                            {/* score, lessons, schedule */}
                            <TrainingsListDetails
                                training={training}
                                brandingStore={brandingStore}
                            />
                        </StyledFlexDiv>
                    );
                })}
        </FlexDiv>
    ) : (
        <EmptyItem item="training" />
    );
}

export default observer(TrainingsList);
