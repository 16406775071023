module.exports = {
    EVENTS: {
        LOGIN: 1,
        LOGOUT: 2,
        SUBJECT_OPEN: 3,
        SUBJECT_CLOSE: 4,
        ANSWARE_CORRECT: 5,
        ANSWARE_INCORRECT: 6,
        VIDEO_START: 7,
        VIDEO_25: 8,
        VIDEO_50: 9,
        VIDEO_75: 10,
        VIDEO_95: 11,
        VIDEO_100: 12,
        CAMPAIGN_EMAIL: 13,
        NOTIFICATION_LINK: 14,
        DECISION: 16, //Decision was taken
        LAST_DECISION: 17, //Decision subject was completed
    },

    USERS_VIEW: {
        LISTS: 1,
        EDIT: 2,
        ADD_USERS: 3,
        LIST_USERS: 4,
        LIST_VIEW: 5,
    },

    SUBJECT_TYPE: {
        VIDEO: 1,
        SCORM: 2,
        DECISION: 3,
        SCORM_QUIZ: 4,
    },

    publicHRASubstrings: [
        'cybersecurity-challenge',
        'ai-challenge',
        'human-cyber-guardian',
    ],
};
