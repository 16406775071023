const defaults = {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    allowMultiLoading: false,
    parse: JSON.parse,
    stringify: JSON.stringify,
    fetch,
    requestOptions: {},
};

const normalize = (funcOrVal, ...args) =>
    typeof funcOrVal === 'function' ? funcOrVal(...args) : funcOrVal;

class BackendError extends Error {
    retry = null;

    constructor(message, retry = false) {
        super(message);

        this.retry = retry;
    }
}

class Backend {
    constructor(services, options) {
        this.init(services, options);
    }

    type = 'backend';

    static type = 'backend';

    init(services, options = {}) {
        this.services = services;

        this.options = {
            ...defaults,
            ...this.options,
            ...options,
        };
    }

    getLoadPath(languages, namespaces) {
        return normalize(this.options.loadPath, languages, namespaces);
    }

    read(language, namespace, callback) {
        let lang = this.options.commonStore.languagesIndex[language];
        if (!lang) {
            language = 'en';
            lang = this.options.commonStore.languagesIndex[language];
        }
        if (lang) {
            if (lang.filePattern) {
                const url = this.services.interpolator.interpolate(
                    lang.filePattern,
                    { lng: language, ns: namespace }
                );
                this.loadUrl(url, callback);
            } else this.loadUrl(lang.file, callback);
        } else {
            const loadPath = this.getLoadPath(language, namespace);
            const url = this.services.interpolator.interpolate(loadPath, {
                lng: language,
                ns: namespace,
            });
            this.loadUrl(url, callback);
        }
    }

    loadUrl(url, callback) {
        const { fetch, requestOptions, parse } = this.options;

        fetch(url, requestOptions)
            .then(
                (response) => {
                    const { ok, status } = response;

                    if (!ok) {
                        const retry = status >= 500 && status < 600; // don't retry for 4xx codes

                        throw new BackendError(`failed loading ${url}`, retry);
                    }

                    return response.text();
                },
                () => {
                    throw new BackendError(`failed loading ${url}`);
                }
            )
            .then((data) => {
                try {
                    return callback(null, parse(data, url));
                } catch (e) {
                    throw new BackendError(
                        `failed parsing ${url} to json`,
                        false
                    );
                }
            })
            .catch((e) => {
                if (e instanceof BackendError) {
                    callback(e.message, e.retry);
                }
            });
    }
}

export default Backend;
