import React, { useEffect, useState } from 'react';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import LandingPage from './landing-page';
import LandingPageShort from './landing-page-short';

function InfosecAssessment({ variant = 'infosec', emailOption }) {
    const { commonStore, infosecStore, brandingStore } = useStores();
    const { joining, error } = infosecStore;
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (emailOption)
            commonStore.saveAnalyticsEvent('mini-hra', 'visit', {
                miniHRAType: variant,
                emailOption,
                page: window.location.pathname,
                eventName: 'page_view',
            });
    }, [emailOption]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value.trim());
        error &&
            error.length > 0 &&
            email !== '' &&
            infosecStore.setError(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (email === '' && emailOption === 'required-email') {
            infosecStore.setError('Fill in your email');
        } else {
            await infosecStore.join(email, variant, emailOption);
        }
    };

    return (
        <>
            {brandingStore.landingPageType(variant) === 'short' ? (
                <LandingPageShort
                    variant={variant}
                    onSubmit={handleSubmit}
                    onEmailChange={handleEmailChange}
                    email={email}
                    error={error}
                    joining={joining}
                    emailOption={emailOption}
                />
            ) : (
                <LandingPage
                    onSubmit={handleSubmit}
                    onEmailChange={handleEmailChange}
                    email={email}
                    error={error}
                    joining={joining}
                    emailOption={emailOption}
                />
            )}
        </>
    );
}

export default observer(InfosecAssessment);
