import React, { useEffect } from 'react';
import Score from '../score';
import Banner from '../../Banner';
import { observer } from 'mobx-react';
import { formatDateAndTime } from '../../../utils/helpers';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import { Heading1, Heading2, Heading4 } from '@awarego/awarego-components';
import ReviewTableSingleThreat from './ReviewTableSingleThreat';
import ReviewTableMultiThreat from './ReviewTableMultiThreat';

function Review({
    parentUrl,
    assessment,
    companyId,
    assessmentId,
    trainingId,
    assessmentStore,
    preloadedResults,
    shareResults,
    bannerImgSrc,
    bannerLinkURL,
    bannerSnippet,
    language,
    onShowResults,
}) {
    const { loadingAssessmentResults, results, resultsCount } = assessmentStore;
    const { t } = useTranslation();

    useEffect(() => {
        if (!preloadedResults) {
            assessmentStore.loadResults(companyId, assessmentId);
        }
    }, [preloadedResults, companyId, assessmentId]);

    useEffect(() => {
        if (onShowResults) onShowResults();
    }, []);

    return (
        <div className="quiz-slide center assessment-review">
            <div className="mobileOnly">
                <Heading2 bold>{assessment.name}</Heading2>
                <p>
                    <i className="date-icon done"></i>
                    {resultsCount} {t(pluralize('question', resultsCount))},{' '}
                    {t('completed on')}{' '}
                    {formatDateAndTime(assessment.end || assessment.completed)}
                </p>
            </div>
            <div className="desktopOnly">
                <Heading1 bold>{assessment.name}</Heading1>
                <br />
                <Heading4 bold>
                    {resultsCount} {t(pluralize('question', resultsCount))},{' '}
                    {t('completed on')} {formatDateAndTime(assessment.end)}.
                </Heading4>
            </div>
            {assessment && (
                <Score
                    parentUrl={parentUrl}
                    assessment={assessment}
                    horizontal
                    shareResults={shareResults}
                    shareUrl={assessmentStore.shareUrl()}
                />
            )}
            <br className="desktopOnly" />
            {(preloadedResults || results) && (
                <>
                    {Object.keys(preloadedResults || results).length > 1 ? (
                        <ReviewTableMultiThreat
                            loadingAssessmentResults={loadingAssessmentResults}
                            results={
                                preloadedResults ? preloadedResults : results
                            }
                            shareResults={shareResults}
                            parentUrl={parentUrl}
                            trainingId={trainingId}
                            assessmentId={assessment.id}
                        />
                    ) : (
                        <ReviewTableSingleThreat
                            loadingAssessmentResults={loadingAssessmentResults}
                            results={
                                preloadedResults ? preloadedResults : results
                            }
                            shareResults={shareResults}
                            parentUrl={parentUrl}
                            trainingId={trainingId}
                            assessmentId={assessment.id}
                        />
                    )}
                </>
            )}
            {bannerImgSrc &&
                (bannerSnippet && bannerSnippet[language] ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: bannerSnippet[language],
                        }}
                    ></div>
                ) : (
                    <Banner
                        language={language}
                        imageSrc={bannerImgSrc}
                        linkUrl={bannerLinkURL}
                    />
                ))}
            <br />
            <br />
        </div>
    );
}

export default observer(Review);
