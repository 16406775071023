import React from 'react';
import { FlexDiv, Heading2 } from '@awarego/awarego-components';

export default () => (
    <FlexDiv justifyCenter py={24} alignCenter>
        <br />
        <br />
        <Heading2>Sorry, page not found</Heading2>
    </FlexDiv>
);
