import { makeAutoObservable } from 'mobx';
import services from '../services';

class QuestionsStore {
    loadingData = false;
    error = null;
    question = null;
    questionAnswers = null;

    constructor(companyStore) {
        makeAutoObservable(this);
        this.companyStore = companyStore;
    }

    setError = (error) => {
        this.error = error;
    };

    async loadQuestion(companyId, id, assessmentId, background) {
        if (!background) {
            if (this.loadingData === id) return;
            this.loadingData = id;
        }
        this.setError(null);
        try {
            this.question = await services.Companies.questionsService(
                companyId
            ).getPersonalizedQuestion(id, assessmentId);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingData = null;
        }
    }
}

export default QuestionsStore;
