import RestService from './RestService';
import Events from './EventsService';

class FeedBackService extends RestService {
    constructor(parentService) {
        if (parentService) {
            super(`${parentService.baseUrl}/feedback`);
        } else throw new Error('parent is required');
    }
}

export default class Demo extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`/demo/${parentId}`);
        } else throw new Error('company is required');
    }

    myAssessment(params) {
        return this.get(`${this.baseUrl}/assessment`, params);
    }
    myTraining(params) {
        return this.get(`${this.baseUrl}/training`, params);
    }

    startAssessment(params) {
        return this.post(`${this.baseUrl}/assessment/run`, params);
    }

    results(params) {
        return this.get(`${this.baseUrl}/assessment/results`, params);
    }

    answers(params) {
        return this.get(`${this.baseUrl}/assessment/answers`, params);
    }

    async getPersonalizedQuestion(questionId, assessmentId, params) {
        return this.get(
            `${this.baseUrl}/assessment/question/${questionId}`,
            params
        );
    }

    questionActions(questionId, actions, params) {
        return this.post(
            `${this.baseUrl}/assessment/question/${questionId}/action`,
            { actions, ...params }
        );
    }

    feedbackService() {
        return new FeedBackService(this);
    }

    eventsService() {
        return new Events(this);
    }

    downloadCertificate(trainingId, params, options) {
        return this.get(
            `${this.baseUrl}/training/certificate`,
            params,
            options
        );
    }
}
