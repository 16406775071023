import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
    ThemedButton,
    Heading2,
    TextDefault,
    FlexDiv,
    SpaceBetweenDiv,
} from '@awarego/awarego-components';
import { useTranslation } from 'react-i18next';

function TrainingViewHeader({
    training,
    subjectsTODO,
    trainingId,
    subjectsDone,
}) {
    const { t } = useTranslation();

    if (!training) return;
    return (
        <SpaceBetweenDiv gap={8} rowGap={16} alignEnd>
            <FlexDiv column gap={8} rowGap={4}>
                <Heading2>{t('Training program')}</Heading2>
                <TextDefault lighter>
                    {training.viewed}/{training.subjectsSent}{' '}
                    {t('videos watched')}
                </TextDefault>
            </FlexDiv>
            {subjectsTODO.length > 0 && (
                <Link to={`/trainings/${trainingId}/subjects/`}>
                    <ThemedButton
                        mode="primary"
                        text={
                            subjectsDone.length > 0
                                ? t('Resume training')
                                : t('Start training')
                        }
                    />
                </Link>
            )}
        </SpaceBetweenDiv>
    );
}

export default observer(TrainingViewHeader);
