import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import { formatDate } from '../../utils/helpers';

function TimelineText({ training }) {
    const { t } = useTranslation();

    if (!training) return;
    if (training.nextSubjectDate && training.scheduleType !== 1) {
        return (
            <Fragment>
                {training.subjectsSent}/{training.totalSubjects}{' '}
                {t('lessons delivered.')} {t('Next')}{' '}
                {training.nextSubjectsToSend > 1 && training.nextSubjectsToSend}{' '}
                {t(pluralize('lesson', training.nextSubjectsToSend))}{' '}
                {t('will be delivered on')}{' '}
                {formatDate(training.nextSubjectDate)}
            </Fragment>
        );
    }
    return t('All delivered');
}

export default observer(TimelineText);
