import _ from 'lodash';
import { reaction, makeAutoObservable } from 'mobx';
import services from '../services';
import subjectStore from './models/subjectStore';
import { EVENTS } from '../utils/constants';

class TrainingsStore {
    loadingData = false;
    loadingTrainingId = null;
    error = null;
    trainings = [];
    training = null;
    // subjects = [];
    statusUpdate = 0;

    constructor(authStore, companyStore, mainStore) {
        makeAutoObservable(this);
        this.authStore = authStore;
        this.companyStore = companyStore;
        this.mainStore = mainStore;

        reaction(
            () => this.companyStore.currentCompany,
            (company) => {
                if (company && company.isTrainingEnabled) this.loadTrainings();
            }
        );
        /*
        reaction(
            () => this.statusUpdate,
            () => {
                this.reloadTraining();
            }
        );
        */
    }

    logout = () => {
        this.trainings.replace([]);
        this.loadingData = false;
    };

    setError = (error) => {
        this.error = error;
    };

    async reloadTraining() {
        this.training && this.loadTraining(this.training.id, true);
    }

    async loadTraining(id, background) {
        if (!background) {
            if (this.loadingTrainingId === id) return;
            this.loadingTrainingId = id;
        }
        this.setError(null);
        try {
            let data = await services.Trainings.myTraining(id, {
                l: this.mainStore.language,
            });
            if (data.subjects) {
                let subjects = [];
                for (const subject of data.subjects) {
                    if (subject.type === 3) {
                        subjects.push(new subjectStore(subject));
                    } else subjects.push(subject);
                }
                data.subjects = subjects;
            }
            this.training = data;
            //this.subjects.replace(data.subjects);
            this.mainStore.setLayoutTitle(data.name);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
            console.log(e);
        } finally {
            this.loadingTrainingId = null;
        }
    }

    async loadTrainings() {
        if (!this.companyStore.currentCompany) return null;
        if (this.loadingData) {
            return this.loadingData;
        }
        this.setError(null);

        this.loadingData = (async () => {
            try {
                let data = await services.Trainings.myTrainings(
                    this.contextParams
                );
                this.trainings.replace(data);
                return this.trainings;
            } catch (e) {
                this.setError(
                    (e.response && e.response.data && e.response.data.error) ||
                        e.message
                );
            } finally {
                this.loadingData = null;
            }
        })();
        return this.loadingData;
    }

    onLogEvent = (trainingId, subject, event_id, context) => {
        if (typeof subject === 'string') subject = Number(subject);
        if (typeof subject === 'number')
            subject = this.training.subjects.find((x) => x.id === subject);
        if (subject.type === 3 && event_id === EVENTS.DECISION) {
            subject.setLogDecision(context);
            subject.updateLongestDecisionPath(context);
            if (!subject.existsNextDecision(context)) {
                subject.createDecisionFeedbackTable();
            }
        }
        if (subject) this.updateStatus(event_id, subject);
    };

    updateStatus = (event_id, subject) => {
        if (subject.status) {
            switch (event_id) {
                case 1:
                    subject.status.LOGIN = 1;
                    break;
                case 2:
                    subject.status.LOGOUT = 1;
                    break;
                case 3:
                    subject.status.SUBJECT_OPEN = 1;
                    break;
                case 4:
                    subject.status.SUBJECT_CLOSE = 1;
                    break;
                case 5:
                    let prevValue_ANSWARE_CORRECT =
                        subject.status.ANSWARE_CORRECT;
                    subject.status.ANSWARE_CORRECT = 1;
                    if (!prevValue_ANSWARE_CORRECT) this.statusUpdate++;
                    break;
                case 6:
                    let prevValue_ANSWARE_INCORRECT =
                        subject.status.ANSWARE_INCORRECT;
                    subject.status.ANSWARE_INCORRECT = 1;
                    if (!prevValue_ANSWARE_INCORRECT) this.statusUpdate++;
                    break;
                case 7:
                    subject.status.VIDEO_START = 1;
                    break;
                case 8:
                    subject.status.VIDEO_25 = 1;
                    break;
                case 9:
                    subject.status.VIDEO_50 = 1;
                    break;
                case 10:
                    subject.status.VIDEO_75 = 1;
                    break;
                case 11:
                    let prevValue_VIDEO_95 = subject.status.VIDEO_95;
                    subject.status.VIDEO_95 = 1;
                    if (!prevValue_VIDEO_95) this.statusUpdate++;
                    break;
                case 12:
                    let prevValue_VIDEO_100 = subject.status.VIDEO_100;
                    subject.status.VIDEO_100 = 1;
                    if (!prevValue_VIDEO_100) this.statusUpdate++;
                    break;
                default:
                    break;
            }
        }
    };

    isSubjectDone(subject) {
        return (
            subject.status.ANSWARE_CORRECT > 0 &&
            (subject.status.VIDEO_95 > 0 || subject.status.VIDEO_100 > 0)
        );
    }

    isSubjectPassed(subject) {
        if (subject.type === 3) {
            if (Array.isArray(subject.status.ANSWARE_CORRECT)) {
                return subject.status.ANSWARE_CORRECT.length > 0;
            }
            return subject.status.ANSWARE_CORRECT > 0;
        }

        return (
            subject.status.ANSWARE_CORRECT > 0 &&
            (subject.status.VIDEO_95 > 0 || subject.status.VIDEO_100 > 0)
        );
    }

    isSubjectDelivered(subject) {
        return subject.sent > 0;
    }

    get subjectsTODO() {
        return this.subjects.filter((x) => {
            if (x.type === 3)
                return x.isSubjectDelivered() && !x.isSubjectDone();
            else return this.isSubjectDelivered(x) && !this.isSubjectDone(x);
        });
    }

    get deliveredSubjects() {
        return this.subjects.filter((x) => this.isSubjectDelivered(x));
    }

    get availableUnfinishedSubjects() {
        return this.subjects.filter(
            (x) => this.isSubjectDelivered(x) && !this.isSubjectPassed(x)
        );
    }

    get subjectsDone() {
        return this.subjects.filter((x) => {
            if (x.type === 3)
                return x.isSubjectDelivered() && x.isSubjectDone();
            else return this.isSubjectDelivered(x) && this.isSubjectDone(x);
        });
    }

    get subjects() {
        return this.training && this.training.subjects
            ? this.training.subjects
            : [];
    }

    get certificateQualified() {
        return (
            _.every(this.subjects, this.isSubjectPassed) &&
            (!this.training.assessment_id ||
                (this.training &&
                    this.training.assessment &&
                    this.training.assessment.status &&
                    this.training.assessment.status.finished))
        );
    }

    get assessmentAvailable() {
        return (
            _.every(this.subjects, this.isSubjectPassed) &&
            this.training.assessment_id
        );
    }

    get contextParams() {
        let params = {};
        if (this.companyStore.currentCompany)
            params.o = this.companyStore.currentCompany.company_id;

        if (this.mainStore.language) params.l = this.mainStore.language;

        return params;
    }
}

export default TrainingsStore;
