import React from 'react';

export default () => (
    <div className="spinner">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1"
            width="86px"
            height="86px"
            viewBox="0 0 28 28"
        >
            <g className="qp-circular-loader">
                <path
                    className="qp-circular-loader-path"
                    fill="none"
                    d="M 14,1.5 A 12.5,12.5 0 1 1 1.5,14"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    </div>
);
